import { useState, useEffect } from 'react';
import { ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage'

export function useAwardsImages(storage, storagePath, uploading) {

  const awardsImagesRef = ref(storage, storagePath)
  const [awardsImages, setAwardsImages] = useState([]) // Array

  useEffect(() => {
    // To avoid duplications
    setAwardsImages([])
    const getAwardsImages = () => {
      listAll(awardsImagesRef).then(res => {
        if (res.items.length > 0) {
          res.items.forEach(item => {
            let imageRef = ref(storage, `${storagePath}${item.name}`)
            let award = {}
            const promises = []
            promises.push(getMetadata(imageRef), getDownloadURL(item))
            Promise.all(promises).then(responseArr => {
              // responseArr will be [{metadata}, 'url'] for each award item
              award.link = responseArr[0].customMetadata.link
              award.name = responseArr[0].customMetadata.name
              award.imgName = item.name
              award.url = responseArr[1]
              setAwardsImages(prev => [...prev, award])
            }).catch(err => {
              console.log(err)
            })
          })
        } else {
          setAwardsImages([])
        }
      }).catch(err => {
        console.error('Error in listAll in getAwardsImages', err)
      })
    }
    getAwardsImages()
  }, [awardsImagesRef._location.path, uploading])
  // IMPORTANT [awardsImagesRef._location.path, uploading]
  // awardsImagesRef._location.path -> when switching between locations, the path will change and that will trigger the useEffect to rerender
  // uploading -> is crucial when uploading a new award or editing an existing one. This will trigger the useEffect to rerender as well.

  return awardsImages.sort((a, b) => a.name.localeCompare(b.name))
}
export default useAwardsImages