export const storeInitialValues = {
  address: '',
  addressCity: '',
  marketingCity: '',
  open: false,
  phone: '',
  place_id: '',
  salesforceValue: '',
  store_email: '',
  openDate: {},
  clinicName: '',
  state: '',
  zipCode: '',
  with_pro_images: false,
  cm_pardot_file: '',
  hero: {
    reversed: false,
    position: 'center',
  },
  maps: {
    google: '',
    apple: '',
  },
  geo: {
    lat: '',
    long: '',
  },
  description: {
    line_one: '',
    line_two: '',
    field_one: '',
    field_two: '',
    field_three: '',
  },
  platforms: {
    reviews: {
      list_token: '',
      slide_token: '',
    },
    facebook: '',
    yelp: '',
    google: '',
  },
};
