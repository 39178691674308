import React, { useEffect } from "react";
import MILAN_BLUE_LOGO from "../../shared/assets/Milan-Logo-Blue.webp";

export function FormWrapper({ children }) {
  useEffect(() => {
    document.title = "Milan Clinic Site App"
  }, [])
  return (
    <section id="auth">
      <div className="container p-2 py-sm-5">
        <div className="row d-flex justify-content-center align-items-center pt-3">
          <div className="col col-md-10 col-lg-6">
            <div className="card">
              <div className="row">
                <div className="card-body p-4 p-lg-5">
                  <div className="d-flex justify-content-between border-bottom pb-3">
                    <div className="logo">
                      <img src={MILAN_BLUE_LOGO} className="w-100" alt="" />
                    </div>
                    <h1 className="h3 align-self-center">Clinic Site App</h1>
                  </div>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default FormWrapper;
