import React from 'react'
import { saveAs } from "file-saver"
import { INTERNAL_ASSETS_PATH_WITH_CORS } from '../shared/constants'
import DownloadIcon from '../shared/assets/Deck-download.png'

import ThumbnailWereHiring from './assets/Thumbnail_WereHiring.webp'
import ThumbnailNewOpportunities from './assets/Thumbnail_NewOpportunities.webp'
import ThumbnailJoinOurTeam from './assets/Thumbnail_JoinOurTeam.webp'
import ThumbnailExpandHorizons from './assets/Thumbnail_ExpandHorizons.webp'
import ThumbnailGrowWithUs from './assets/Thumbnail_GrowWithUs.webp'
import ThumbnailAnswerTheCall from './assets/Thumbnail_AnswerTheCall.webp'
import ThumbnailReadyForChange from './assets/Thumbnail_ReadyForChange.webp'
import ThumbnailDreamJob from './assets/Thumbnail_DreamJob.webp'
import ThumbnailUpgradeCareer from './assets/Thumbnail_UpgradeCareer.webp'
import ThumbnailGlowUp from './assets/Thumbnail_GlowUp.webp'
import ThumbnailLPN from './assets/LPN.webp'
import ThumbnailPA from './assets/PA.webp'
import ThumbnailNP from './assets/NP.webp'
import ThumbnailSalesManager from './assets/SalesManager.webp'

// import ThumbnailReceptionist from './assets/Receptionist.webp'
import ThumbnailReceptionist from './assets/Receptionist2.webp'
// import ThumbnailRN from './assets/RN.webp'
import ThumbnailRN from './assets/RN2.webp'

const WereHiring = INTERNAL_ASSETS_PATH_WITH_CORS + 'WereHiring.png'
const NewOpportunities = INTERNAL_ASSETS_PATH_WITH_CORS + 'NewOpportunities.png'
const JoinOurTeam = INTERNAL_ASSETS_PATH_WITH_CORS + 'JoinOurTeam.png'
const ExpandHorizons = INTERNAL_ASSETS_PATH_WITH_CORS + 'ExpandHorizons.png'
const GrowWithUs = INTERNAL_ASSETS_PATH_WITH_CORS + 'GrowWithUs.png'
const AnswerTheCall = INTERNAL_ASSETS_PATH_WITH_CORS + 'AnswerTheCall.png'
const ReadyForChange = INTERNAL_ASSETS_PATH_WITH_CORS + 'ReadyForChange.png'
const DreamJob = INTERNAL_ASSETS_PATH_WITH_CORS + 'DreamJob.png'
const UpgradeCareer = INTERNAL_ASSETS_PATH_WITH_CORS + 'UpgradeCareer.png'
const GlowUp = INTERNAL_ASSETS_PATH_WITH_CORS + 'GlowUp.png'
const LPN = INTERNAL_ASSETS_PATH_WITH_CORS + 'LPN.png'
const PA = INTERNAL_ASSETS_PATH_WITH_CORS + 'PA.png'
const SalesManager = INTERNAL_ASSETS_PATH_WITH_CORS + 'SalesManager.png'
const NP = INTERNAL_ASSETS_PATH_WITH_CORS + 'NP.png'

// const Receptionist = INTERNAL_ASSETS_PATH_WITH_CORS + 'Receptionist.png'
const Receptionist = INTERNAL_ASSETS_PATH_WITH_CORS + 'Receptionist2.png'
// const RN = INTERNAL_ASSETS_PATH_WITH_CORS + 'RN.png'
const RN = INTERNAL_ASSETS_PATH_WITH_CORS + 'RN2.png'

function OpenPositions() {
  return (
    <section id="linkedin-open-postions" className="pb-5">
      <div className="container">
        <h2 className="text-center pt-5 pb-4 h1 fw-light d-none d-sm-block milan-text-primary">SHARE YOUR OPEN POSITIONS</h2>
        <h2 className="text-center pt-5 pb-4 d-sm-none display-3 milan-text-primary">SHARE YOUR OPEN POSITIONS</h2>
        {/* <p className="pb-3">As we continue to grow, many of you will be looking to expand your teams. No matter how you want to hype up opportunities to join the Milan team, there's a graphic to help jazz up your post and make it stand out.</p> */}
        <h6 className="pb-3 text-center">As we continue to grow, many of you will be looking to expand your teams. No matter how you want to hype up opportunities to join the Milan team, there's a graphic to help jazz up your post and make it stand out.</h6>
        <div className="row justify-content-center gx-xl-5">
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(LPN, 'LPN.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailLPN} alt="We're hiring" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>


          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(RN, 'RN.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailRN} alt="We're hiring" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>


          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(PA, 'PA.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailPA} alt="We're hiring" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(NP, 'NP.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailNP} alt="We're hiring" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(Receptionist, 'Receptionist.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailReceptionist} alt="We're hiring" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(SalesManager, 'SalesManager.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailSalesManager} alt="We're hiring" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(WereHiring, 'WereHiring.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailWereHiring} alt="We're hiring" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(NewOpportunities, 'NewOpportunities.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailNewOpportunities} alt="New opportunities" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(JoinOurTeam, 'JoinOurTeam.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailJoinOurTeam} alt="Join Our Team" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(ExpandHorizons, 'ExpandHorizons.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailExpandHorizons} alt="Expand Your Horizons" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(GrowWithUs, 'GrowWithUs.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailGrowWithUs} alt="Grow With Us" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(AnswerTheCall, 'AnswerTheCall.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailAnswerTheCall} alt="Answer The Call" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(ReadyForChange, 'ReadyForChange.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailReadyForChange} alt="Ready For Change" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(DreamJob, 'DreamJob.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailDreamJob} alt="Dream Job" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(UpgradeCareer, 'UpgradeCareer.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailUpgradeCareer} alt="Upgrade Career" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(GlowUp, 'GlowUp.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={ThumbnailGlowUp} alt="Glow Up" />
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default OpenPositions