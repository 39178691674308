import * as Yup from 'yup';

export const storeValidationSchema = Yup.object({
  marketingCity: Yup.string().required('Required field!'),
  clinicName: Yup.string(),
  address: Yup.string().required('Required field!'),
  addressCity: Yup.string(),
  state: Yup.string().required('Required field!'),
  zipCode: Yup.string().max(5, '5 digits max').min(5, 'Enter a valid zip code').required('Required field!'),
  phone: Yup.string().min(12, 'Enter a valid phone number!'),
  salesforceValue: Yup.string().required('Required field!'),
  store_email: Yup.string()
    .required('Required field!')
    .test((input) => {
      if (input && !input.includes('@milanlaser.com')) return new Yup.ValidationError('Enter a valid Milan email', undefined, 'store_email');
      else return true;
    }),
  openDate: Yup.object(),
  place_id: Yup.string(),
  open: Yup.boolean().required('Required field!'),
  with_pro_images: Yup.boolean().required('Required field'),
  cm_pardot_file: Yup.string(),
  hero: Yup.object({
    reversed: Yup.boolean(),
    position: Yup.string(),
  }),
  maps: Yup.object({
    google: Yup.string(),
    apple: Yup.string(),
  }),
  geo: Yup.object({
    lat: Yup.string(),
    long: Yup.string(),
  }),
  description: Yup.object({
    field_one: Yup.string(),
    field_two: Yup.string(),
    line_one: Yup.string().test((input) => {
      if (input && input.trim().toLowerCase().startsWith('located')) {
        return new Yup.ValidationError('Remove "located" from the beginning', undefined, 'description.line_one');
      } else if (input && input.trim().endsWith(',')) {
        return new Yup.ValidationError('Remove "," from the end and add it to the long description', undefined, 'description.line_one');
      } else return true;
    }),
    line_two: Yup.string().test((input) => {
      if (input && (input.endsWith('.') || input.endsWith('. '))) {
        return new Yup.ValidationError('Remove "." from the end', undefined, 'description.line_two');
      } else return true;
    }),
    field_three: Yup.string(),
  }),
  platforms: Yup.object({
    reviews: Yup.object({
      list_token: Yup.string(),
      slide_token: Yup.string(),
    }),
    facebook: Yup.string().test((input) => {
      if (input && input.toLowerCase().includes('milanlaser')) {
        return new Yup.ValidationError('Complete link without repetition!', undefined, 'platforms.facebook');
      } else if (input && input.includes('/')) {
        return new Yup.ValidationError('"/" may not be included', undefined, 'platforms.facebook');
      } else return true;
    }),
    yelp: Yup.string().test((input) => {
      if (input && input.toLowerCase().includes('milan-laser-hair-removal')) {
        return new Yup.ValidationError('Complete link without repetition!', undefined, 'platforms.yelp');
      } else if (input && input.includes('/')) {
        return new Yup.ValidationError('"/" may not be included', undefined, 'platforms.yelp');
      } else return true;
    }),
    google: Yup.string(),
  }),
});
