import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { FaAsterisk } from 'react-icons/fa'
import { TextError } from '.'

export function EmailInput(props) {
  const { label, name, ...rest } = props
  return (
    <>
      {label && <label htmlFor={name}>{label} <sup><FaAsterisk /></sup></label>}
      <Field name={name} >
        {
          props => {
            const { field, meta } = props
            return (
              <input
                className="form-control"
                type="email"
                id={name}
                {...field} {...rest}
                style={meta.touched && meta.error ? { borderColor: 'red' } : null} />
            )
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}

export default EmailInput