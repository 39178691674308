import React, { useState, useRef, useEffect } from 'react'
import { FaSearch } from 'react-icons/fa'
import Form from "react-bootstrap/Form"
import ListGroup from "react-bootstrap/ListGroup"
import { trimAll } from '../../utils'
import { useClickOutside } from '../../hooks'

// Don't import hardTrim - it doesn't lowercase the string
const hardTrim = str => trimAll(str).replace(/[^a-z0-9]/gi, '');
const initialValues = {
  state: null,
  metro: null,
  details: null
}

export function BorrowStore({ milanStores, currentMetro, updateDB }) {
  const [selectedStore, setSelectedStore] = useState(initialValues)
  const [foundStores, setFoundStores] = useState([])
  const [inputValue, setInputValue] = useState("")
  const domNode = useClickOutside(() => setFoundStores([]))

  const onChangeHandler = e => {
    setFoundStores([])
    setInputValue(e.target.value)
    if (e.target.value === "") {
      setFoundStores([])
      return
    }
    let foundStoresArr = []
    milanStores.forEach(state => {
      if (state.metros.length !== 0) {
        state.metros.forEach(metro => {
          if (metro.stores.length !== 0) {
            metro.stores.forEach(store => {
              const value = hardTrim(e.target.value)
              if (hardTrim(store.marketingCity).includes(value) || hardTrim(store.addressCity).includes(value) || hardTrim(store.clinicName).includes(value) || hardTrim(metro.name).includes(value)) {
                const foundStore = {
                  state: {
                    name: state.name,
                    abbreviation: state.abbreviation,
                    pathname: trimAll(state.name.toLowerCase())
                  },
                  metro: {
                    name: metro.name,
                    pathname: metro.pathname,
                    metroPath: metro.metroPath
                  },
                  details: { ...store }
                }
                foundStoresArr.push(foundStore)
              }
            })
          }
        })
      }
    })
    setFoundStores(foundStoresArr)
  }

  const onStoreClicked = (clickedStore) => {
    let doesExist = currentMetro.stores.some(store => store.salesforceValue === clickedStore.details.salesforceValue)
    if (!doesExist && currentMetro.borrowed_stores) {
      doesExist = currentMetro.borrowed_stores.some(store => store.details.salesforceValue === clickedStore.details.salesforceValue)
    }
    if (!doesExist) {
      setSelectedStore(clickedStore)
    }
    setFoundStores([])
    setInputValue("")
  }

  const confirmBorrowLocation = () => {
    updateDB({ data: selectedStore, target: 'metro', mode: 'borrow store' })
    setSelectedStore(initialValues)
  }

  // Keyboard navigation
  const listRef = useRef(null)
  const searchBarRef = useRef(null)
  const [count, setCount] = useState(0)
  const handleKeyDown = e => {
    if (e.key === 'ArrowDown' && listRef.current && count < foundStores.length) {
      setCount(prevCount => prevCount + 1)
    } else if (e.key === 'ArrowUp' && listRef.current && count > 1) {
      setCount(prevCount => prevCount - 1)
    } else if (e.key === 'Enter' && listRef.current && count > 0) {
      onStoreClicked(foundStores[count - 1])
      setCount(0)
      setFoundStores([])
    } else {
      setCount(0)
    }
  }
  useEffect(() => {
    if (listRef.current && listRef.current.children && count === 0) {
      searchBarRef.current.focus()
    }
    if (listRef.current && listRef.current.children && count > 0) {
      listRef.current.children[count - 1].focus()
    }
  }, [count])

  return (
    <div className="row pb-5 gx-5">
      <h4 className="h5 mb-4 main-blue">Borrow Location From Another Metro</h4>
      <div className="col-md-6 col-xl-4">
        <div ref={domNode} className="search-bar-wrapper mt-5 mt-lg-0">
          <div className="search-input input-group">
            <span className="input-group-text" style={{ minWidth: '60px' }}><FaSearch /></span>
            <Form.Control className="py-2" ref={searchBarRef} type="search" placeholder="Search locations..." onChange={onChangeHandler} value={inputValue} onKeyDown={handleKeyDown} />
          </div>
          <div className="search-results position-relative">
            {
              foundStores.length !== 0 &&
              <ListGroup ref={listRef} className="align-items-center position-absolute w-100 bg-white">
                {
                  foundStores.map((store, x) => (
                    <ListGroup.Item className="pointer list-group-item-action" tabIndex={-1} key={x} onClick={() => onStoreClicked(store)} onKeyDown={handleKeyDown}>
                      {store.metro.name} ({store.details.marketingCity} | {store.details.stateAbbrev})
                    </ListGroup.Item>
                  )
                  )
                }
              </ListGroup>
            }
          </div>
        </div>
      </div>
      {
        selectedStore.metro &&
        <>
          <div className="col-md-6 col-xl-4">
            <div className="input-group mb-2" >
              <div className="form-control disabled-bg overflow-scroll py-2 text-center">
                {selectedStore.details.marketingCity} | {selectedStore.details.stateAbbrev}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <button type="button" className="btn-submit btn shadow-sm py-2 w-100" onClick={confirmBorrowLocation}>Borrow Location</button>
          </div>
        </>
      }
    </div>
  );
}
export default BorrowStore;
