import React, { useRef, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import emailjs from '@emailjs/browser';
import validationSchema from './validationSchema';
import TextError from './TextError'
import FormSuccess from './FormSuccess';
import FormFailed from './FormFailed'
import { FaAsterisk } from 'react-icons/fa'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from 'date-fns';
import moment from 'moment'
import ReCAPTCHA from "react-google-recaptcha";
import { MILAN_CORS } from '../constants';

export const CustomGraphicsForm = () => {
  const form = useRef()
  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState('')

  const restartHandler = () => {
    setSuccess(false)
    setFailed(false)
    setRecaptchaToken('')
  }

  const onChangeRecaptcha = (token) => setRecaptchaToken(token)

  async function validateHuman(token) {
    const secret = process.env.REACT_APP_RECAPTCHA_SECRET_KEY
    const response = await fetch(`${MILAN_CORS}https://www.google.com/recaptcha/api/siteverify?secret=${secret}&response=${token}`, {
      method: "POST",
    })
    const recaptchaData = await response.json()
    return recaptchaData
  }

  const sendEmail = (values, actions) => {
    setSubmitting(true)
    validateHuman(recaptchaToken).then(response => {
      if(response.success) {
        emailjs.sendForm(process.env.REACT_APP_EMAIL_JS_SERVICE_ID, process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY)
        .then((result) => {
          console.log(result.text);
          setSuccess(true)
          setFailed(false)
        }, (error) => {
          console.log(error.text);
          setFailed(true)
          setSuccess(false)
        }).finally(() => {
          setSubmitting(false)
          actions.resetForm()
        })
      }
    })
  };

  return (
    <Formik 
      initialValues={{
        user_name: '',
        user_email: '',
        due_date: null,
        message: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => sendEmail(values, actions)}>
      {
        formik => {
          return (
          <Form ref={form}>
          {
            failed ? <FormFailed /> : success ? <FormSuccess /> :
            <>
            <div className="row justify-content-center mx-auto">
              <label htmlFor="user_name">Your name <sup><FaAsterisk /></sup></label>
              <Field 
                id="user_name" name="user_name" placeholder="John Doe" className="form-control"
                style={formik.touched.user_name && formik.errors.user_name ? {borderColor: 'red'} : null} />
                <ErrorMessage name="user_name" component={TextError} />
            </div>

            <div className="row justify-content-center mx-auto pt-3">
              <label htmlFor="email">Your email <sup><FaAsterisk /></sup></label>
              <Field
                id="user_email" name="user_email"  placeholder="user@milanlaser.com"
                type="user_email" className="form-control"
                style={formik.touched.user_email && formik.errors.user_email ? {borderColor: 'red'} : null}
              />
              <ErrorMessage name="user_email" component={TextError} />
            </div>

            <div className="row justify-content-center mx-auto pt-3">
              <label htmlFor="due_date">When do you need the graphic by</label>
              <DatePicker 
                selected={formik.values.due_date}
                dateFormat="MMMM d, yyyy"
                className="form-control"
                name="due_date" id="due_date"
                placeholderText="Pick a day"
                excludeDates={[new Date(), addDays(new Date(), 1), addDays(new Date(), 2)]}
                minDate={moment().toDate()}
                onChange={date => formik.setFieldValue('due_date', date)}
              />
            </div>

            <div className="row justify-content-center mx-auto pt-3">
              <label htmlFor="message">Give us the details <sup><FaAsterisk /></sup></label>
              <Field as="textarea" className="form-control" placeholder="Share the details of your projects here" id="message" name="message" rows="3"
                style={formik.touched.message && formik.errors.message ? {borderColor: 'red'} : null}/>
              <ErrorMessage name="message" component={TextError} />
            </div>

            <div className="row justify-content-center mx-auto pt-3">
              <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={onChangeRecaptcha} className="px-0" />
            </div>
           

            <div className="row pt-3">
              <div className="">
                <input type="submit" value={submitting ? 'Submitting...' : 'Submit Request'} className='cta-btn red-bg-btn py-2 px-3 shadow-sm' disabled={!formik.isValid || !recaptchaToken} />
              </div>
            </div>
            </>
          }
          {
            (failed || success) && 
            <div className="row justify-content-center mx-auto pt-5">
              <div className="col-md-6 px-0">
                <input 
                  type="button" value={failed ? 'Try Again' : 'Submit Another Request'} 
                  className='w-100 cta-btn light-btn py-2 shadow-sm'
                  onClick={restartHandler}/>
              </div>
            </div>
          }
          </Form>
          )
        }
      }
    </Formik>
  )
};