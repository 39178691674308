import React, { useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa'

export function BorrowedStores({ currentMetro, currentUser, updateDB }) {
  const [deleteMode, setDeleteMode] = useState(false)
  const onDeleteStore = toBeDeleted => {
    updateDB({ data: toBeDeleted, target: 'metro',  mode: 'delete borrowed store' })
  }

  return (
    <div className="row">
      <h4 className="h5 mb-4 main-blue">Borrowed Locations From Another Metro</h4>
      {
        currentMetro.borrowed_stores.length !== 0 && currentMetro.borrowed_stores.map((store, x) => (
          <div key={x} className="col-md-6 col-xl-4">
            <div className="input-group mb-2" >
              <div className="form-control disabled-bg overflow-scroll py-2 text-center">
                <span>{store.details.marketingCity} | {store.details.stateAbbrev}</span>
                {
                  currentUser.role === 'admin' && deleteMode &&
                  <button className="float-end pointer btn text-coral py-0" onClick={() => onDeleteStore(store)} disabled={!deleteMode}><FaTrashAlt className="mb-1" /></button>
                }
              </div>
            </div>
          </div>
        ))
      }
      {
        currentUser.role === 'admin' &&
        <div className="col-12 mt-4">
          {
            !deleteMode ?
              <button type="button" className="btn-delete btn px-2 shadow-sm" onClick={() => setDeleteMode(true)}>
                <FaTrashAlt className="mb-1" /> Delete Store
              </button>
              :
              <button type="button" className="btn-khaki btn px-4 shadow-sm" onClick={() => setDeleteMode(false)}>
                Done
              </button>
          }
        </div>
      }
    </div>
  )
}

export default BorrowedStores