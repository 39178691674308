import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { FormikControl } from "../utils"
import { AlertWarning } from '.'
import { StoresManagerContext } from "../home"

export function SelectionsDropdown() {
  const { milanStores, updateCurrentView, currentState, currentMetro, currentStore } = useContext(StoresManagerContext)

  const initialValues = {
    state: currentState.name,
    metro: currentMetro ? currentMetro.name : "",
    location: currentStore ? currentStore.marketingCity : ""
  }

  const onChangeHandler = (e, fieldName) => {
    switch (fieldName) {
      case 'state':
        const selectedState = milanStores.find(state => state.name === e.target.value)
        const hasMetros = selectedState.metros.length !== 0
        let metroHasStore = null
        // Check if the first metro has at least one store
        if (hasMetros) {
          metroHasStore = selectedState.metros[0].stores.length !== 0
        }
        updateCurrentView({
          stateName: selectedState.name,
          metroPathname: hasMetros ? selectedState.metros[0].pathname : "",
          salesforceValue: hasMetros && metroHasStore ? selectedState.metros[0].stores[0].salesforceValue : ""
        })
        break
      case 'metro':
        const selectedMetro = currentState.metros.find(metro => metro.name === e.target.value)
        const hasStores = selectedMetro.stores.length !== 0
        updateCurrentView({
          stateName: currentState.name,
          metroPathname: selectedMetro.pathname,
          salesforceValue: hasStores ? selectedMetro.stores[0].salesforceValue : ""
        })
        break
      case 'location':
        updateCurrentView({
          stateName: currentState.name,
          metroPathname: currentMetro.pathname,
          salesforceValue: currentMetro.stores.find(store => store.marketingCity === e.target.value).salesforceValue
        })
        break
      default: return
    }
  }

  return (
    <div id="selections-dropdowns" className="container-fluid px-3 px-md-5 py-3">
      <Formik initialValues={initialValues} enableReinitialize>
        <Form className="row gy-3 gx-5 justify-content-between">
          <div className="col-lg-4">
            <FormikControl
              control="select" name="state" optionProp="name"
              options={milanStores} onChange={(e) => onChangeHandler(e, 'state')} />
          </div>
          <div className="col-lg-4">
            {

              currentMetro ?
                <FormikControl
                  control="select" name="metro" optionProp="name"
                  options={currentState.metros}
                  onChange={(e) => onChangeHandler(e, 'metro')} />
                :
                <AlertWarning item="metros" />
            }
          </div>
          <div className="col-lg-4">
            {

              currentMetro && currentStore ?
                <FormikControl
                  control="select" name="location" optionProp="marketingCity"
                  options={currentMetro.stores}
                  onChange={(e) => onChangeHandler(e, 'location')} />
                :
                <AlertWarning item="locations" />
            }
          </div>
        </Form>
      </Formik>
    </div>
  );
}
export default SelectionsDropdown;
