export const MILAN_SITE_START = 'https://milanlaser'
export const MILAN_CORS = 'https://milan-cors-2023-9de078d0fd3b.herokuapp.com/'
export const CORPORATE_SITE = MILAN_SITE_START+'.com'
export const GATSBY_DOMAIN = MILAN_SITE_START+'gatsby.com'
export const A2_DOMAIN_ASSETS_PATH = MILAN_CORS+MILAN_SITE_START+'a2.com/'

//https://milanlasergatsby.com/internal/assets/
export const INTERNAL_ASSETS_PATH = GATSBY_DOMAIN+'/internal/assets/'
//https://milan-cors-2023-9de078d0fd3b.herokuapp.com/https://milanlasergatsby.com/internal/assets/
export const INTERNAL_ASSETS_PATH_WITH_CORS = MILAN_CORS + GATSBY_DOMAIN+'/internal/assets/'

export const APP_NAME = 'Clinic Site App'
export const APP_NAME_TRIMMED = APP_NAME.replace(/\s+/g, '')
// export const MILAN_CORS = 'https://cors-milanlaser.herokuapp.com/'
export const GOOGLE_REVIEWS_API_URL = MILAN_CORS+'https://maps.googleapis.com/maps/api/place/details/json'
export const GOOGLE_TIMEZONE_API_URL = MILAN_CORS+'https://maps.googleapis.com/maps/api/timezone/json'
export const MILAN_EMAIL = '@milanlaser.com'
export const GEOCODE_GOOGLE_MAPS_URL = MILAN_CORS+'https://maps.googleapis.com/maps/api/geocode/json'
export const DISTANCE_MATRIX_GOOGLE_MAPS_URL = MILAN_CORS+'https://maps.googleapis.com/maps/api/distancematrix/json'
export const MILAN_PHONE = '833-667-2967'

