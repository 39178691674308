import React, { useRef } from 'react'
import { useNavigate } from "react-router-dom"
import { SelectionsDropdown } from '../main-selections'
import { MetroDetails } from '../metro-store-wrapper'
import { ControlPanel } from '../control-panel'
import { useFetchMilanStores, useAuth, useRecentActivities, useGlobalData, useMedicalDirectors, useUpdateCurrentView } from "../hooks"
import { APP_NAME_TRIMMED } from '../utils'
import { Header, CarouselDivider, ToastAlert, updateRecentActivities, updateMilanStoresDB } from '.'

export const StoresManagerContext = React.createContext()

export function StoresManager() {
  const milanStores = useFetchMilanStores()
  const recentActivities = useRecentActivities()
  const { currentUser, signout } = useAuth()
  const { globalData, docRef } = useGlobalData()
  const medicalDirectors = useMedicalDirectors()
  const navigate = useNavigate()

  // To scroll to the metro details section
  const detailsRef = useRef(null)
  const scrollToDetails = () => detailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

  const { currentState, currentMetro, currentStore, updateCurrentView } = useUpdateCurrentView(milanStores)

  const updateDB = action => updateMilanStoresDB(milanStores, currentUser, currentState, currentMetro, currentStore, updateCurrentView, medicalDirectors, action)

  const handleSignOut = async () => {
    try {
      await signout()
      navigate(`/${APP_NAME_TRIMMED}/signin`)
    } catch (error) {
      console.error(error)
    }
  }

  const contextValue = {
    updateCurrentView,
    updateDB,
    currentState,
    currentMetro,
    currentStore,
    currentUser,
    milanStores,
    scrollToDetails,
    updateActivities: activity => updateRecentActivities(activity, currentUser)
  }

  return (
    <main className={currentUser && currentUser.role === 'reader' ? 'reader' : ''}>
      {
        milanStores.length > 0 && currentState !== null && currentUser && currentUser.emailVerified &&
        <StoresManagerContext.Provider value={contextValue}>
          <Header {...{ milanStores, currentUser, handleSignOut }} />
          <SelectionsDropdown />
          <span ref={detailsRef}></span>
          {currentMetro && <MetroDetails />}
          <CarouselDivider {...{ milanStores, currentState }} />
          <ControlPanel {...{ recentActivities, globalData, docRef, medicalDirectors }} />
          {
            globalData && globalData.last_updated !== '' && <ToastAlert {...{ currentUser, globalData }} />
          }
        </StoresManagerContext.Provider>
      }
    </main>
  );
}
export default StoresManager
