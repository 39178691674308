import React from 'react'
import { FormContainer } from '../shared/form/FormContainer';
import Backgrounds from './Backgrounds';
import Hero from './Hero';
import ClinicCountCalculator from './ClinicCountCalculator';
import Milestones from './Milestones';
import OpenPositions from './OpenPositions';
import { Helmet } from 'react-helmet'
import './styles.css'

const formVerbiage = "Want a custom graphic to share something exciting about the Milan Magic with your LinkedIn crew? We’re here to help! Fill out this form, and we’ll be in touch with you soon!"

function LinkedIn() {
  return (
    <main id='milan-linkedin'>
      <Helmet>
        <title>Milan Linkedin | Milan Laser</title>
        <meta name="description" content="Images and examples of how our brand comes to life with Milan Linkedin assets." />
      </Helmet>
      <Hero />
      <Backgrounds />
      <ClinicCountCalculator />
      <Milestones />
      <OpenPositions />
      <FormContainer formVerbiage={formVerbiage} />
    </main>
  )
}

export default LinkedIn