import React, { useEffect } from 'react'
import { Link } from "react-router-dom"
import { saveAs } from "file-saver"
import { APP_NAME, APP_NAME_TRIMMED } from './clinic-site-app/utils'
import DecksIcon from './shared/assets/decks-grey.webp'
import LinkedinIcon from './shared/assets/linkedin-grey.webp'
import MilanLogoWhite from './shared/assets/Milan_H-R-Logo.webp'
import RightArrow from './shared/assets/RightArrow.png'
import DownloadIcon from './shared/assets/Deck-download.png'
import MILAN_BLUE_LOGO from './shared/assets/Milan-Logo-Blue.webp'
import PDFIcon from './shared/assets/PDFIcon.svg'
import ZipIcon from './shared/assets/ZipIcon.svg'
import GoogleIcon from './shared/assets/GoogleIcon.svg'
import EmployeeSpotlight from './shared/assets/employee-spotlight.png'
import { INTERNAL_ASSETS_PATH } from './shared/constants'
import { FaSearch, FaSignInAlt, FaSearchLocation } from 'react-icons/fa'

function HomePage() {
  const PresentationsPath = INTERNAL_ASSETS_PATH + 'presentations/'
  const MilanBrandGuide = PresentationsPath + 'MilanBrandGuide-ExpressionSep22.pdf'
  const SocialBestPractices = PresentationsPath + 'Milan-Laser-Social-Advocacy-Best-Practices.pdf'
  const MilanLogoPack = INTERNAL_ASSETS_PATH + 'MilanLogoPack-2022.zip'

  useEffect(() => {
    document.title = "Internal Links | Milan Laser"
  }, [])

  return (
    <main id="home-page">
      <section className="hero background m-0">
        <div className="container">
          <div className="row justify-content-center py-5">
            <img src={MilanLogoWhite} alt="Milan Logo" className="py-sm-5 pt-2 pb-5 mw-100 px-4 px-sm-0" style={{ width: '350px' }} />
            <h1 className="head text-uppercase text-white fw-bold text-center pb-4 lh-base"><strong>All Together Now</strong></h1>
            <div className="d-none d-sm-block col-md-10 col-lg-7 col-xxl-6 text-center mx-auto"><p className="text-white">Bringing the Milan brand to life starts here. Use these high-level brand assets to execute with consistency.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="second pb-md-5">
        <div className="container translate-top">
          <div className="row">
            <div className="d-sm-none col-sm-10 text-center mx-auto py-5"><p>Bringing the Milan brand to life starts here. Use these high-level brand assets to execute with consistency.</p>
            </div>
          </div>
          <div className="row mt-sm-5 mt-md-0 justify-content-center">
            <div className="col-xl-8">
              <div className="row">
                <div className="col-md-6 col-xl-6 mb-4">
                  <div className="card border-0 rounded-0 h-100">
                    <div className="card-body d-flex flex-column p-4">
                      <img src={LinkedinIcon} alt="Milan LinkedIn" className="pt-3 pb-4" style={{ maxWidth: '150px' }} />
                      <h2 className="text-uppercase pb-3 fw-light">Linkedin</h2>
                      <p className="pb-5">Images and examples of how our brand comes to life with Milan Linkedin assets.</p>
                      <Link to="/LinkedIn" className="py-3">
                        <button type="button" className="mt-auto cta-btn red-bg-btn py-2 shadow-sm w-50">Get started</button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6 mb-4">
                  <div className="card border-0 rounded-0 h-100">
                    <div className="card-body d-flex flex-column p-4">
                      <img src={DecksIcon} alt="MilanDeck" className="pt-3 pb-4" style={{ maxWidth: '150px' }} />
                      <h2 className="text-uppercase pb-3 fw-light">Milan Decks</h2>
                      <p className="pb-5">Presentation templates that help you present confidently and consistently – the Milan way.</p>
                      <Link to="/MilanDeck" className="py-3">
                        <button type="button" className="mt-auto cta-btn red-bg-btn py-2 shadow-sm w-50">Get started</button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6 mb-4" style={{ minHeight: '394px' }}>
                  <div className="card border-0 rounded-0 h-100">
                    <div className="card-body d-flex flex-column p-4">
                      <img src={GoogleIcon} alt="MILAN SOCIAL MEDIA POST SUBMISSION" className="pt-3 pb-4" style={{ maxHeight: '100px', maxWidth: '50px' }} />
                      <h2 className="text-uppercase pb-3 fw-light">Social Media Post Submission</h2>
                      <p className="pb-2">Become a Milan Laser Social Media Employee Advocate!</p>
                      <div className="px-0 pt-4 pb-5">
                        <a href="https://docs.google.com/forms/d/1xTHNQHho2Vh8uKzZv6ItAVnJi7qGIqbunW2TV-8F4zM/edit" target="_blank" rel="noreferrer" className="mt-auto">
                          <button className="cta-btn red-bg-btn py-2 shadow-sm text-start px-3"><span className="d-inline-block" style={{ paddingBottom: '2px' }}>Submit Content</span></button>
                        </a>
                        <button onClick={() => saveAs(SocialBestPractices, 'Milan-Laser-Social-Advocacy-Best-Practices.pdf')} className="cta-btn dark-grey-btn py-2 px-3 shadow-sm ms-4">
                          Guidelines <img src={DownloadIcon} alt='' className="ps-3 d-none d-xxl-inline" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6 mb-4" style={{ minHeight: '394px' }}>
                  <div className="card border-0 rounded-0 h-100">
                    <div className="card-body d-flex flex-column p-4">
                      <img src={PDFIcon} alt="Milan Brand Expression" className="pt-3 pb-4" style={{ maxHeight: '100px', maxWidth: '50px' }} />
                      <h2 className="text-uppercase pb-3 fw-light">Milan Brand Expression</h2>
                      <p className="pb-2">How to merge our unique brand voice with visual execution.</p>
                      <div className="text-white pt-4">
                        <button onClick={() => saveAs(MilanBrandGuide, 'MilanBrandGuide.pdf')} className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                          Download <img src={DownloadIcon} alt='' className="ps-3" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6 mb-4" style={{ minHeight: '394px' }}>
                  <div className="card border-0 rounded-0 h-100">
                    <div className="card-body d-flex flex-column p-4">
                      <img src={ZipIcon} alt="Milan Logo Pack" className="pt-3 pb-4" style={{ maxHeight: '100px', maxWidth: '50px' }} />
                      <h2 className="text-uppercase pb-3 fw-light">Milan Logo Pack</h2>
                      <p className="pb-2">Stay on brand by using our approved logos on your projects.</p>
                      <div className="text-white pt-4">
                        <a href={MilanLogoPack} className="cta-btn red-bg-btn py-2 px-3 shadow-sm d-inline-block">Download <img src={DownloadIcon} alt='' className="ps-3" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6 mb-4" style={{ minHeight: '394px' }}>
                  <div className="card border-0 rounded-0 h-100">
                    <div className="card-body d-flex flex-column p-4">
                      <img src={MILAN_BLUE_LOGO} alt="Milan Blue Logo" className="pt-3 pb-4" style={{ maxWidth: '150px' }} />
                      <h2 className="text-uppercase mt-3 pb-5 fw-light">{APP_NAME}</h2>
                      <Link to={`/${APP_NAME_TRIMMED}`} target="_blank" className="py-3 mt-5">
                        <button type="button" className="mt-auto cta-btn red-bg-btn py-2 shadow-sm w-50">Log in <FaSignInAlt /></button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6 mb-4" style={{ minHeight: '394px' }}>
                  <div className="card border-0 rounded-0 h-100">
                    <div className="card-body d-flex flex-column p-4">
                      <figure>
                        <FaSearchLocation className="fs-1" style={{ color: '#999999' }} />
                      </figure>
                      <h2 className="text-uppercase mt-3 pb-3 fw-light">Find Milan</h2>
                      <p className="">Find nearby Milan locations.</p>
                      <p className="pb-2">Search by address, zip code, city, or state.</p>
                      <Link to={`/FindMilan`} target="_blank" className="py-3 mt-5">
                        <button type="button" className="mt-auto cta-btn red-bg-btn py-2 shadow-sm w-50">Find Milan <FaSearch className="ms-2" /></button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6 mb-4" style={{ minHeight: '394px' }}>
                  <div className="card border-0 rounded-0 h-100">
                    <div className="card-body d-flex flex-column p-4">
                    <img src={EmployeeSpotlight} alt="Milan Logo Pack" className="pt-3 pb-2" style={{ maxHeight: '100px', maxWidth: '50px' }} />  
                      <h2 className="text-uppercase mt-3 pb-3 fw-light">Employee Spotlight</h2>
                      <p className="pb-2">Share your own Milan Story, or nominate a colleague’s story, to be featured on LinkedIn!</p>
                      
                      <div className="row px-0 py-3 py-lg-5 py-xl-3 py-xxl-5">
                        <div className="col">
                        <a href="https://app.smartsheet.com/b/form/306192cbf69944da9896cd5d95e02b14" target="_blank" rel="noreferrer" className="mt-auto">
                          <button className="cta-btn red-bg-btn py-2 shadow-sm text-start px-2"><span className="d-inline-block text-center" style={{ paddingBottom: '2px' }}>Employee Spotlight</span></button>
                        </a>
                        </div>
                        <div className="col">
                        <a href="https://app.smartsheet.com/b/form/b6117eb8ce2b4d09a33118bf2c987d12" target="_blank" rel="noreferrer" className="mt-auto">
                          <button className="cta-btn red-bg-btn py-2 shadow-sm text-start px-2"><span className="d-inline-block text-center" style={{ paddingBottom: '2px' }}>Nomination Form</span></button>
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="thirdBG">
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-sm-6 col-lg-7 offset-md-6 offset-lg-5 py-5 mb-sm-5">
              <div className="ps-4 ps-sm-0 ps-lg-4 ps-xxl-5 spacer">
                <h3 className="h1"><strong>IMAGE LIBRARY</strong></h3>
                <p>Every photo says Milan. We use people in our images thoughtfully and with intention to tell stories that showcase the benefits of laser hair removal.</p>
                <div className="px-0 pt-4 pb-5">
                  <a href="https://drive.google.com/drive/folders/1gzEOTo64RSz59Xb_JJjzTVyribKQz4ww?usp=sharing" target="_blank" rel="noreferrer" className="mt-auto">
                    <button className="cta-btn red-bg-btn py-2 shadow-sm text-start px-3">Get started <img src={RightArrow} alt="arrow" /></button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </main>
  )
}

export default HomePage