import React, { useEffect, useState } from "react";
import { auth, provider, db } from "../firebase/firebase";
import { onAuthStateChanged, signOut, signInWithPopup, setPersistence, browserSessionPersistence } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore"
import { MILAN_EMAIL } from "../utils";

export const AuthContextGoogle = React.createContext()

export function AuthProviderGoogle({ children }) {
  const [currentUser, setCurrentUser] = useState(null)

  const signInWithGoogle = () => {
    setPersistence(auth, browserSessionPersistence)
    .then(() => {
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // ...
      // New sign-in will be persisted with session persistence.
      return signInWithPopup(auth, provider)
    })
    .catch((error) => {
      console.log(error.message)
    });    
  }

  const signout = async () => {
    await signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setCurrentUser(currentUser)
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    const getUserFromDatabase = async (user) => {
      const userInDatabaseSnapshot = await getDoc(doc(db, "users", user.uid))
      // if user exists in db
      if (userInDatabaseSnapshot.data()) {
        user.role = userInDatabaseSnapshot.data().role
        user.displayName = userInDatabaseSnapshot.data().displayName
        user.firstName = userInDatabaseSnapshot.data().firstName
        user.lastName = userInDatabaseSnapshot.data().lastName
      } else if (!userInDatabaseSnapshot.data() && user.emailVerified && user.email.includes(MILAN_EMAIL)) {
        // Create a new user in db
        try {
          await setDoc(doc(db, 'users', user.uid), {
            email: user.email,
            displayName: user.displayName,
            firstName: user.displayName.split(' ')[0],
            lastName: user.displayName.split(' ').slice(1).join(' '),
            role: 'reader'
          })
          user.role = 'reader'
        } catch (error) {
          console.log('Error signing up', error)
        }
      } else {
        signout()
      }
    }
    if (currentUser) {
      getUserFromDatabase(currentUser)
    }
  }, [currentUser])

  // This value object will be returned when useAuth() is imported
  const value = {
    currentUser,
    signInWithGoogle,
    signout
  }

  return (
    <AuthContextGoogle.Provider value={value} >
      {children}
    </AuthContextGoogle.Provider>
  );
}
