import React, { useState, useContext, useRef, useEffect } from 'react'
import { FaSearch } from 'react-icons/fa'
import Form from "react-bootstrap/Form"
import ListGroup from "react-bootstrap/ListGroup"
import { trimAll } from '../utils'
import { useClickOutside } from '../hooks'
import { StoresManagerContext } from '.'

// Don't import hardTrim - it doesn't lowercase the string
const hardTrim = str => trimAll(str).replace(/[^a-z0-9]/gi, '');

export function SearchBar() {
  const { milanStores, updateCurrentView, scrollToDetails } = useContext(StoresManagerContext)
  const [foundStores, setFoundStores] = useState([])
  const [inputValue, setInputValue] = useState("")
  const domNode = useClickOutside(() => setFoundStores([]))

  const onChangeHandler = e => {
    setFoundStores([])
    setInputValue(e.target.value)
    if (e.target.value === "") {
      setFoundStores([])
      return
    }
    let foundStoresArr = []
    milanStores.forEach((state, s) => {
      if (state.metros.length !== 0) {
        state.metros.forEach((metro, m) => {
          if (metro.stores.length !== 0) {
            metro.stores.forEach((store, x) => {
              const value = hardTrim(e.target.value)
              if (hardTrim(store.marketingCity).includes(value) || hardTrim(store.addressCity).includes(value) || hardTrim(store.clinicName).includes(value) || hardTrim(metro.name).includes(value)) {
                foundStoresArr.push({
                  marketingCity: store.marketingCity,
                  salesforceValue: store.salesforceValue,
                  stateAbbrev: store.stateAbbrev,
                  metroPathname: metro.pathname,
                  metroName: metro.name,
                  stateName: state.name,
                })
              }
            })
          }
        })
      }
    })
    setFoundStores(foundStoresArr)
  }

  const onStoreClicked = (store) => {
    updateCurrentView({
      stateName: store.stateName,
      metroPathname: store.metroPathname,
      salesforceValue: store.salesforceValue
    })
    setFoundStores([])
    setInputValue("")
    // scrollToDetails()
  }

    // Keyboard navigation
    const listRef = useRef(null)
    const searchBarRef = useRef(null)
    const [count, setCount] = useState(0)
    const handleKeyDown = e => {
      if (e.key === 'ArrowDown' && listRef.current && count < foundStores.length) {
        setCount(prevCount => prevCount + 1)
      } else if (e.key === 'ArrowUp' && listRef.current && count > 1) {
        setCount(prevCount => prevCount - 1)
      } else if (e.key === 'Enter' && listRef.current && count > 0) {
        onStoreClicked(foundStores[count - 1])
        setCount(0)
        setFoundStores([])
      } else {
        setCount(0)
      }
    }
    useEffect(() => {
      if (listRef.current && listRef.current.children && count === 0) {
        searchBarRef.current.focus()
      }
      if(listRef.current && listRef.current.children && count > 0) {
        listRef.current.children[count - 1].focus()
      }
    }, [count])

  return (
    <div ref={domNode} className="search-bar-wrapper">
      <div className="search-input input-group">
        <Form.Control ref={searchBarRef} type="search" placeholder="Search locations..." onChange={onChangeHandler} value={inputValue} onKeyDown={handleKeyDown} />
        <span className="input-group-text"><FaSearch /></span>
      </div>
      <div className="search-results position-relative">
        {
          foundStores.length !== 0 &&
          <ListGroup ref={listRef} className="align-items-center position-absolute w-100 bg-white">
            {
              foundStores.map((store, x) => (
                <ListGroup.Item className="pointer list-group-item-action" tabIndex={-1} key={x} onClick={() => onStoreClicked(store)} onKeyDown={handleKeyDown}>
                  {store.metroName} ({store.marketingCity} | {store.stateAbbrev})
                </ListGroup.Item>
              )
              )
            }
          </ListGroup>
        }
      </div>
    </div>
  );
}
export default SearchBar;
