import React from 'react'
import Table from 'react-bootstrap/Table'

export function UpcomingClinics({ milanStores, updateCurrentView, scrollToDetails }) {

  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))

  const openDatesArr = milanStores.map(state => state.metros.map(metro => metro.stores.map(store => ({
    openDate: store.openDate !== '' ? new Date(store.openDate.toDate()) : null,
    marketingCity: store.marketingCity,
    metro: metro.name,
    stateAbbrev: store.stateAbbrev,
    stateName: state.name,
    metroPathname: metro.pathname,
    salesforceValue: store.salesforceValue
  })))).flat(2).sort((a, b) => b.openDate - a.openDate).filter(store => !store.openDate || store.openDate > yesterday)

  let UPCOMING = []
  let TBD = []
  openDatesArr.forEach(store => {
    if (store.openDate) UPCOMING.unshift(store)
    else TBD.push(store)
  })

  const onStoreClicked = (store) => {
    updateCurrentView({
      stateName: store.stateName,
      metroPathname: store.metroPathname,
      salesforceValue: store.salesforceValue
    })
    scrollToDetails()
  }

  return (
    <div className="container-fluid py-5">
      <div className="row">
        <div className="col-lg-8 col-xxl-6">
          <h4>Scheduled</h4>
          {
            UPCOMING.length > 0 &&
            <Table striped hover bordered>
              <thead>
                <tr>
                  <th>Clinic</th>
                  <th>Metro Area</th>
                  <th>Open Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  UPCOMING.map((store, x) => (
                    <tr className="pointer" key={x} onClick={() => onStoreClicked(store)}>
                      <td>{store.marketingCity} | {store.stateAbbrev} <small className="text-success ms-4">{store.openDate && store.openDate.toString().slice(4, 15) === (new Date()).toString().slice(4, 15) && '(Opens Today)'}</small></td>
                      <td>{store.metro}</td>
                      <td><span className="main-blue">{store.openDate.toString().slice(4, 15)}</span></td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          }
        </div>
        <div className="col-lg-4">
          <h4>TBD</h4>
          {
            TBD.length > 0 &&
            <Table striped hover bordered>
              <thead>
                <tr>
                  <th>Clinic</th>
                  <th>Metro Area</th>
                  <th>Open Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  TBD.map((store, x) => (
                    <tr className="pointer" key={x} onClick={() => onStoreClicked(store)}>
                      <td>{store.marketingCity} | {store.stateAbbrev}</td>
                      <td>{store.metro}</td>
                      <td>TBD</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          }
        </div>
      </div>
    </div>
  )
}

export default UpcomingClinics