import React, { useState } from "react"
import Form from "react-bootstrap/Form"
import ListGroupItem from "react-bootstrap/ListGroupItem"
import ListGroup from "react-bootstrap/ListGroup"
import { MDSearchBar, AssignMDForm } from '.'

export function MedicalDirectors({ medicalDirectors, currentUser }) {
  const isAdminOrCMO = currentUser.emailVerified && (currentUser.role === 'admin' || currentUser.role === 'CMO')
  const [director, setDirector] = useState(medicalDirectors[0])
  const [storesToBeAssigned, setStoresToBeAssigned] = useState([])
  const [success, setSuccess] = useState(false)

  const onDirectorChange = value => {
    setDirector(medicalDirectors.find(md => md.displayName === value))
  }

  return (
    <div id="medical-directors" className="container-fluid py-5 px-3 px-md-5">
      <h3 className="pb-4">Medical Directors </h3>
      <div className="row g-4 justify-content-between align-items-center">
        <div className="col-lg-5">
          <Form className="text-center text-lg-start">
            <Form.Select
              className="d-lg-inline py-2 w-100" aria-label="metros query"
              value={director.displayName} name="metros-query" onChange={(e) => onDirectorChange(e.target.value)}
              disabled={storesToBeAssigned.length > 0}>
              {medicalDirectors.map(md => <option key={md.id} value={md.displayName}>{md.displayName}</option>)}
            </Form.Select>
          </Form>
        </div>
        {
          director.displayName !== 'Not Assigned' &&
          <div className="col-lg-2">
            <figure className="overflow-hidden rounded-circle mx-auto mb-0" style={{ maxWidth: '80px', maxHeight: '80px' }}>
              <img src={director.url} className="w-100" alt="" />
            </figure>
          </div>
        }
        <div className="col-lg-5">
          <MDSearchBar {...{ medicalDirectors, director, onDirectorChange, isAdminOrCMO, storesToBeAssigned, setStoresToBeAssigned }} />
        </div>
      </div>
      <div className="row justify-content-between my-5">
        <div className="col-lg-7">
          {
            director.displayName !== 'Not Assigned' ?
              <h4><strong className="main-blue">{director.locations.length}</strong> locations assigned to <strong className="main-blue">{director.displayName}</strong></h4>
              :
              <h4><strong className="text-coral">{director.locations.length}</strong> unassigned locations</h4>
          }
        </div>
        <div className="col-lg-5">
          {
            success &&
            <div className="alert alert-success text-center" role="alert">
              SUCCESS!
            </div>
          }
          {
            isAdminOrCMO && storesToBeAssigned.length > 0 &&
            <AssignMDForm {...{ director, storesToBeAssigned, setStoresToBeAssigned, isAdminOrCMO, medicalDirectors, setSuccess }} />
          }
        </div>
      </div>
      <div className="row g-4">
        {
          director.locations.length > 0 && director.locations.map((location, x) => (
            <ListGroup
              key={x}
              className="col-6 col-lg-4 col-xl-3">
              <ListGroupItem className="text-center btn disabled-bg rounded-0" style={{ cursor: 'default' }}>
                {location.name} | {location.stateAbbrev}
              </ListGroupItem>
            </ListGroup>
          ))}
      </div>
    </div>
  )
}

export default MedicalDirectors