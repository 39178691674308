import React, { useState } from 'react'
import { FaCarSide, FaStar, FaDirections, FaCalendarTimes, FaStoreAlt, FaUsers, FaClock } from 'react-icons/fa'
import { MILAN_SITE_START, MILAN_PHONE } from '../shared/constants'
import Clock from 'react-live-clock';

export function NearbyStoreCard({ store, showOnMap, highlighted }) {
  const [moreInfo, setMoreInfo] = useState(false)
  return (
    <div className={`card h-100 rounded ${highlighted ? 'highlighted' : 'border-0'}`}>
      <div className="card-body">
        <h2 className="card-title h5 mb-1">
          <a href={store.metro.singleStore ?
            `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.pathname}`
            :
            `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.metro.metroPath}/${store.pathname}/`}
            className="fw-bold milan-text-primary" target="_blank" rel="noreferrer">
            {store.metro.name === store.marketingCity ? <span>{store.metro.name}</span> : <span>{store.metro.name} ({store.marketingCity})</span>} 
          </a>
        </h2>

        <p className="card-text text-muted mb-2">
          <small>{store.address}</small><br />
          <small>{store.addressCity === '' ? store.marketingCity : store.addressCity}, {store.stateAbbrev} {store.zipCode}</small>
        </p>

        <ul id="nearby-stores-list" className="row gy-3 flex-wrap mb-3">
          {
            !store.open &&
            <li className="col-4 text-center">
              <div>
                <FaCalendarTimes className="mb-1 milan-text-red" />
              </div>
              <div className="text-muted milan-fs-7">
                Coming Soon
              </div>
            </li>
          }
          {
            store.maps.google !== '' &&
            <li className="col-4 text-center">
              <a href={store.metro.singleStore ?
                `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.pathname}`
                :
                `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.metro.metroPath}/${store.pathname}/`} target="_blank" rel="noreferrer">
                <div>
                  <FaStoreAlt className="mb-1 milan-text-tur" />
                </div>
                <div className="text-muted milan-fs-7">Clinic Page</div>
              </a>
            </li>
          }
          {
            store.maps.google !== '' &&
            <li className="col-4 text-center">
              <a href={store.maps.google} target="_blank" rel="noreferrer">
                <div>
                  <FaDirections className="mb-1 milan-text-tur" />
                </div>
                <div className="text-muted milan-fs-7">Google Maps</div>
              </a>
            </li>
          }
          {
            store.maps.apple !== '' &&
            <li className="col-4 text-center">
              <a href={store.maps.apple} target="_blank" rel="noreferrer">
                <div>
                  <FaDirections className="mb-1 milan-text-tur" />
                </div>
                <div className="text-muted milan-fs-7">Apple Maps</div>
              </a>
            </li>
          }
          {
            store.timezone !== '' &&
            <li className="col-4 text-center">
              <div>
                <FaClock className="mb-1 milan-text-tur" />
              </div>
              <div className="text-muted milan-fs-7"> <Clock format={'hh:mm'} blinking={true} ticking={true} timezone={`US/${store.timezone}`} /></div>
            </li>
          }
          {
            store.maps.google !== '' &&
            <li className="col-4 text-center">
              <a href={store.metro.singleStore ?
                `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.pathname}/#staff`
                :
                `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.metro.metroPath}/${store.pathname}/#staff`} target="_blank" rel="noreferrer">
                <div>
                  <FaUsers className="mb-1 milan-text-tur" />
                </div>
                <div className="text-muted milan-fs-7">Staff</div>
              </a>
            </li>
          }
          {
            (store.rating && store.reviewCount) &&
            <li className="col-4 text-center">
              <div>
                <FaStar className="mb-1 milan-text-tur" /> <small className="">{store.rating}/5</small>
              </div>
              <div className="text-muted milan-fs-7">
                {store.reviewCount} reviews
              </div>
            </li>
          }
        </ul>

        <div className="card-text">
          <span type="button" className="milan-text-primary milan-fs-7 text-decoration-underline" onClick={() => setMoreInfo(!moreInfo)}>
            {moreInfo ? 'Less info' : 'More info'}
          </span>
          <span type="button" className="milan-fs-7 float-end py-1 px-3 rounded fm-btn" onClick={() => showOnMap(store)}>
            Show on map
          </span>
        </div>
        {
          moreInfo &&
          <div className="ps-1">
            <p className="card-text my-1 milan-fs-7 text-muted">
              <span className="fw-bold">Metro area: </span>
              <a href={`${MILAN_SITE_START}${store.metro.pathname}.com`} target="_blank" rel="noreferrer" className="milan-text-primary">{store.metro.name}, {store.state.abbrev}</a>
            </p>
            {
              store.timezone &&
              <p className="card-text my-1 milan-fs-7 text-muted">
                <span className="fw-bold">Time zone:</span> <span>{store.timezone}</span>
              </p>
            }
            <p className="card-text my-1 milan-fs-7 text-muted">
              <span className="fw-bold">Clinic phone:</span> <span>{store.phone === '' ? MILAN_PHONE : store.phone}</span>
            </p>
            {
              store.openDate && store.openDate !== '' && store.openDate.seconds &&
              <div className="card-text my-1 milan-fs-7 text-muted">
                <span className="fw-bold">{store.open ? 'Opened' : 'Opens'}:</span> <span className="text-muted">{new Date(store.openDate.toDate()).toString().slice(4, 15)}</span>
              </div>
            }
            {
              store.description.line_one &&
              <div className="card-text milan-fs-7 text-muted">
                <span>Located {store.description.line_one} {store.description.line_two}.</span>
              </div>
            }
          </div>
        }

      </div>
      {
        store.matrix && store.matrix.distance &&
        <div className="card-footer d-flex justify-content-between fst-italic border-color-light-grey">
          <div>{store.matrix.distance && <small>{store.matrix.distance.text}</small>}</div>
          <div>{store.matrix.duration && <small>{store.matrix.duration.text} <FaCarSide /></small>}</div>
        </div>
      }
    </div>
  )
}

export default NearbyStoreCard