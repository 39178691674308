import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from '.'
import { FaAsterisk } from 'react-icons/fa'

export function DefaultInput(props) {
  const { name, label, optional, small, ...rest } = props
  return (
    <div className="row py-1 g-1">
      <div className="col-4">
        <label htmlFor={name} className="form-label col-form-label text-capitalize">{label || name} {!optional && <sup><FaAsterisk /></sup>}</label>
      </div>
      <div className="col-8">
        <Field name={name} id={name} className="form-control" {...rest} />
        <ErrorMessage name={name} component={TextError} />
        {small && <small className="text-black-50"><i>{small}</i></small>}
      </div>
    </div>
  )
}

export default DefaultInput