import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form"
import ListGroupItem from "react-bootstrap/ListGroupItem"

export function MetrosQuery({ milanStores, updateCurrentView, scrollToDetails, currentUser }) {

  const isAdminOrEditor = currentUser.role === 'admin' || currentUser.role === 'editor'
  const [foundMetros, setFoundMetros] = useState([])
  // This queryType along with the useEffect keeps the list up to date if any change happens to any metro
  const [queryType, setQueryType] = useState("grandOpening")
  const onChangeHandler = e => {
    setQueryType(e.target.value)
    const query = e.target.value
    setFoundMetros(findMetros(query))
  }

  useEffect(() => {
    // To keep the list updated when a metro gets edited or a new metro is added
    setFoundMetros(findMetros(queryType))
  }, [milanStores])

  const findMetros = query => {
    let foundMetrosArr = []
    milanStores.forEach(state => {
      if (state.metros.length !== 0) {
        state.metros.forEach(metro => {
          if ((query === 'grandOpening' && metro.grandOpening) || (query !== 'grandOpening' && (metro[query] === '' || !metro[query]))) {
            foundMetrosArr.push(metro)
          }
        })
      }
    })
    return foundMetrosArr.sort((a, b) => a.stateAbbrev.localeCompare(b.stateAbbrev))
  }

  const onMetroClicked = metro => {
    updateCurrentView({
      stateName: metro.state,
      metroPathname: metro.pathname,
      salesforceValue: metro.stores.length > 0 ? metro.stores[0].salesforceValue : ""
    })
    scrollToDetails()
  }

  return (
    <div className="container-fluid py-5">
      <div className="row g-4 justify-content-center justify-content-lg-start">
        <Form>
          <Form.Select
            className="d-lg-inline w-auto py-2" aria-label="metros query"
            defaultValue="" name="metros-query" onChange={onChangeHandler}
            style={{ minWidth: '370px' }} >
            <option value="grandOpening">Metros with Grand Opening Specials</option>
            <option value="All Sites">All Milan Sites</option>
            <option value="gtag_id" disabled={!isAdminOrEditor}>Missing GTAG ID</option>
            <option value="google_analytics_id" disabled={!isAdminOrEditor}>Missing Google Analytics ID</option>
            <option value="bing_id" disabled={!isAdminOrEditor}>Missing Bing ID</option>
            <option value="google_verification" disabled={!isAdminOrEditor}>Missing Google Verification ID</option>
          </Form.Select>
        </Form>
        {foundMetros.length > 0 && <h4 className="mb-4 h3"><strong className="main-blue">{foundMetros.length}</strong> metros found</h4>}
        {
          foundMetros.length > 0 && foundMetros.map((metro, x) => (
            <div
              key={x} onClick={() => onMetroClicked(metro)}
              className="col-6 col-lg-4 col-xl-3">
              <ListGroupItem className="btn btn-outline-dark border text-center shadow-sm">
                {metro.name}, {metro.stateAbbrev}
              </ListGroupItem>
            </div>
          ))}
        {
          foundMetros.length === 0 &&
          <div className="col-6 col-lg-4 col-xl-3">
            <div className="alert alert-secondary text-center" role="alert">No Metros found!</div>
          </div>
        }
      </div>
    </div>
  );
}
export default MetrosQuery
