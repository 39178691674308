import { useState, useEffect, useRef } from 'react'

export function useUpdateCurrentView(milanStores) {
  const [currentState, setCurrentState] = useState(null)
  const [currentMetro, setCurrentMetro] = useState(null)
  const [currentStore, setCurrentStore] = useState(null)

  const updateCurrentView = toBeDisplayed => {
    if (milanStores.length > 0) {
      const foundState = milanStores.find(state => state.name === toBeDisplayed.stateName)
      setCurrentState(foundState)
      localStorage.setItem('stateName', foundState.name)
      const foundMetro = foundState.metros.find(metro => metro.pathname === toBeDisplayed.metroPathname)
      setCurrentMetro(foundMetro ? foundMetro : null)
      localStorage.setItem('metroPathname', foundMetro ? foundMetro.pathname : null)
      // When using the Searchbar, it will find a store for sure, but when using metros dropdown, not necessarily 
      const foundStore = foundMetro ? foundMetro.stores.find(store => store.salesforceValue === toBeDisplayed.salesforceValue) : null
      setCurrentStore(foundStore)
      localStorage.setItem('salesforceValue', foundStore ? foundStore.salesforceValue : null)
    }
  }
  
  const didMount = useRef(false)
  useEffect(() => {
    // This will run only once after milanStores is fetched
    if (!didMount.current && milanStores.length > 0) {
      didMount.current = true
      // Check if first state has a metro
      const hasMetro = milanStores[0].metros.length !== 0
      const hasStore = hasMetro && milanStores[0].metros[0].stores.length !== 0
      updateCurrentView({
        stateName: localStorage.getItem('stateName') && localStorage.getItem('stateName') !== 'null' ? localStorage.getItem('stateName') : milanStores[0].name,
        metroPathname: localStorage.getItem('metroPathname') && localStorage.getItem('metroPathname') !== 'null' ? localStorage.getItem('metroPathname') : hasMetro ? milanStores[0].metros[0].pathname : "",
        salesforceValue: localStorage.getItem('salesforceValue') && localStorage.getItem('salesforceValue') !== 'null' ? localStorage.getItem('salesforceValue') : hasStore ? milanStores[0].metros[0].stores[0].salesforceValue : ""
      })
    }
    if (didMount.current && milanStores.length > 0 && currentState) {
      // This will run every time the db gets updated by other users
      // In that case, check if the currentMetro & the currentStore still exist
      // This will update the view for other users in case their currentMetro or their currentStore got deleted in db by an admin
      const matchedCurrentState = milanStores.find(state => state.name === currentState.name)
      const matchedCurrentMetro = matchedCurrentState && currentMetro && matchedCurrentState.metros.find(metro => metro.pathname === currentMetro.pathname)
      const matchedCurrentStore = matchedCurrentMetro && currentStore && matchedCurrentMetro.stores.find(store => store.salesforceValue === currentStore.salesforceValue)
      updateCurrentView({
        stateName: matchedCurrentState ? matchedCurrentState.name : milanStores[0].name,
        metroPathname: matchedCurrentMetro ? matchedCurrentMetro.pathname : "",
        salesforceValue: matchedCurrentMetro && matchedCurrentStore ? matchedCurrentStore.salesforceValue : ""
      })
    }
  }, [milanStores])
  return { currentState, currentMetro, currentStore, updateCurrentView }
}
export default useUpdateCurrentView