import React from 'react'

function FormSuccess() {
  return (
    <center className="px-3 success">
      <p className="h1 text-success">SUCCESS!</p>
      <p className="h4 mt-4 mb-3">A member of the design team will contact you shortly.</p>
    </center>
  )
}

export default FormSuccess