export const proInitialValues = { 
  Hero: '',
  HeroXL: '',
  Thumbnail: '',
  FrontDesk: '',
  ConsultationRoom: '',
  Lobby: '',
  TXRoom: '',
  Portrait: '',
  FrontDoor: '',
  ShoppingCenterSignage: ''
}