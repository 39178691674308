import React, { useEffect } from "react"
import { useAuth } from "../hooks"
import { useNavigate } from "react-router-dom"
import { FormWrapper } from "."
import { APP_NAME_TRIMMED, MILAN_EMAIL } from "../utils"
import { FaSignInAlt } from 'react-icons/fa'

export function SignInWithGoogle() {
  const { signInWithGoogle, signout, currentUser } = useAuth()
  const navigate = useNavigate()

  const handleSignIn = async () => {
    try {
      const userCredentials = await signInWithGoogle();
      if (userCredentials && userCredentials.user.email.includes(MILAN_EMAIL) && userCredentials.user.emailVerified) {
        navigate(`/${APP_NAME_TRIMMED}`)
      } else {
        signout()
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    // This is useful when refreshing the browser
    if (currentUser && currentUser.emailVerified && currentUser.email.includes(MILAN_EMAIL)) {
      navigate(`/${APP_NAME_TRIMMED}`)
    }
  }, [currentUser])


  return (
    <FormWrapper>
      <button type="button" onClick={handleSignIn} className="btn navy-bg-btn btn-lg mt-5">Sign in with Google <FaSignInAlt className="ms-2" /></button>
    </FormWrapper>
  );
}
export default SignInWithGoogle
