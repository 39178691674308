import React from "react";
// import { Navigate, Route } from 'react-router-dom'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from "../hooks"
import { APP_NAME_TRIMMED, MILAN_EMAIL } from "../utils"

export function PrivateRoute() {
  const { currentUser } = useAuth()
  return (
    <>
    {
      currentUser && currentUser.emailVerified && currentUser.email.includes(MILAN_EMAIL) ?
      <Outlet /> : <Navigate to={`/${APP_NAME_TRIMMED}/signin`} />
    }
    </>
  );
}
export default PrivateRoute;
