import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik"
import { doc, updateDoc, deleteDoc } from "firebase/firestore"
import { db } from "../../firebase/firebase"
import * as Yup from 'yup'
import { TextError, FormikControl } from "../../utils"
import { FaEdit, FaTrashAlt, FaUserEdit } from 'react-icons/fa'

const validationSchema = Yup.object({
  role: Yup.string().required('Required field!'),
  displayName: Yup.string().required('Required field!'),
  firstName: Yup.string().required('Required field!'),
  lastName: Yup.string().required('Required field!'),
})
const FIREBASE_COLLECTION = 'users'

export function EditUser({ admin, selectedUser, users, setSelectedUser, handlePaginationClick }) {
  const [formMode, setFormMode] = useState(null)

  const onSubmit = (data) => {
    try {
      if (admin.emailVerified && admin.role === 'admin') {
        if (formMode === 'delete') onDeleteUser()
        if (formMode === 'edit') onEditUser(data)
        else return
      } else return
    } catch (error) {
      console.error(error)
    }
  }

  const onEditUser = async (data) => {
    try {
      await updateDoc(doc(db, FIREBASE_COLLECTION, selectedUser.id), {
        displayName: data.displayName,
        firstName: data.firstName,
        lastName: data.lastName,
        role: data.role
      })
    } catch (error) {
      console.error('Error updating user', error)
    } finally {
      setFormMode(null)
    }
  }
  const onDeleteUser = async () => {
    try {
      await deleteDoc(doc(db, FIREBASE_COLLECTION, selectedUser.id))
      // After deletion, default to first page and first user
      handlePaginationClick(1)
      setSelectedUser(users[0])
    } catch (error) {
      console.error(error)
    } finally {
      setFormMode(null)
    }
  }

  return (
    <Formik
      initialValues={selectedUser}
      validationSchema={validationSchema}
      onSubmit={(data) => onSubmit(data)}
      enableReinitialize >
      {
        formik => {
          return (
            <Form className={`px-4 w-100 bg-white border p-4`}>
              <h2 className="h4 mb-3"><strong className="main-blue">{selectedUser.displayName} </strong> {selectedUser.role === 'admin' && <small>(Admin)</small>} <FaUserEdit className="mb-1 main-blue float-end" /> </h2>
              <div className="row gy-3 gx-5">
                <div className="col-12">
                  <div className="row pt-2 g-1">
                    <div className="col-4">
                      <label>Email </label>
                    </div>
                    <div className="col-8">
                      <p className="mb-0">{selectedUser.email}</p>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <FormikControl control="defaultInput" name="displayName" label="Display Name" disabled={formMode !== 'edit'} />
                </div>
                <div className="col-12">
                  <FormikControl control="defaultInput" name="firstName" label="First Name" disabled={formMode !== 'edit'} />
                </div>
                <div className="col-12">
                  <FormikControl control="defaultInput" name="lastName" label="Last Name" disabled={formMode !== 'edit'} />
                </div>
                <div className="col-12">
                  <div className="row py-1 g-1">
                    <div className="col-4">
                      <label htmlFor="role" className="form-label col-form-label text-capitalize">Role </label>
                    </div>
                    <div className="col-8">
                      <Field as="select" name="role" id="role" className="form-select" disabled={formMode !== 'edit'}>
                        <option value="admin">Admin</option>
                        <option value="editor">Editor</option>
                        <option value="designer">Designer</option>
                        <option value="copywriter">Copywriter</option>
                        <option value="coordinator">Coordinator</option>
                        <option value="HR">HR</option>
                        <option value="reader">Reader</option>
                      </Field>
                      <ErrorMessage name="role" component={TextError} />
                    </div>
                  </div>
                </div>
              </div>
              {/* ************ Form Actions Control ************** */}
              {
                admin.emailVerified && admin.role === 'admin' &&
                <>
                  {
                    formMode === 'delete' &&
                    <div className="alert alert-warning mt-2" role="alert">
                      <strong className="main-blue">{selectedUser.email}</strong> will be deleted! <br />
                      Are you sure?
                    </div>
                  }
                  {
                    formMode ?
                      <div className="mt-4">
                        <input type="submit" value={formMode === 'delete' ? 'Delete user' : 'Update'} className={`btn px-5 me-3 btn-${formMode === 'delete' ? 'delete' : 'submit'}`} disabled={!formik.isValid} />
                        <button type="button" className="btn btn-cancel float-end px-3" onClick={() => (setFormMode(null))}>Cancel</button>
                      </div>
                      :
                      <div className="mt-4">
                        <button type="button" className="btn btn-edit shadow-sm" onClick={() => setFormMode('edit')}> <FaEdit className="mb-1" /> Edit user</button>
                        {
                          admin.role === 'admin' &&
                          <button type="button" className="btn btn-delete shadow-sm float-end" onClick={() => setFormMode('delete')}>
                            <FaTrashAlt className="mb-1" /> Delete user
                          </button>
                        }
                      </div>
                  }
                </>
              }
            </Form>
          )
        }
      }
    </Formik>
  )
}

export default EditUser