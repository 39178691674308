import { replaceComma, toMinutes } from "."

export const sortCallback = (a, b, sortType) => {
  if (sortType === 'distance') {
    let x = a.matrix.distance.text.includes('ft') ? 0 : Number(replaceComma(a.matrix.distance.text.slice(0, -3)))
    let y = b.matrix.distance.text.includes('ft') ? 0 : Number(replaceComma(b.matrix.distance.text.slice(0, -3)))
    return (x - y)
  } else {
    let a_to_minutes = toMinutes(a.matrix.duration.text)
    let b_to_minutes = toMinutes(b.matrix.duration.text)
    return (a_to_minutes - b_to_minutes)
  }
}