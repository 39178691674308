import React from 'react'
import { Field, ErrorMessage } from 'formik'
import InputMask from 'react-input-mask'
import { TextError } from '.'

export function PhoneInput(props) {
  const { label, name, ...rest } = props
  return (
    <div>
      <div className="input-group mb-2" >
        <div className="input-group-prepend">
          <label htmlFor={name} className="input-group-text">{label || name}</label>
        </div>
        <Field name={name} >
          { // These props are coming from the Formik Field compoenent and it contains: field, form, meta
            props => {
              const { field } = props
              return <>
                <InputMask
                  mask="999-999-9999"
                  maskChar={null}
                  className="form-control phone_input"
                  id={name}
                  {...field} {...rest}
                  placeholder="000-000-0000" />
              </>
            }
          }
        </Field>
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default PhoneInput