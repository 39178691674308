import React from 'react'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import './styles.css'

export function StatesDropdownSection({ milanStores }) {
  return (
    <ol id="states-dropdown-section" className="row gy-2 gy-md-3">
    {
      milanStores.length > 0 && milanStores.map(state => (
        <li key={state.id} className="col-6 col-md-4 col-xl-3 col-xxl-2 text-center">
          <DropdownButton
            as={ButtonGroup}
            drop="down"
            title={state.name}
            className="btn-group__state"
          >
            {
              state.metros.length > 0 && state.metros.map((metro, m) => (
                <React.Fragment key={m}>
                  {
                    metro.stores.length > 1 ?
                      <Dropdown drop="end">
                        <Dropdown.Toggle>
                          {metro.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            metro.stores.map((store, s) => (
                              <Dropdown.Item key={s} href={`${metro.origin}/locations/${metro.metroPath}/${store.pathname}`} target="_blank" rel="noreferrer">{store.marketingCity}</Dropdown.Item>
                            ))
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                      :
                      <Dropdown.Item href={`${metro.origin}/locations/${metro.stores[0].pathname}`} target="_blank" rel="noreferrer">{metro.name}</Dropdown.Item>
                  }
                </React.Fragment>
              ))
            }
          </DropdownButton>
        </li>
      ))
    }

  </ol>
  )
}

export default StatesDropdownSection