import React, { useState, useRef, useEffect } from 'react'
import { FaSearch } from 'react-icons/fa'
import Form from "react-bootstrap/Form"
import ListGroup from "react-bootstrap/ListGroup"
import { hardTrim } from '../../utils'
import { useClickOutside } from '../../hooks'

export function UserSearchBar({ users, setSelectedUser, LIST_COUNT_PER_PAGE, handlePaginationClick }) {
  const [foundUsers, setFoundUsers] = useState([])
  const [inputValue, setInputValue] = useState("")
  const domNode = useClickOutside(() => setFoundUsers([]))

  const onChangeHandler = e => {
    setFoundUsers([])
    setInputValue(e.target.value)
    if (e.target.value === "") {
      setFoundUsers([])
      return
    }
    let foundUsersArr = []
    users.forEach((user) => {
      const value = hardTrim(e.target.value)
      if (hardTrim(user.displayName).includes(value) || hardTrim(user.email).includes(value) || hardTrim(user.role).includes(value)) {
        foundUsersArr.push(user)
      }
    })
    setFoundUsers(foundUsersArr)
  }

  const onUserClicked = (user) => {
    setFoundUsers([])
    setInputValue("")
    setSelectedUser(user)
    // Calculate the right page number to display
    const pageNumber = user.index === 0 ? 1 : Math.ceil(user.index / LIST_COUNT_PER_PAGE)
    handlePaginationClick(pageNumber)
  }

  // Keyboard navigation
  const listRef = useRef(null)
  const searchBarRef = useRef(null)
  const [count, setCount] = useState(0)
  const handleKeyDown = e => {
    if (e.key === 'ArrowDown' && listRef.current && count < foundUsers.length) {
      setCount(prevCount => prevCount + 1)
    } else if (e.key === 'ArrowUp' && listRef.current && count > 1) {
      setCount(prevCount => prevCount - 1)
    } else if (e.key === 'Enter' && listRef.current && count > 0) {
      onUserClicked(foundUsers[count - 1])
      setCount(0)
      setFoundUsers([])
    } else {
      setCount(0)
    }
  }
  useEffect(() => {
    if (listRef.current && listRef.current.children && count === 0) {
      searchBarRef.current.focus()
    }
    if(listRef.current && listRef.current.children && count > 0) {
      listRef.current.children[count - 1].focus()
    }
  }, [count])

  return (
    <div ref={domNode} className="search-bar-wrapper mb-4 mb-lg-0">
      <div className="search-input input-group">
        <Form.Control ref={searchBarRef} type="search" placeholder="Search users ..." onChange={onChangeHandler} value={inputValue} onKeyDown={handleKeyDown} />
        <span className="input-group-text"><FaSearch /></span>
      </div>
      <div className="search-results position-relative">
        {
          foundUsers.length !== 0 &&
          <ListGroup ref={listRef} className="align-items-center position-absolute w-100 bg-white " >
            {
              foundUsers.map((user, x) => (
                <ListGroup.Item className="pointer list-group-item-action" tabIndex={-1} key={x} onClick={() => onUserClicked(user)} onKeyDown={handleKeyDown} >
                  {user.displayName}
                </ListGroup.Item>
              )
              )
            }
          </ListGroup>
        }

      </div>
    </div>
  )
}

export default UserSearchBar