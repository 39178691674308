import React, { useState } from 'react'
import { SearchBar } from '.'
import Button from "react-bootstrap/Button"
import { FaSignOutAlt, FaCopy } from 'react-icons/fa'
import MILAN_BLUE_LOGO from '../../shared/assets/Milan-Logo-Blue.webp'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export function Header({ milanStores, currentUser, handleSignOut }) {
  const milanStoresJson = JSON.stringify(milanStores)
  const [copyJson, setCopyJson] = useState(false)

  const handleCopyJson = () => {
    setCopyJson(true)
    setTimeout(() => {
      setCopyJson(false)
    }, 5000)
  }

  return (
    <header className="container-fluid p-3 px-md-5">
      <div className="row justify-content-between py-3">
        <div className="col">
          <p className="fs-5">
            {
              currentUser.photoURL && currentUser.photoURL !== '' &&
              <img className="rounded-circle border shadow-sm me-3" src={currentUser.photoURL} alt="profile" style={{ maxWidth: '50px' }} />
            }
            Hello <span className="main-blue">{currentUser.displayName}</span>
          </p>
        </div>
        <div className="col text-end">
          {/* <Form.Text className="pe-3 d-none d-sm-inline">{currentUser && currentUser.email}</Form.Text> */}
          <Button type="button" className="auth-btn" onClick={handleSignOut}>Log out <FaSignOutAlt className="ms-2" /></Button>
        </div>
      </div>
      <div className="row justify-content-between g-4 g-lg-5 py-3">
        <div className="col-xxl-5">
          <div className="row align-items-end ">
            <div className="logo">
              <img src={MILAN_BLUE_LOGO} className="w-100" alt="" />
            </div>
            <h1 className="col h3 mb-0">Milan Clinic Site App</h1>
          </div>
        </div>
        {
          currentUser.role === 'admin' &&
          <div className="col-md-5 col-xxl-3">
            <CopyToClipboard text={milanStoresJson}>
              <button type="button" className="btn btn-submit shadow-sm float-xxl-end" onClick={() => handleCopyJson()}>
                {copyJson ? 'COPPIED' : 'Copy Milan Stores JSON'} <FaCopy className={`ms-2 mb-1 ${copyJson ? 'light-blue' : ''}`} />
              </button>
            </CopyToClipboard>
          </div>
        }
        <div className="col-md-7 col-xxl-4">
          <SearchBar />
        </div>
      </div>
    </header>
  );
}
export default Header;
