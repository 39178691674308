import React from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {TextError, FormikControl, StatesDropdown} from '../../utils';
import states from 'states-us';
import {newStoreSchema, newStoreInitialValues as initialValues} from '.';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Timestamp} from 'firebase/firestore';

export function AddNewStore({currentMetro, updateDB, medicalDirectors}) {
  const us_states = states.filter((state) => !state.territory);
  initialValues.state = currentMetro.state;
  return (
    <div className='d-flex justify-content-center justify-content-xxl-start store-form'>
      <Formik
        initialValues={initialValues}
        validationSchema={newStoreSchema(currentMetro)}
        onSubmit={(data, helpers) => updateDB({data: data, target: 'store', mode: 'add new', helpers: helpers})}
      >
        {(formik) => {
          return (
            <Form className='px-3 py-4 shadow-sm navy-border w-100 bg-white'>
              <h2 className='h4 pb-2'>
                New store will be added to <strong className='main-blue'>{currentMetro.name}</strong> metro
              </h2>
              {formik.status === 'success' ? (
                <div className='alert alert-success text-center m-3' role='alert'>
                  New Store Added Successfully!
                </div>
              ) : formik.status === 'failed' ? (
                <div className='alert alert-danger text-center m-3' role='alert'>
                  Failed to add new store.
                </div>
              ) : (
                <div className='row gy-2'>
                  <FormikControl
                    control='groupInput'
                    name='marketingCity'
                    label='Marketing City'
                    disabled={formik.status === 'confirm'}
                    placeholder='e.g. Englewood | Bucktown | Omaha West'
                    small='*This will be in the store url (trimmed &amp; lowercased)'
                  />
                  <FormikControl
                    control='groupInput'
                    name='clinicName'
                    label='Clinic Name'
                    disabled={formik.status === 'confirm'}
                    optional
                    placeholder='e.g. Denver South | Blank | Blank'
                  />
                  <FormikControl control='groupInput' name='address' disabled={formik.status === 'confirm'} placeholder='e.g. 3602 River Point Pkwy' />
                  <FormikControl
                    control='groupInput'
                    name='addressCity'
                    label='Address City'
                    disabled={formik.status === 'confirm'}
                    optional
                    placeholder='e.g. Sheridan | Chicago | Omaha'
                    small="*Keep blank if it's as same as the marketing city"
                  />
                  <StatesDropdown us_states={us_states} disabled={formik.status === 'confirm'} />
                  <FormikControl control='groupInput' name='zipCode' disabled={formik.status === 'confirm'} />
                  <FormikControl control='phoneInput' name='phone' disabled={formik.status === 'confirm'} />
                  <FormikControl control='groupInput' name='salesforceValue' label='Salesforce Value' disabled={formik.status === 'confirm'} />
                  <FormikControl control='groupInput' name='store_email' label='Clinic Email' disabled={formik.status === 'confirm'} />
                  <div>
                    <div className='input-group mb-2'>
                      <div className='input-group-prepend'>
                        <label htmlFor='md' className='input-group-text'>
                          Medical Director
                        </label>
                      </div>
                      <Field as='select' id='md' name='md' className='form-select'>
                        {medicalDirectors.map((md) => (
                          <option key={md.id} value={md.id}>
                            {md.displayName}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <ErrorMessage name='md' component={TextError} />
                  </div>
                  <FormikControl control='groupInput' name='place_id' label='Google Place ID' disabled={formik.status === 'confirm'} optional placeholder='Go to Google Place ID' />
                  <div className='input-group z-index-100'>
                    <div className='input-group-prepend'>
                      <label className='input-group-text' htmlFor='openDate'>
                        Open Date
                      </label>
                    </div>
                    <div className='form-control'>
                      <DatePicker
                        selected={formik.values.openDate && formik.values.openDate !== '' && formik.values.openDate.seconds ? formik.values.openDate.toDate() : ''}
                        dateFormat='MMM dd, yyyy'
                        className='border-0 w-100 main-blue '
                        name='openDate'
                        id='openDate'
                        placeholderText='Select The Actual Open Date'
                        onChange={(date) => formik.setFieldValue('openDate', date !== null ? Timestamp.fromDate(date) : '')}
                      />
                    </div>
                  </div>
                  <div>
                    <div className='input-group mb-2'>
                      <div className='input-group-prepend'>
                        <label htmlFor='open' className='input-group-text'>
                          Open <small>(Website)</small>
                        </label>
                      </div>
                      <Field as='select' id='open' name='open' className='form-select' disabled={formik.status === 'confirm'}>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Field>
                    </div>
                    <ErrorMessage name='open' component={TextError} />
                  </div>
                  <div>
                    <h4 className='h5'>Maps Links</h4>
                    <FormikControl control='groupInput' name='maps.google' label='Google' optional disabled={formik.status === 'confirm'} />
                    <FormikControl control='groupInput' name='maps.apple' label='Apple' optional disabled={formik.status === 'confirm'} />
                  </div>
                  <div>
                    <h4 className='h5'>Description</h4>
                    <FormikControl control='groupInput' name='description.field_one' label='Field One' optional disabled={formik.status === 'confirm'} />
                    <FormikControl control='groupInput' name='description.field_two' label='Field Two' optional disabled={formik.status === 'confirm'} />
                    <FormikControl control='groupInput' name='description.line_one' label='Short' optional disabled={formik.status === 'confirm'} />
                    <FormikControl
                      control='groupInput'
                      name='description.line_two'
                      label='Long'
                      optional
                      disabled={formik.status === 'confirm'}
                      placeholder="Don't repeat the short one"
                    />
                    <FormikControl control='groupInput' name='description.field_three' label='Field Three' optional disabled={formik.status === 'confirm'} />
                  </div>
                  <div>
                    <h4 className='h5'>Platforms</h4>
                    <FormikControl
                      control='groupInput'
                      name='platforms.reviews.list_token'
                      label='List Token'
                      optional
                      disabled={formik.status === 'confirm'}
                      placeholder='reviewsonmywebsite token'
                    />
                    <FormikControl
                      control='groupInput'
                      name='platforms.reviews.slide_token'
                      label='Slider Token'
                      optional
                      disabled={formik.status === 'confirm'}
                      placeholder='reviewsonmywebsite token'
                    />
                    <FormikControl
                      control='groupInput'
                      name='platforms.google'
                      label='Google Reviews'
                      optional
                      disabled={formik.status === 'confirm'}
                      placeholder='Google Reviews Link For The Reviews Page'
                    />
                    <FormikControl
                      control='groupInput'
                      name='platforms.facebook'
                      label='fb.com/MilanLaser'
                      optional
                      disabled={formik.status === 'confirm'}
                      placeholder='Complete the link'
                    />
                    <FormikControl
                      control='groupInput'
                      name='platforms.yelp'
                      label='yelp.com/biz/milan-laser-hair-removal-'
                      optional
                      disabled={formik.status === 'confirm'}
                      placeholder='Complete the link'
                    />
                  </div>
                </div>
              )}
              {/* ************ Form Actions Control ************** */}
              {formik.status === 'confirm' ? (
                <div className='border bg-light p-3'>
                  <p>
                    A new store will be added to <span className='fs-5 fw-bold main-blue'>{currentMetro.name}</span>
                  </p>
                  <div>
                    <input type='submit' value='Confirm' className='btn btn-submit shadow-sm' disabled={formik.status === 'success' || !formik.isValid} />
                    <button type='button' className='btn btn-cancel shadow-sm float-end' onClick={() => formik.resetForm()}>
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className='mt-3'>
                  {formik.status === 'success' || formik.status === 'failed' ? (
                    <button type='button' className='btn navy-bg-btn light-blue' onClick={() => formik.resetForm()} style={{maxWidth: '200px'}}>
                      Start Over
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='btn btn-submit shadow-sm'
                      onClick={() => formik.setStatus('confirm')}
                      disabled={formik.status === 'success' || !formik.isValid}
                    >
                      Add New Store
                    </button>
                  )}
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
export default AddNewStore;
