import React from 'react'

export function RecommendationCard() {
  return (
    <div className="card py-2 border-0">
      <div className="card-body py-4">
        <div className="card-title fw-bold milan-text-primary">
          RECOMMENDATION
        </div>
        <div className="card-text my-4 text-muted">
          When searching, use [city, state] or zip code for accuracy.
        </div>
        <div className="card-text text-muted">
          US is set by default
        </div>
      </div>
    </div>
  )
}

export default RecommendationCard