import React from 'react'
import RightArrow from '../shared/assets/RightArrow.png'

function BrandImages() {
  return (
  <section className=" py-3 py-sm-5 bg-grey">
    <div className="container py-sm-5">
      <div className="row">
        <div className=" col-sm-6 my-sm-auto mt-4 mt-sm-0">
          <h1 className="text-uppercase brandon-grotesque pop">Oooh the possibilities</h1>
          <h4 className="text-uppercase brandon-grotesque py-4">With Milan brand images</h4>
        </div>
        <div className="col-sm-5 my-auto">
        <p>So how do you keep those presentations dynamic and engaging using the Milan Brand? Easy peasy! Use the Milan Branded images whenever possible, which has a library of pre-selected images for you to use in your presentations to give it that extra touch of appeal!</p>
        <div className="text-white pt-2 mb-4 mb-sm-0"><a href="https://drive.google.com/drive/folders/1gzEOTo64RSz59Xb_JJjzTVyribKQz4ww?usp=sharing" target="_blank" rel="noreferrer" ><button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">Milan Brand Images <img src={RightArrow} alt='' className="ps-2" /></button></a></div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default BrandImages
