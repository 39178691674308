import React, { useState } from "react"
import { updateDoc } from "firebase/firestore"
import { Formik, Form } from "formik"
import { FormikControl } from "../../utils"
import { FaEdit, FaCopy } from "react-icons/fa"
import { globalInitialValues as initialValues, globalValidationSchema as validationSchema, NumberInput, updateGoogleRatings } from "."
import { CopyToClipboard } from 'react-copy-to-clipboard'

export function GlobalData({ milanStores, currentUser, updateActivities, globalData, docRef }) {

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const date = new Date()
  const today = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`

  // Google Ratings Update
  let milanReviewsCount = 0
  let milanAvgRating = 0
  let storesWithRatings = 0
  let storesWithPlaceID = 0
  milanStores.forEach(state => state.metros.forEach(metro => metro.stores.forEach(store => {
    if (store.place_id !== '') {
      storesWithPlaceID += 1
      if (store.rating) {
        storesWithRatings += 1
        milanAvgRating += store.rating
        milanReviewsCount += store.reviewCount
      }
    }
  })))

  const [isUpdating, setIsUpdating] = useState(false)
  const handleUpdateGoogleRating = () => {
    setIsUpdating(true)
    updateGoogleRatings(milanStores)
    setTimeout(() => {
      setIsUpdating(false)
    }, Math.ceil(storesWithPlaceID * 1.1) * 1000)
  }

  const milanDomains = JSON.stringify(milanStores.map(state => state.metros.map(metro => metro.origin)).flat(1))

  // All locations addresses Object - For Sinead
  // const allAddresses = {}
  // milanStores.forEach(state => state.metros.forEach(metro => metro.stores.forEach(store => {
  //   allAddresses[store.salesforceValue] = `${store.address} ${store.addressCity !== '' ? store.addressCity : store.marketingCity} ${store.stateAbbrev}, ${store.zipCode}` 
  // })))

  const [copyMilanDomains, setCopyMilanDomains] = useState(false)
  const handleCopyMilanDomains = () => {
    setCopyMilanDomains(true)
    setTimeout(() => {
      setCopyMilanDomains(false)
    }, 5000)
  }

  const onSubmit = async (data, helpers) => {
    if (currentUser.emailVerified && (currentUser.role === 'admin' || currentUser.role === 'copywriter')) {
      try {
        await updateDoc(docRef, {
          last_updated: data.last_updated,
          avg_rating: (milanAvgRating / storesWithRatings).toFixed(2),
          reviews_count: new Intl.NumberFormat().format(milanReviewsCount),
          tx_per_month: +data.tx_per_month,
          pulses_per_day: +data.pulses_per_day,
          waxing_cost_per_year: +data.waxing_cost_per_year,
          plans_start_at: data.plans_start_at,
          specials_banner: data.specials_banner,
          grand_specials_banner: data.grand_specials_banner
        })
        helpers.setStatus('success')
        updateActivities({ type: 'Global Data', details: `Global Data Updated`, reviewed: true })
      } catch (error) {
        console.error('Error updating global data', error)
        helpers.setStatus('failed')
      }
    } else return
  }

  return (
    <div id="global-data" className="container-fluid py-5 px-3 px-md-5">
      <div className="d-flex justify-content-center justify-content-lg-start">
        <Formik
          initialValues={globalData || initialValues}
          validationSchema={validationSchema}
          onSubmit={(data, helpers) => onSubmit(data, helpers)}
          enableReinitialize >
          {
            formik => {
              return (
                <Form className={`px-2 w-100`}>
                  <h3 className="mb-4 h1 d-inline-block h2">
                    Milan Global Data
                  </h3>
                  <div className="row gy-3 gx-5 mb-5">
                    <div className="col-12 col-xxl-6">
                      <FormikControl control="groupInput" name="specials_banner" label="Specials Banner" disabled={formik.status !== 'edit'} />
                    </div>
                    <div className="col-12 col-xxl-6">
                      <FormikControl control="groupInput" name="grand_specials_banner" label="Grand Specials Banner" disabled={formik.status !== 'edit'} />
                    </div>
                    <div className="col-lg-6">
                      <NumberInput name="tx_per_month" label="Treatments Count" formik={formik} />
                    </div>
                    <div className="col-lg-6">
                      <FormikControl control="groupInput" name="plans_start_at" label="Plans Start At" disabled={formik.status !== 'edit'} />
                    </div>
                    <div className="col-lg-6">
                      <NumberInput name="pulses_per_day" label="Pulses Per Day" formik={formik} />
                    </div>
                    <div className="col-lg-6">
                      <NumberInput name="waxing_cost_per_year" label="Waxing Cost Per Year" formik={formik} />
                    </div>
                  </div>
                  {
                    currentUser.role === 'admin' &&
                    <div className="row">
                      <h4 className="col-12 mb-4">Date of last update of the Clinic Site App</h4>
                      <div className="col-12 col-xl-5">
                        <FormikControl control="groupInput" name="last_updated" placeholder={today} label="Last updated on" optional disabled={formik.status !== 'edit'} />
                      </div>
                    </div>
                  }
                  {/* ************ Form Actions Control ************** */}
                  <div className="row my-4">
                    {
                      formik.status === 'edit' ?
                        <div className="col-6">
                          <input type="submit" value="Update" className={`btn px-5 me-3 shadow-sm btn-submit`} disabled={!formik.isValid} />
                          <button type="button" className="btn btn-cancel shadow-sm float-end px-3" onClick={() => formik.setStatus('cancel')}>Cancel</button>
                        </div>
                        :
                        <div className="col-6">
                          <button type="button" className="btn btn-edit shadow-sm px-4" onClick={() => formik.setStatus('edit')}> <FaEdit className="mb-1" /> Edit</button>
                        </div>
                    }
                  </div>

                  <div className="row pt-4 border-top mt-1 justify-content-between">
                    <div className="col-xl-5 mb-5">
                      <h4 className="mb-3">Milan Rating / ReviewCount</h4>
                      <p><small>- The average rating and reviewCount of all milan locations</small></p>
                      <FormikControl control="groupInput" name="reviews_count" label="ReviewCount" disabled={true} />
                      <FormikControl control="groupInput" name="avg_rating" label="Average Rating" disabled={true} />
                      <button type="button" className="btn btn-khaki shadow-sm px-4 mt-2" onClick={() => formik.submitForm()}>Update Milan Total Rating/ReviewCount</button>
                    </div>
                    <div className="col-xl-6">
                      <h4 className="mb-4">Google Stores Ratings</h4>
                      <p>This will update Google rating & reviewCount for each location.</p>
                      <p><small>- Please wait untill the process is finished before you navigate away or make any changes</small></p>
                      <p><small>- Estimated time to complete this function is: <strong className="text-success">{Math.ceil(storesWithPlaceID * 1.1)}</strong> seconds. (Use with caution)</small></p>
                      <button className="btn btn-khaki shadow-sm px-4 me-4" type="button" onClick={() => handleUpdateGoogleRating()} disabled={isUpdating}>{isUpdating ? 'Updating...' : 'Update Google Ratings'}</button>
                    </div>
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </div>
      <div className="row py-4 border-top mt-1">
        <div className="col">
          <CopyToClipboard text={milanDomains}>
            <button type="button" className="btn btn-submit shadow-sm" onClick={() => handleCopyMilanDomains()}>
              {copyMilanDomains ? 'COPPIED' : 'Copy Milan Domains'} <FaCopy className={`ms-2 mb-1 ${copyMilanDomains ? 'light-blue' : ''}`} />
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
}
export default GlobalData;