import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { FormikControl, TextError } from "../../../utils"
import { proImagesValidation as validationSchema, proInitialValues as initialValues } from '..'
import { ref, uploadBytes } from 'firebase/storage'
import Spinner from 'react-bootstrap/Spinner'
import { FaCamera } from 'react-icons/fa'

export function AddImages({ storage, storagePath, currentStore, isAdminOrDesigner, uploading, setUploading, setProImagesChanged, updateActivities, updateDB }) {
  const onSubmit = (values, helpers) => {
    if (!isAdminOrDesigner) return
    if (values.Hero && values.Hero !== '') {
      updateDB({ data: { hero: true, reversed: values.reversed, position: values.position }, target: 'store', mode: 'pro images' })
    }
    setUploading(true)
    setProImagesChanged(false)
    const promises = []
    for (const [key, file] of Object.entries(values)) {
      const imageRef = ref(storage, `${storagePath}${currentStore.marketingCity.trim().replace(/[^a-z0-9]/gi, '')}_${key}.webp`)
      if (typeof file === 'object') {
        const uploadFile = uploadBytes(imageRef, file)
        promises.push(uploadFile)
      }
    }
    if (promises.length > 0) {
      Promise.all(promises).then(res => {
        helpers.setStatus('success')
        setUploading(false)
        setProImagesChanged(true)
        const isHero = res.find(item => item.metadata.name.includes('Hero'))
        if (isHero) {
          updateActivities({ type: 'Pro Images Upload', details: `Added to ${currentStore.marketingCity} | ${currentStore.stateAbbrev}` })
        } else {
          updateActivities({ type: 'Clinic Images', details: `Added to ${currentStore.marketingCity} | ${currentStore.stateAbbrev}`, reviewed: true })
        }
        setTimeout(() => {
          helpers.resetForm()
        }, 1500)
      }).catch(err => {
        console.log('Error uploading images', err)
        helpers.setStatus('failed')
        setUploading(false)
      })
    } else {
      setUploading(false)
      return
    }
  }

  return (
    <>
      <hr />
      <h4 className="my-5">Upload Images to <strong className="main-blue">{currentStore.marketingCity}</strong> Location</h4>
      <Formik
        initialValues={{
          ...initialValues,
          reversed: '', // Store location: on the left -> reversed
          position: 'center' // Hero CSS background-position
        }}
        validationSchema={validationSchema}
        onSubmit={(values, helpers) => onSubmit(values, helpers)} >
        {
          formik => {
            return (
              <Form className="row mx-0 p-4 bg-white border justify-content-center">
                {
                  formik.status === 'success' ?
                    <div className="alert alert-success text-center col-lg-6" role="alert">
                      <p className="mb-0 py-4 fw-bold fs-5">Images Uploaded Successfully!</p>
                    </div>
                    :
                    formik.status === 'failed' ?
                      <div className="alert alert-danger text-center col-lg-6" role="alert">
                        Failed to upload images.
                      </div>
                      :
                      uploading ?
                        <div className="py-4 text-center main-blue">
                          <span className="me-4 fs-4">Uploading images...</span>
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden"></span>
                          </Spinner>
                        </div>
                        :
                        <>
                          {
                            Object.keys(initialValues).sort().map(key => (
                              <FormikControl key={key} control="file" name={key} formik={formik} />
                            ))
                          }
                          <div className="col-lg-6 mt-4">
                            <div className="row py-1 g-1">
                              <div className="col-4">
                                <label htmlFor="reversed" className="form-label col-form-label">Clinic Location</label>
                              </div>
                              <div className="col-8">
                                <Field as="select" name="reversed" id="reversed" className="form-select" disabled={formik.values.Hero === "" || !formik.values.Hero}>
                                  <option value="">Select</option>
                                  <option value={false}>On the right</option>
                                  <option value={true}>On the left</option>
                                </Field>
                                <ErrorMessage name="reversed" component={TextError} />
                                <small className="text-black-50"><i>Either on the right or on the left of the Hero image</i></small>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mt-4">
                            <div className="row py-1 g-1">
                              <div className="col-4">
                                <label htmlFor="position" className="form-label col-form-label">Hero BG Position</label>
                              </div>
                              <div className="col-8">
                                <Field as="select" name="position" id="position" className="form-select" disabled={formik.values.Hero === "" || !formik.values.Hero}>
                                  <option value="center">Center</option>
                                  <option value="left">Left</option>
                                  <option value="right">Right</option>
                                </Field>
                                <ErrorMessage name="position" component={TextError} />
                                <small className="text-black-50"><i>Hero image CSS background-position</i></small>
                              </div>
                            </div>
                          </div>
                          {/* ************ Form Actions Control ************** */}
                          <div className="mt-4">
                            <button type="submit" className="btn btn-submit" disabled={!formik.isValid || !formik.dirty}>
                              Upload Images <FaCamera className="ms-2 mb-1" />
                            </button>
                          </div>
                        </>
                }
                {
                  (formik.status === 'success' || formik.status === 'failed') &&
                  <div className="px-0">
                    <button type="button" className="btn btn-khaki shadow-sm" onClick={() => formik.resetForm()}>
                      {formik.status === 'success' ? 'Done' : 'Try again'}
                    </button>
                  </div>
                }
              </Form>
            )
          }
        }
      </Formik>
    </>
  )
}

export default AddImages