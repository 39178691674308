export const globalInitialValues = {
  last_updated: '',
  avg_rating: '4.96',
  reviews_count: 30,
  tx_per_month: 30,
  pulses_per_day: 50,
  waxing_cost_per_year: 23,
  plans_start_at: '29',
  specials_banner: '',
  grand_specials_banner: ''
}