import {collection, addDoc, serverTimestamp} from 'firebase/firestore';
import {db} from '../../firebase/firebase';
import {FIREBASE_COLLECTION} from '../../utils/constants';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const updateRecentActivities = async (activity, currentUser) => {
  if (FIREBASE_COLLECTION !== 'milan-stores') return;
  const date = new Date();
  const today = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

  activity.author = `${currentUser.firstName} ${currentUser.lastName}`;
  activity.createdAt = serverTimestamp();
  activity.date = today;
  activity.reviewed = activity.reviewed === true ? true : false;
  const dbRef = collection(db, 'recent-activities');
  try {
    // console.log('new activity', activity)
    await addDoc(dbRef, activity);
  } catch (error) {
    console.error('updateRecentActivities error', error);
  }
};
