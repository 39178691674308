import React, {useState, useEffect} from "react";
import './balancecalculator.css'

function BalanceCalculator() {
  const initialValues = {
    loanBalance: "",
    discount: "",
  };

  const [values, setValues] = useState(initialValues);
  const [savingsDisplay, setSavingsDisplay] = useState(0);
  const [paidInFullDisplay, setPaidInFullDisplay] = useState(0);

  const handleChange = (e) => {
    const {name, value} = e.target;
    console.log(name);
    console.log(value);
    if (name === "discount") {
      setValues({...values, [name]: value.replace(/\D+/g, "")});
    }
    if (value.length <= 9) {
      // setValues({ ...values, [name]: value.replace(/\D+/g, "") });
      //this makes it so it doesn't remove the period for decimal points
      setValues({...values, [name]: value.replace(/[^\d.]+/g, "")});
    }
  };

  const resetValues = () => {
    setValues(initialValues);
  };

  useEffect(() => {
    let savingsVal = 0;
    let paidInFullVal = 0;
    console.log("changed!");
    console.log(values);

    if (values.loanBalance !== "" && values.discount !== "") {
      savingsVal = values.loanBalance * values.discount;
      console.log("savingsVal", savingsVal);
      paidInFullVal = values.loanBalance - savingsVal;
      console.log("paidInFullVal", paidInFullVal);
    }

    setSavingsDisplay(savingsVal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));    
    setPaidInFullDisplay(paidInFullVal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  }, [values]);


  return (
    <div id="store-rank">
      <div className="mt-5 d-flex justify-content-center">
        <div className="row width-limit d-flex justify-content-center bg-light-turquoise border-turquoise pb-3">
          <div className="width-limit-percent">
            <h1 className="text-center my-4 font-dark-turquoise text-uppercase">
              <strong>Balance Calculator</strong>
            </h1>
            <div className="d-flex">
              <div className="w-100 mb-3">
                <p className="mb-1">
                  <strong>Remaining Balance</strong>
                </p>
                <p className="milan-fp">Clients Remaining Balance found in their SalesForce Opportunity.</p>
                <div className="dollar-sign">
                  {/* <input className="form-control d-block" type="text" name="loanBalance" value={values.loanBalance.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={handleChange} /> */}
                  {/* <input className="form-control d-block" type="text" name="loanBalance" value={values.loanBalance} onChange={handleChange} /> */}
                  <input className="form-control rounded-start rounded-end" type="text" name="loanBalance" value={values.loanBalance.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onChange={handleChange} />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <div className="w-100 mb-3">
                <p className="mb-1">
                  <strong>Discount</strong>
                </p>
                <p className="milan-fp">Found under the Client's SalesForce Account Campaign History.</p>
                <select className="form-select form-control rounded-start rounded-end" aria-label="Default select example" name="discount" onChange={handleChange} value={values.discount}>
                  <option defaultValue>Select</option>
                  <option value=".05">
                    5%
                  </option>
                  <option value=".1">10%</option>
                </select>
              </div>
            </div>

            <div className="d-flex">
              <div className="w-100 mb-3">
                <p className="mb-1">
                  Savings ={" "}
                  <span className="fw-bold" style={{color: "#00A5B4"}}>
                    ${savingsDisplay}
                  </span>
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div className="w-100 mb-3">
                <p className="mb-1">
                  Balance to be Paid in Full ={" "}
                  <span className="fw-bold" style={{color: "#F13A1A"}}>
                    ${paidInFullDisplay}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* reset button */}
      <div className="mt-4 d-flex justify-content-center">
        <div className="row width-limit d-flex justify-content-center">
          <button type="button" className="btn btn-primary milan-btn rounded-start rounded-end" onClick={resetValues} style={{maxWidth: "100px"}}>
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default BalanceCalculator;
