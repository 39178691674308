import React from 'react'
import Carousel from "react-bootstrap/Carousel"

export function MetroCarousel({ currentMetro }) {
  const openStores = currentMetro.stores.filter(store => store.open).length
  const comingsoon = currentMetro.stores.length - openStores
  return (
    <div className="row justify-content-center navy-bg py-4">
      <div className="col-lg-10 col-xl-8">
        <Carousel
          controls={false}
          indicators={false}
          interval="8000">
          <Carousel.Item>
            <p className="text-white fs-5 text-center mb-0">
              <span className="light-blue">{currentMetro.name}</span> has <strong className="light-blue">{currentMetro.stores.length}</strong> Milan location{currentMetro.stores.length === 1 ? null : 's'}
            </p>
          </Carousel.Item>
          <Carousel.Item>
            <p className="text-white fs-5 text-center mb-0">
              <span className="light-blue">{currentMetro.name}</span> is {currentMetro.grandOpening ? <span>a <span className="light-blue">Grand Opening</span></span> : <span><strong className="light-blue">NOT</strong> a Grand Opening</span>} metro
            </p>
          </Carousel.Item>
          <Carousel.Item>
            <p className="text-white fs-5 text-center mb-0">
              <span className="light-blue">{currentMetro.name}</span> has <strong className="light-blue">{openStores}</strong> open location{openStores === 1 ? null : 's'} and <strong className="light-blue">{comingsoon}</strong> location{comingsoon === 1 ? null : 's'} coming soon
            </p>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  )
}

export default MetroCarousel