import React, { useState, useEffect } from 'react'
import { ReplaceImageForm, ImagesList } from '..'
import { useStoreImages } from '../../../hooks'
import { FaCamera } from 'react-icons/fa'

export function DisplayImages({ currentStore, setUploading, uploading, storage, storagePath, isAdminOrDesigner, updateActivities, setProImagesChanged, updateDB }) {

  const storeImages = useStoreImages(storage, storagePath, uploading)
  const [selectedImage, setSelectedImage] = useState([])
  const [toBeReplaced, setToBeReplaced] = useState(false)

  const handleImageSelected = entry => {
    setSelectedImage(entry)
    setToBeReplaced(false)
  }

  useEffect(() => {
    // Don't show any image initially.
    return () => {
      setSelectedImage([])
    }
  }, [currentStore.salesforceValue])


  return (
    <>
      {Object.keys(storeImages).length > 0 && <h4 className="mb-5 h3"><strong className="main-blue">{currentStore.marketingCity}</strong> Images <FaCamera className="ms-2 mb-1 main-blue" /></h4>}
      <div className="row pb-3">
        <div className="col-lg-5 col-xl-4 mb-3 mb-lg-0">
          {
            Object.entries(storeImages).length > 0 ?
              <ImagesList {...{ storeImages, handleImageSelected, selectedImage, currentStore, setUploading, setSelectedImage, storagePath, isAdminOrDesigner, updateActivities, setProImagesChanged, updateDB }} />
              : // Store has no images to display
              <div className="alert alert-warning py-4 mb-0 text-center fs-6" role="alert">
                No images to display for <strong className="main-blue">{currentStore.marketingCity}</strong>
              </div>
          }
        </div>
        <div className="col-lg-7 col-xl-8">
          {
            Object.keys(storeImages).length > 0 &&
            <>
              {
                // Replace Image Button Clicked
                toBeReplaced && isAdminOrDesigner ?
                  <ReplaceImageForm {...{ selectedImage, setSelectedImage, storagePath, currentStore, setToBeReplaced, setUploading }} />
                  : // When image is selected to preview
                  (selectedImage.length === 2) ?
                    <div className="h-100 text-center position-relative" style={{ maxHeight: '370px', minHeight: '300px' }}>
                      <figure className="mb-0 h-100">
                        <img src={selectedImage[1]} className="m-auto mh-100 mw-100 border p-1 shadow-sm" alt="" />
                      </figure>
                      {
                        isAdminOrDesigner && <button type="button" className="btn btn-cancel shadow-sm px-4 position-absolute bottom-0 start-50 translate-middle" onClick={() => setToBeReplaced(true)} >Replace {selectedImage[0]} Image</button>
                      }
                    </div>
                    : // On load
                    <div className="h-100 d-flex justify-content-center align-items-center bg-light border">
                      <p className="text-center fs-5 p-5 text-muted">Select an image from the list to preview</p>
                    </div>
              }
            </>
          }
        </div>
      </div>
    </>
  )
}

export default DisplayImages