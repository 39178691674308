import React from 'react'

function FormVerbiage({formVerbiage}) {
  return (
    <>
      <h1 className="brandon-grotesque pop">MAKE IT POP</h1>
      <h2 className="h4 brandon-grotesque py-4">REQUEST CUSTOM GRAPHICS</h2>
      <p className="pt-3 pe-lg-5 me-lg-4">{formVerbiage}</p>
    </>
  )
}

export default FormVerbiage