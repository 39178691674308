import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import ListGroup from 'react-bootstrap/ListGroup'

export function StoresQuery({ milanStores, updateCurrentView, currentUser, scrollToDetails }) {

  const isAdmin = currentUser.role === 'admin'
  const [foundStores, setFoundStores] = useState([])
  // This queryType along with the useEffect keeps the list up to date if any change happens to any store
  const [queryType, setQueryType] = useState("open")
  const onChangeHandler = e => {
    setQueryType(e.target.value)
    const query = e.target.value
    setFoundStores(findStores(query))
  }

  useEffect(() => {
    // To keep the list updated when a store gets edited or a new store is added
    setFoundStores(findStores(queryType))
  }, [milanStores])

  const findStores = query => {
    let queryArr = query.split('.')
    let foundStoresArr = []
    milanStores.forEach(state => {
      if (state.metros.length !== 0) {
        state.metros.forEach(metro => {
          if (metro.stores.length !== 0) {
            metro.stores.forEach(store => {
              // Basically check if the prop is set to false or an empty string EXCEPT when the query is 'Open Clinics' then check if the open prop is set to true
              if (
                (queryArr.length === 1 && queryArr[0] === 'Open Clinics' && store.open === true) ||
                (queryArr.length === 1 && queryArr[0] !== 'Open Clinics' && (store[queryArr[0]] === "false" || store[queryArr[0] === ""] || !store[queryArr[0]])) ||
                (queryArr.length === 2 && (store[queryArr[0]][queryArr[1]] === "false" || store[queryArr[0]][queryArr[1]] === "" || !store[queryArr[0]][queryArr[1]])) ||
                (queryArr.length === 3 && (store[queryArr[0]][queryArr[1]][queryArr[2]] === "false" || store[queryArr[0]][queryArr[1]][queryArr[2]] === "" || !store[queryArr[0]][queryArr[1]][queryArr[2]]))
              ) {
                const foundStore = {
                  stateName: state.name,
                  metroPathname: metro.pathname,
                  marketingCity: store.marketingCity,
                  salesforceValue: store.salesforceValue,
                  stateAbbrev: store.stateAbbrev,
                  open: store.open
                }
                foundStoresArr.push(foundStore)
              }
            })
          }
        })
      }
    })
    return foundStoresArr.sort((a, b) => a.stateAbbrev.localeCompare(b.stateAbbrev))
  }

  const onStoreClicked = (store) => {
    updateCurrentView({
      stateName: store.stateName,
      metroPathname: store.metroPathname,
      salesforceValue: store.salesforceValue
    })
    scrollToDetails()
  }

  return (
    <div className="container-fluid py-5">
      <div className="row">
        <Form className="mb-4">
          <h4 className="pe-4 pb-3 pb-lg-0 h3 d-lg-inline">Query Locations By</h4>
          <Form.Select
            className="d-lg-inline w-auto" aria-label="stores query"
            defaultValue="" name="stores-query" onChange={onChangeHandler}
            style={{ minWidth: '300px' }} >
            <option value="open">Not Open</option>
            <option value="Open Clinics">Open Clinics</option>
            <option value="openDate">No Open Date</option>
            <option value="All Locations">All Locations</option>
            <option value="with_pro_images">No Pro Images</option>
            <option value="phone">No Phone</option>
            <option value="store_email">No Clinic Email</option>
            <option value="platforms.facebook">No Facebook Link</option>
            <option value="platforms.yelp">No Yelp Link</option>
            <option value="platforms.google">No Google Reviews Link</option>
            <option value="description.line_one">No Location Description</option>
            <option value="maps.google">No Google Maps</option>
            <option value="maps.apple">No Apple Maps</option>
            <option value="place_id">No Place ID</option>
            <option value="platforms.reviews.list_token" disabled={!isAdmin} >No Reviews List Token</option>
            <option value="platforms.reviews.slide_token" disabled={!isAdmin} >No Reviews Slider Token</option>
            <option value="geo.lat" disabled={!isAdmin} >No GeoCodes</option>
            <option value="cm_pardot_file" disabled={!isAdmin} >No Pardot File</option>
          </Form.Select>
        </Form>
        {
          foundStores.length > 0 && <h4 className="h5"><strong className="main-blue">{foundStores.length}</strong> location{foundStores.length === 1 ? '' : 's'} found</h4>
        }
        <div className="row g-4 mt-3 mt-lg-1 justify-content-center justify-content-lg-start">
          {
            foundStores.length !== 0 && foundStores.map((store, x) => (
              <div
                className="col-6 col-lg-4 col-xl-3"
                key={x} onClick={() => onStoreClicked(store)}>
                <ListGroup.Item className={`text-center btn-outline-dark border shadow-sm position-relative corner-flag ${!store.open ? 'corner-flag-red' : 'corner-flag-green' }`}>
                  {store.marketingCity} | {store.stateAbbrev}
                </ListGroup.Item>
              </div>
            ))
          }
          {
            foundStores.length === 0 &&
            <div className="col-6 col-lg-4 col-xl-3">
              <div className="alert alert-secondary text-center" role="alert">No locations found!</div>
            </div>
          }
        </div>
      </div>

    </div>
  );
}
export default StoresQuery;
