import { useState, useEffect } from 'react';
import { collection, onSnapshot } from "firebase/firestore"
import { db } from '../firebase/firebase'

export function useUsers() {
	const [users, setUsers] = useState([])
	const COLLECTION = 'users'

	useEffect(() => {
		const unsubscribe = onSnapshot(collection(db, COLLECTION), (snapshot) => {
			const fetchedData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
			setUsers(fetchedData.map((user, x) => ({ ...user, index: x })))
		}, error => {
			console.error(error)
		});
		// Stop listening to changes
		return unsubscribe
	}, [])
	return users
}
export default useUsers
