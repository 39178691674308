import React from "react";
import {saveAs} from "file-saver";
import MilanHorizontalLogo from "../shared/assets/Milan_Logo_Horizontal.webp";
import DownloadIcon from "../shared/assets/Deck-download.png";
import {CORPORATE_SITE, INTERNAL_ASSETS_PATH} from "../shared/constants";

const MilanLinkedInBestPractices = INTERNAL_ASSETS_PATH + "MilanLinkedInBestPractices.pdf";

function Hero() {
  return (
    <section>
      <div className="background hero bg-l-blue pt-3 pt-lg-5 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col offset-md-6 col-md-6">
              <div className="row pb-4">
                <div className="text-center text-sm-start">
                  <a href={CORPORATE_SITE} target="_blank" rel="noreferrer">
                    <img src={MilanHorizontalLogo} alt="Milan Laser Logo Horizontal" className="mw-100" />
                  </a>
                </div>
              </div>

              <div className="row pb-4">
                <h1 className="fw-light text-uppercase brandon-grotesque milan-text-primary" style={{maxWidth: "380px"}}>
                  Share The Love On LinkedIn
                </h1>
              </div>

              <div className="row">
                <p>
                  We want you to love your job and be proud to show off where you work! We encourage you to share all about it on LinkedIn, and now you can use Milan- branded
                  graphics.
                </p>
                <p>Simply download the graphic you want to use, add it to your LinkedIn profile, and make everyone wish they were a part of the Milan team.</p>
                <p className="pt-2">
                  <strong>New to LinkedIn? Become a pro with these simple tips!</strong>
                </p>
                <div className="bg-card-download-btn text-center text-md-start">
                  <button onClick={() => saveAs(MilanLinkedInBestPractices, "MilanLinkedInBestPractices.pdf")} className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                    Download <img src={DownloadIcon} alt="" className="ps-3" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* background image when < md */}
      <div className="d-md-none stack-sm-hero-bg" />
    </section>
  );
}

export default Hero;
