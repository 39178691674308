import * as Yup from 'yup'
import { trimAll } from '../../../utils'

const isMilanMetro = (pathname, milanStores) => {
  if (!pathname) return false
  // This will return the whole state if a metro with the same pathname is found, but that's fine. 
  let milanMetro = milanStores.find(state => state.metros.find(metro => metro.pathname === trimAll(pathname)))
  if (milanMetro) return true
}

export const newMetroSchema = milanStores => Yup.object({
  name: Yup.string().required('Required field!'),
  pathname: Yup.string().required('Required field!').test(pathname => {
    if (isMilanMetro(pathname, milanStores)) return new Yup.ValidationError('Metro with same pathname already exists!', undefined, 'pathname')
    return true
  }),
  gtag_id: Yup.string(),
  google_analytics_id: Yup.string(),
  bing_id: Yup.string(),
})
