import React from 'react'

export function Footer() {
  return (
    <footer className="text-center py-3">
      <p className="mb-0"><i><small>Copyright © {new Date().getFullYear()} Milan Laser All rights reserved.</small></i></p>
    </footer>
  );
}
export default Footer;
