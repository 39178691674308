import React from 'react'
import Carousel from "react-bootstrap/Carousel"
import { useMilanOpenStores } from '../hooks'

export function CarouselDivider({ milanStores, currentState }) {
  const { totalCount } = useMilanOpenStores(milanStores)
  const metrosCount = currentState.metros.length
  return (
    <div className="container-fluid pt-4 px-3 px-md-5">
      <div className="row justify-content-center navy-bg py-4">
        <div className="col-lg-10 col-xl-8">
          <Carousel
            controls={false}
            indicators={false}
            interval="8000">
            <Carousel.Item>
              <p className="text-white fs-5 text-center mb-0">
                Milan is currently open in <strong className="light-blue">{totalCount}</strong> locations in <strong className="light-blue">{milanStores.length} </strong> states
              </p>
            </Carousel.Item>
            <Carousel.Item>
              <p className="text-white fs-5 text-center mb-0">
                <span className="light-blue">{currentState.name}</span> has <strong className="light-blue">{metrosCount}</strong> Milan metro area{metrosCount === 1 ? null : 's'}
              </p>
            </Carousel.Item>
            <Carousel.Item>
              <p className="text-white fs-5 text-center mb-0">
                <strong className="light-blue">{currentState.name}</strong> has <strong className="light-blue">{currentState.openStores}</strong> open location{currentState.openStores === 1 ? null : 's'}
              </p>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default CarouselDivider