import React, { useContext } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { MetrosQuery, StoresQuery, Users, AddNew, RecentActivities, GlobalData, MedicalDirectors, UpcomingClinics } from '.'
import { StoresManagerContext } from '../home'
import { useUsers } from '../hooks'

export function ControlPanel({ recentActivities, globalData, docRef, medicalDirectors }) {
  const { milanStores, updateCurrentView, currentUser, scrollToDetails, updateActivities } = useContext(StoresManagerContext)
  const users = useUsers()
  return (
    <div id="control-panel" className="container-fluid py-5 px-3 px-md-5 control-panel border-bottom">
      <div className="row">
        <Tabs
          defaultActiveKey="upcoming"
          className=""
        >
          <Tab eventKey="upcoming" title="Upcoming Clinics">
            <UpcomingClinics {...{ milanStores, updateCurrentView, scrollToDetails }} />
          </Tab>
          <Tab eventKey="activities" title="Recent Activities">
            <RecentActivities {...{ recentActivities, currentUser, users }} />
          </Tab>
          <Tab eventKey="metroQuery" title="Metros Query">
            <MetrosQuery {...{ milanStores, updateCurrentView, scrollToDetails, currentUser }} />
          </Tab>
          <Tab eventKey="storesQuery" title="Locations Query">
            <StoresQuery {...{ milanStores, updateCurrentView, currentUser, scrollToDetails }} />
          </Tab>
          {
            medicalDirectors.length > 0 &&
            <Tab eventKey="medicalDirectors" title="Medical Directors">
              <MedicalDirectors {...{ medicalDirectors, currentUser }} />
            </Tab>
          }
          {
            currentUser.role === 'admin' &&
            <Tab eventKey="addNew" title="Add New">
              <AddNew medicalDirectors={medicalDirectors} />
            </Tab>
          }
          {
            (currentUser.role === 'admin' || currentUser.role === 'copywriter') &&
            <Tab eventKey="globalData" title="Global Data">
              <GlobalData {...{ milanStores, currentUser, updateActivities, globalData, docRef }} />
            </Tab>
          }
          {
            currentUser.role === 'admin' &&
            <Tab eventKey="users" title="Users">
              <Users admin={currentUser} users={users} />
            </Tab>
          }
        </Tabs>
      </div>
    </div>
  );
}

export default ControlPanel