import { useState, useEffect } from 'react';
import { ref, listAll, getDownloadURL } from 'firebase/storage'

export function useStoreImages(storage, storagePath, uploading) {
  const storeImagesRef = ref(storage, storagePath)
  const [storeImagesObj, setStoreImagesObj] = useState({}) // Object
  useEffect(() => {
    const getStoreImages = () => {
      // Get Store Images
      listAll(storeImagesRef).then(res => {
        // console.log(res.items)
        if (res.items.length > 0) {
          let imagesObj = {}
          res.items.forEach(item => {
            let storeProp = item.name.split("_")[1].split(".")[0] // returns HeroXL, FrontDesk, TXRoom ...etc
            getDownloadURL(item).then(url => {
              imagesObj[storeProp] = url
              setStoreImagesObj({
                ...imagesObj,
                [storeProp]: url
              })
            }).catch(err => {
              console.error('Error in getDownloadURL in storeImagesRef', err)
            })
          })
        }
        else {
          setStoreImagesObj({})
        }
      }).catch(err => {
        console.error('storeImagesRef error', err)
      })
    }
    if (storeImagesRef._location.path && !uploading) {
      getStoreImages()
    }
    // console.log('useEffect')
  }, [storeImagesRef._location.path, uploading])

  return Object.keys(storeImagesObj).sort().reduce(
    (sortedObj, key) => { 
      sortedObj[key] = storeImagesObj[key]
      return sortedObj
    }, 
    {}
  )
}
export default useStoreImages