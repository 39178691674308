import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from '.'

export function Select(props) {
  const { name, options, optionProp, ...rest } = props
  return (
    <div className="input-group" >
      <div className="input-group-prepend">
        <label htmlFor={name} className="input-group-text text-capitalize">{name}</label>
      </div>
      <Field as="select" id={name} name={name} {...rest} className="form-select">
        {
          options.map((item, x) => (
            <option key={x} value={item[optionProp]}>
              {item[optionProp]}
            </option>
          ))
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default Select