import L from 'leaflet'

export const initialPoint = [41.23133059426951, -96.19087170854449] // Milan Corporate Geo
export const ZOOM_LEVEL = 9

// Leaflet tiles config
export const tileConfig = {
  maptiler: {
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }
}

// Milan nearby stores markers | Milan M logo
export const markerIcon = new L.Icon({
  iconUrl: require('../../shared/assets/M-Pin.png'),
  iconAnchor: [0, 20],
  popupAnchor: [13, -13],
  iconSize: [28, 38]
})

// Search point marker | white/blue circle
export const markerStartPoint = new L.Icon({
  iconUrl: require('../../shared/assets/YouAreHere-2.png'),
  iconAnchor: [9, -11],
  iconSize: [16, 16],
  className: 'you-are-here'
})

// This helps with creating dynamic refs for each added nearby store
export const addToMarkersRefs = (el, markersRefs) => {
  if (el && !markersRefs.current.includes(el)) {
    markersRefs.current.push(el)
  }
}