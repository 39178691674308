import React from 'react'
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import MilanDeck from './components/milan-deck';
import LinkedIn from './components/linkedin';
import HomePage from './components/Home';
import NotFound from './components/NotFound';
import { PrivateRoute } from './components/clinic-site-app/home';
import ClinicSiteApp from './components/clinic-site-app';
import FindMilan from './components/find-milan';
import BalanceCalculator from './components/balanceCalculator/BalanceCalculator';
// import { SignUp, SignIn, ForgotPassword } from './components/clinic-site-app/user';
import { SignInWithGoogle } from './components/clinic-site-app/user';
// import { AuthProvider } from './components/clinic-site-app/context/AuthContext';
import { AuthProviderGoogle } from './components/clinic-site-app/context';
import { APP_NAME_TRIMMED } from './components/clinic-site-app/utils'
import './App.css';

function App() {
  return (
    <HashRouter>
      <AuthProviderGoogle>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/MilanDeck" element={<MilanDeck />} />
          <Route path="/LinkedIn" element={<LinkedIn />} />
          <Route path="/FindMilan" element={<FindMilan />} />
          <Route path="/balancecalculator" element={<BalanceCalculator />} />
          <Route path="/ClinicManager" element={ <Navigate to={`/${APP_NAME_TRIMMED}`} /> } />
          <Route path="/ClinicManager/signin" element={ <Navigate to={`/${APP_NAME_TRIMMED}`} /> } />
          <Route exact path={`/${APP_NAME_TRIMMED}`} element={<PrivateRoute />}>
            <Route exact path={`/${APP_NAME_TRIMMED}`} element={<ClinicSiteApp />} />
          </Route>
          <Route path={`/${APP_NAME_TRIMMED}/signin`} element={<SignInWithGoogle />} />
          <Route path={`/${APP_NAME_TRIMMED}/signup`} element={ <Navigate to={`/${APP_NAME_TRIMMED}`} /> } />
          <Route path={`/${APP_NAME_TRIMMED}/forgot-password`} element={ <Navigate to={`/${APP_NAME_TRIMMED}`} /> } />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProviderGoogle>
    </HashRouter>
  );
}

export default App;
