import React, { useState } from 'react'
import { usePagination } from '../../hooks'
import { FaUsers } from 'react-icons/fa'
import { EditUser, UserSearchBar } from '..'
import Pagination from "react-bootstrap/Pagination"

const initialValues = {
  id: '',
  role: '',
  displayName: '',
  firstName: '',
  lastName: ''
}
const LIST_COUNT_PER_PAGE = 12

export function Users({ admin, users }) {
  const [selectedUser, setSelectedUser] = useState(initialValues)
  const { collection, items, handlePaginationClick } = usePagination(users, LIST_COUNT_PER_PAGE)

  return (
    <div className="container-fluid py-5">
      <div className="row">
        <div className="col-lg-6">
          <h3 className="pb-4">Manage Users <FaUsers className="main-blue ms-3 mb-1" /></h3>
        </div>
        <div className="col-lg-6">
          <UserSearchBar {...{ setSelectedUser, users, LIST_COUNT_PER_PAGE, handlePaginationClick }} />
        </div>
      </div>
      {
        users.length > 0 &&
        <div className="row">
          <div className="col-lg-5">
            <ul className="list-group bg-white border">
              {
                collection.map(user => (
                  <li key={user.id}
                    className={`d-flex justify-content-between align-items-center list-group-item list-group-item-action overflow-hidden ${user.id === selectedUser.id ? 'bg-blue text-white' : ''}`}
                    onClick={() => setSelectedUser(user)}>
                    {user.email}
                    {
                      user.role !== 'reader' && <span className={`badge text-capitalize ${user.role === 'admin' ? 'navy-bg' : user.role === 'editor' ? 'bg-mild-blue' : user.role === 'copywriter' ? 'bg-mild-coral' : user.role === 'HR' ? 'bg-secondary' : user.role === 'coordinator' ? 'bg-info' : 'bg-khaki'}`}>{user.role}</span>
                    }
                  </li>
                ))
              }
            </ul>
            {
              collection.length > 0 &&
              <div className="mt-3">
                <Pagination className="mb-0" size='sm'>{items}</Pagination>
              </div>
            }
          </div>
          <div className="col-lg-7">
            {
              selectedUser.id !== '' &&
              <EditUser {...{ admin, selectedUser, users, setSelectedUser, handlePaginationClick }} />
            }
          </div>
        </div>
      }
    </div>
  )
}

export default Users