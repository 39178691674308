import React, { useState, useEffect, useContext } from 'react'
import { trimAll } from '../../../utils'
import { storage } from "../../../firebase/firebase"
import { useAwardsImages } from '../../../hooks'
import { AddAward, AwardsList, EditAward, AwardCard } from '..'
import { StoresManagerContext } from "../../../home"

export function Awards() {
  const { currentMetro, currentStore, currentUser } = useContext(StoresManagerContext)
  const storagePath = `stores/${trimAll(currentMetro.state)}/${currentMetro.pathname}/${currentStore.pathname}/awards/`
  const isAdminOrDesigner = currentUser.role === 'admin' || currentUser.role === 'designer'
  // uploading state is crucial to trigger useAwardsImages hook to refetch awards
  const [uploading, setUploading] = useState(false)
  const awardsImages = useAwardsImages(storage, storagePath, uploading)
  const [selectedAward, setSelectedAward] = useState({})

  useEffect(() => {
    // By default, no award is selected initially.
    return () => {
      setSelectedAward({})
    }
  }, [currentStore.salesforceValue])

  return (
    <div className="container-fluid py-5">
      <h4 className="mb-4 h3"><strong className="main-blue">{currentStore.marketingCity}</strong> Awards </h4>
      <div className="row gx-4 mb-4">
        <div className="col-lg-7 col-xl-5">
          {
            awardsImages.length > 0 ?
              <AwardsList {...{ awardsImages, selectedAward, setSelectedAward }} />
              :
              <div className="alert alert-warning py-4 mb-0 text-center fs-6" role="alert">
                <strong className="main-blue">{currentStore.marketingCity}</strong> location has no awards
              </div>
          }
        </div>
        <div className="col-xl-7">
          {
            selectedAward.url &&
            <div className="row h-100">
              <div className="col-12 col-md-auto">
                <AwardCard selectedAward={selectedAward} />
              </div>
              {
                isAdminOrDesigner &&
                <div className="col">
                  <EditAward {...{ selectedAward, setSelectedAward, setUploading, uploading, storagePath, isAdminOrDesigner }} />
                </div>
              }
            </div>
          }
        </div>
      </div>
      {
        isAdminOrDesigner &&
        <div className="row g-4">
          <div className="col-lg-7 col-xl-5">
            <AddAward {...{ storagePath, setUploading, uploading, isAdminOrDesigner }} />
          </div>
        </div>
      }
    </div>
  )
}

export default Awards