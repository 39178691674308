import React, { useContext } from "react"
import { AddNewState, AddNewMetro, AddNewStore } from ".";
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import { StoresManagerContext } from "../../home"

export function AddNew({medicalDirectors}) {
  const { milanStores, currentState, currentMetro, updateCurrentView, updateDB, updateActivities } = useContext(StoresManagerContext)

  return (
    <div id="add-new" className="container-fluid py-5 px-3 px-md-5">
      <Tab.Container id="add-new-tabs" defaultActiveKey="metro">
        <Row>
          <Col lg={5} xl={4}>
            <Nav variant="pills" className="flex-column align-items-center align-items-lg-start">
              <Nav.Item className="btn navy-bg-btn rounded-0 mb-3 p-0">
                <Nav.Link className="text-white navy-bg-btn rounded-0" eventKey="state">Add new state</Nav.Link>
              </Nav.Item>
              <Nav.Item className="btn navy-bg-btn rounded-0 mb-3 p-0">
                <Nav.Link className="text-white navy-bg-btn rounded-0" eventKey="metro">Add new metro to <strong className="light-blue text-nowrap">{currentState.name}</strong></Nav.Link>
              </Nav.Item>
              {
                currentMetro &&
                <Nav.Item className="btn navy-bg-btn rounded-0 mb-0 p-0">
                  <Nav.Link className="text-white navy-bg-btn rounded-0" eventKey="store">Add new store to <strong className="light-blue text-nowrap">{currentMetro.name}</strong></Nav.Link>
                </Nav.Item>
              }
            </Nav>
          </Col>
          <Col lg={7} xl={8} className="mt-4 mt-lg-0">
            <Tab.Content>
              <Tab.Pane eventKey="state">
                <AddNewState {...{ milanStores, updateCurrentView, updateActivities }} />
              </Tab.Pane>
              <Tab.Pane eventKey="metro">
                <AddNewMetro {...{ milanStores, updateDB, currentState }} />
              </Tab.Pane>
              {
                currentMetro &&
                <Tab.Pane eventKey="store">
                  <AddNewStore {...{ currentMetro, updateDB, medicalDirectors }} />
                </Tab.Pane>
              }
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
export default AddNew;
