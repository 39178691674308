import {useState, useEffect} from 'react';
import {collection, onSnapshot} from 'firebase/firestore';
import {db} from '../firebase/firebase';
import {FIREBASE_COLLECTION} from '../utils';

export function useFetchMilanStores() {
  const [milanStores, setMilanStores] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, FIREBASE_COLLECTION),
      (snapshot) => {
        const fetchedData = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}));
        setMilanStores(fetchedData);
      },
      (error) => {
        console.error(error);
      }
    );
    // Stop listening to changes
    return unsubscribe;
  }, []);
  return milanStores;
}
export default useFetchMilanStores;

//  ------ To Add/Update each store's description to have field_1, field_2, field_3 -------- //
// import {doc, updateDoc} from 'firebase/firestore';
// const addNewPropToStores = async (milanStores) => {
// 	milanStores.forEach((state) => {
// 	  state.metros.forEach((metro) => {
// 		 metro.stores.forEach((store) => {
// 			store.description.field_1 = '';
// 			store.description.field_2 = '';
// 			store.description.field_3 = '';
// 		 });
// 	  });
// 	});
// 	milanStores.forEach((state) => {
// 	  console.log('state', state);
// 	  const stateRef = doc(db, FIREBASE_COLLECTION, state.name);
// 	  updateDoc(stateRef, {metros: state.metros});
// 	});
//  };
//inside useEffect, under setMilanStorews(fetchedData);, add this line: addNewPropToStores(fetchedData);

//  ------ To Add/Update a New Prop to each store in the DB -------- //
// import { doc, updateDoc } from "firebase/firestore"
// const addNewPropToStores = async (milanStores, prop, value) => {
// 	milanStores.forEach(state => {
// 		state.metros.forEach(metro => {
// 			metro.stores.forEach(store => {
// 				store[prop] = value
// 			})
// 		})
// 	})
// 	milanStores.forEach(state => {
// 		const stateRef = doc(db, FIREBASE_COLLECTION, state.name)
// 		updateDoc(stateRef, { metros: state.metros })
// 	})
// }

//  ------ To Add/Update a New Prop to each metro in the DB -------- //
// import { doc, updateDoc } from "firebase/firestore"
// const addNewPropToMetros = async (milanStores, prop, value) => {
// 	milanStores.forEach(state => {
// 		state.metros.forEach(metro => {
// 			metro[prop] = value
// 		})
// 	})
// 	milanStores.forEach(state => {
// 		const stateRef = doc(db, FIREBASE_COLLECTION, state.name)
// 		updateDoc(stateRef, { metros: state.metros })
// 	})
// }
// addNewPropToMetros(fetchedData, 'google_verification', '')

// To doplicate the entire data base:
// milanStores.forEach(state => {
// 	const stateRef = doc(db, 'milan-test', state.name)
// 	setDoc(stateRef, { ...state })
// })

// const temp = fetchedData.map(state => state.metros.map(metro => metro.stores.map(store => {
// 	return {
// 		salesforceValue: store.salesforceValue,
// 		place_id: store.place_id
// 	}
// })))
// console.log('temp', temp.flat(2))
