import React from 'react'
import { FaAward } from 'react-icons/fa'

export function AwardsList({ awardsImages, selectedAward, setSelectedAward }) {
  return (
    <ul className="list-group bg-white mb-4 mb-xl-0">
      {
        awardsImages.map((award, x) => (
          <li key={x}
            className={`list-group-item list-group-item-action w-100 position-relative ${award.name === selectedAward.name ? 'bg-blue text-white' : ''}`}
            onClick={() => setSelectedAward(award)}>
            <span className={`${award.name === selectedAward.name ? 'light-blue' : 'main-blue'}`}>{award.name}</span>
            <span className={`float-end light-blue`}><FaAward className="ms-2 mb-1" /></span>
          </li>
        ))
      }
    </ul>
  )
}

export default AwardsList