import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form"
import { passwordStrength } from 'check-password-strength'
import { useAuth } from "../hooks"
import { Link } from 'react-router-dom'
import { doc, setDoc } from "firebase/firestore"
import { db } from "../firebase/firebase"
import { sendEmailVerification } from 'firebase/auth'
import FormWrapper from "./FormWrapper"
import { APP_NAME_TRIMMED } from "../utils"
import { FaUserPlus } from 'react-icons/fa'

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  // url: 'http://localhost:4200/',
  url: `https://internal.milanlaser.com/#/${APP_NAME_TRIMMED}/`,
  // This must be true.
  handleCodeInApp: true
};

// ------------- Deprecated -------------------- //
export function SignUp() {
  const { register, handleSubmit, watch, formState: { errors, dirtyFields, isValid }, setError, clearErrors } = useForm({ mode: 'onBlur' });
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [signupFailed, setSignupFailed] = useState({ failed: false, message: '' })
  const { signup, signout } = useAuth()

  const handleSignUp = async data => {
    if (!data.email.includes('@milanlaser.com')) return
    try {
      setLoading(true)
      const userCredentials = await signup(data)
      sendEmailVerification(userCredentials.user, actionCodeSettings)
      await setDoc(doc(db, 'users', userCredentials.user.uid), {
        email: userCredentials.user.email,
        firstName: data.firstName,
        lastName: data.lastName,
        role: 'reader'
      })
      setSuccess(true)
      // Firebase by default signs user in after signing up 
      // Prevent that
      signout()
    } catch (error) {
      if (error.message.includes('email-already-in-use')) {
        setSignupFailed({ failed: true, message: 'Account with same email already exists!' })
      } else {
        setSignupFailed({ failed: true, message: 'Signup Failed!' })
      }
      console.error(error)
    }
    setLoading(false)
  }
  const checkPasswordStrength = e => {
    const pwStrength = passwordStrength(e.target.value).value
    if (e.target.value.length >= 8) {
      if (pwStrength === 'Medium' || pwStrength === 'Strong') {
        clearErrors("password")
      } else {
        setError("password", { type: "strength" })
      }
    }
  }
  const comparePasswords = e => {
    clearErrors("confirmPassword")
    setTimeout(() => {
      if (e.target.value !== watch("password")) {
        setError("confirmPassword", { type: "match" })
      }
    }, 1500)
  }
  return (
    <FormWrapper>
      {
        success ?
          <div className="alert alert-success py-4 text-center" role="alert">
            <p className="fs-2">SUCCESS</p>
            <p className="mb-0">Check your inbox to verify your email!</p>
          </div>
          :
          <Form onSubmit={handleSubmit(handleSignUp)}>
            <div className="d-flex">
              <h4 className="fw-bold my-3">Sign up</h4>
              {loading && <div className="ms-4 pt-3"><Spinner animation="grow" /></div>}
            </div>
            {signupFailed.failed && <div className="alert alert-danger py-2 mb-2 text-center" role="alert">{signupFailed.message}</div>}
            <Form.Group className="form-outline mb-4" controlId="email">
              <Form.Control
                className="form-control-lg" type="email" placeholder="Email" {...register("email", {
                  required: "required",
                  pattern: {
                    value: /\S+@milanlaser.com/,
                    message: "Email does not match email format"
                  }
                })} />
              {errors.email?.type === "required" && <small className="text-danger">This field is required</small>}
              {errors.email?.type === "pattern" && <small className="text-danger">{errors.email.message}</small>}
            </Form.Group>

            <Form.Group className="form-outline mb-4" controlId="firstName">
              <Form.Control
                className="form-control-lg" type="firstName" placeholder="First Name"
                {...register("firstName", { required: true, minLength: '2' })} />
              {errors.firstName?.type === "required" && <small className="text-danger">This field is required</small>}
              {errors.firstName?.type === "minLength" && <small className="text-danger">Must be at least 2 chars</small>}
            </Form.Group>

            <Form.Group className="form-outline mb-4" controlId="lastName">
              <Form.Control
                className="form-control-lg" type="lastName" placeholder="Last Name"
                {...register("lastName", { required: true })} />
              {errors.lastName?.type === "required" && <small className="text-danger">This field is required</small>}
            </Form.Group>

            <Form.Group className="form-outline mb-4" controlId="password">
              <Form.Control
                className="form-control-lg" type="password" placeholder="Password"
                {...register("password", { required: true, minLength: '8' })}
                onChange={checkPasswordStrength} />
              {errors.password?.type === "required" && <small className="text-danger">This field is required</small>}
              {errors.password?.type === "minLength" && <small className="text-danger">Must be at least 8 chars</small>}
              {errors.password?.type === "strength" && <small className="text-danger">Password not strong enough!</small>}
            </Form.Group>

            <Form.Group className="form-outline mb-4" controlId="confirmPassword">
              <Form.Control
                className="form-control-lg" type="password" placeholder="Confirm password"
                {...register("confirmPassword", { required: true, minLength: '8' })}
                onChange={comparePasswords} />
              {errors.confirmPassword?.type === "required" && <small className="text-danger">This field is required</small>}
              {errors.confirmPassword?.type === "minLength" && <small className="text-danger">Must be at least 8 chars</small>}
              {errors.confirmPassword?.type === "match" && <small className="text-danger">Passwords don't match!</small>}
            </Form.Group>
            <div className="pt-1 mb-4">
              <Button type="submit" className="navy-bg-btn btn-lg" disabled={!isValid && !(dirtyFields.email && dirtyFields.password && dirtyFields.confirmPassword)}>
                Sign up <FaUserPlus className="ms-2" />
              </Button>
            </div>

          </Form>
      }
      <p className="mb-0">Already have an account? <Link to={`/${APP_NAME_TRIMMED}/signin`} className="main-blue" >Sign in</Link></p>
    </FormWrapper>
  );
}
export default SignUp;