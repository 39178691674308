import * as Yup from 'yup'

export const metroValidationSchema = Yup.object({
  name: Yup.string().required('Required field!'),
  pathname: Yup.string().required('Required field!'),
  metroPath: Yup.string().required('Required field!'),
  gtag_id: Yup.string(),
  google_analytics_id: Yup.string(),
  bing_id: Yup.string(),
  google_verification: Yup.string(),
  grandOpening: Yup.boolean()
})