import React from 'react'
import { TextError } from '.'

export function FileInput(props) {
  const { name, label, formik, ...rest } = props
  const onChange = (e) => {
    formik.setFieldTouched(name, true)
    formik.setFieldValue(name, e.target.files[0])
  }
  return (
    <div className="col-lg-6">
      <div className="row py-1 g-1">
        <div className="col-4">
          <label htmlFor={name} className={`form-label col-form-label text-capitalize ${(formik.values[name] && formik.values[name] !== '' && !formik.errors[name]) ? 'fw-bolder text-khaki' : ''}`}>{label || name}</label>
        </div>
        <div className="col-8">
          <input type="file" name={name} id={name} {...rest} className="form-control" onChange={onChange} disabled={name === 'HeroXL' && (!formik.values.Hero || formik.values.Hero === '')} />
          {formik.errors[name] && formik.touched[name] && <TextError>{formik.errors[name]}</TextError>}
        </div>
      </div>
    </div>
  )
}

export default FileInput