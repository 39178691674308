import React from 'react'
import { updateDoc, doc } from "firebase/firestore"
import { db } from "../../firebase/firebase"
import { FaTimes } from 'react-icons/fa'
import ListGroup from "react-bootstrap/ListGroup"

export function AssignMDForm({ director, storesToBeAssigned, setStoresToBeAssigned, isAdminOrCMO, medicalDirectors, setSuccess }) {
  const FIREBASE_COLLECTION = 'medical-directors'
  const onSubmit = async () => {
    if (isAdminOrCMO) {
      storesToBeAssigned.forEach(store => {
        const previousDirector = medicalDirectors.find(md => md.displayName === store.director)
        previousDirector.locations = previousDirector.locations.filter(location => !(location.name === store.name && location.metro === store.metro))
        const docRef = doc(db, FIREBASE_COLLECTION, previousDirector.id)
        try {
          updateDoc(docRef, { locations: previousDirector.locations })
        } catch (error) {
          console.log(error)
        }
        const toBePushed = {
          name: store.name,
          metro: store.metro,
          stateAbbrev: store.stateAbbrev
        }
        director.locations.push(toBePushed)
      })
      director.locations.sort((a, b) => a.name.localeCompare(b.name))
      const docRef = doc(db, FIREBASE_COLLECTION, director.id)
      try {
        await updateDoc(docRef, { locations: director.locations })
        setSuccess(true)
        setStoresToBeAssigned([])
        setTimeout(() => {
          setSuccess(false)
        }, 2000)
      } catch (error) {
        console.error('Error Assigning Locations', error)
      }
    } else return
  }
  
  return (
    <div className="card p-4">
      {
        director.displayName !== 'Not Assigned' ?
          <h4 className="h5 mb-4">Assign locations to <strong className="main-blue">{director.displayName}</strong></h4>
          :
          <h4 className="h5 mb-4 text-coral">Un Assign Locations</h4>
      }
      <ListGroup>
        {
          storesToBeAssigned.map((store, x) => (
            <ListGroup.Item key={x} style={{ cursor: 'default' }}>
              <span>{store.name} | {store.stateAbbrev}</span>
              <div className="d-inline float-end">
                {store.director === 'Not Assigned' ? <small className="text-coral">Not Assigned</small> : <small>Assigned to <strong className="main-blue">{store.director}</strong></small>}
                <span className="ms-4 float-end pointer" onClick={() => setStoresToBeAssigned(storesToBeAssigned.filter(item => !(item.name === store.name && item.stateAbbrev === store.stateAbbrev)))}><FaTimes /></span>
              </div>
            </ListGroup.Item>
          ))
        }
      </ListGroup>
      {
        director.displayName !== 'Not Assigned' ?
          <div className="alert alert-warning mt-4" role="alert">
            The above locations will be assigned to <strong className="main-blue">{director.displayName}</strong>
          </div>
          :
          <div className="alert alert-danger mt-4" role="alert">
            The above locations will be <strong>UNASSIGNED</strong>
          </div>
      }
      <div>
        <button type="button" className="btn btn-submit shadow-sm" onClick={onSubmit}>Confirm</button>
        <button type="button" className="btn btn-cancel shadow-sm float-end" onClick={() => setStoresToBeAssigned([])}>Cancel All</button>
      </div>
    </div>
  )
}

export default AssignMDForm