import React from 'react'
import { TextError } from "../../../utils"
import { storage } from "../../../firebase/firebase"
import { Formik, Form } from 'formik'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import * as Yup from 'yup'
import { FaCamera } from 'react-icons/fa'
import { validateImage } from '.'

export const ReplaceImageForm = ({ selectedImage, setSelectedImage, storagePath, currentStore, setToBeReplaced, setUploading }) => {
  const imageLabel = selectedImage[0]
  const initialValues = {}
  initialValues[imageLabel] = ''
  const imageRef = ref(storage, `${storagePath}${currentStore.marketingCity.trim().replace(/[^a-z0-9]/gi, '')}_${selectedImage[0]}.webp`)
  const maxSize = imageLabel.includes('Hero') ? 600000 : 300000
  const replaceImgValidation = Yup.object({
    [imageLabel]: Yup.mixed().required('Required field').test(input => validateImage(input, imageLabel, maxSize, true)),
  })
  const onSubmitImageReplace = (value, helpers) => {
    if (typeof (value[imageLabel]) === 'object') {
      setUploading(true)
      uploadBytes(imageRef, value[imageLabel]).then((res) => {
        helpers.setStatus('success')
        setUploading(false)
        getDownloadURL(imageRef).then(url => {
          setSelectedImage([selectedImage[0], url])
        }).catch(err => {
          console.error('Store Image getDownloadURL Failed', err)
        })
        setTimeout(() => {
          helpers.resetForm()
        }, 1200)
      }).catch(err => {
        console.error('Failed replacing store image', err)
        helpers.setStatus('failed')
        setUploading(false)
      })
    } else return
  }
  const resetForm = formik => {
    if(formik.status === 'success') {
      setToBeReplaced(false)
    }
    formik.resetForm()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={replaceImgValidation}
      onSubmit={(value, helpers) => onSubmitImageReplace(value, helpers)} >
      {
        formik => {
          return (
            <Form className="row mx-0 p-4 rounded bg-white border justify-content-center">
              <h4 className="lh-base h5 mb-4 px-0">Replacing the <strong className="main-blue">{selectedImage[0]}</strong> image of the <strong className="main-blue">{currentStore.marketingCity}</strong> location?</h4>
              {
                formik.status === 'success' ?
                  <div className="alert alert-success text-center py-4" role="alert">
                    <p className="fs-5"><strong>Image Replaced Successfully!</strong></p>
                  </div>
                  :
                  formik.status === 'failed' ?
                    <div className="alert alert-danger text-center col-lg-6" role="alert">
                      Failed to replace image.
                    </div>
                    :
                    <>
                      <div className="row py-1 g-1">
                        <div className="col-4">
                          <label htmlFor={imageLabel} className="form-label col-form-label text-capitalize main-blue fw-bold">{selectedImage[0]}</label>
                        </div>
                        <div className="col-8">
                          <input type="file" name={imageLabel} id={imageLabel} className="form-control" onChange={(e) => formik.setFieldValue(imageLabel, e.target.files[0])} />
                          {formik.errors[imageLabel] && <TextError>{formik.errors[imageLabel]}</TextError>}
                        </div>
                      </div>
                      {/* ************ Form Actions Control ************** */}
                      <div className="mt-4 px-0">
                        <button type="submit" className="btn btn-submit shadow-sm" disabled={!formik.isValid}>
                          Replace Image <FaCamera className="ms-2 mb-1" />
                        </button>
                      </div>
                    </>
              }
              {
                (formik.status === 'success' || formik.status === 'failed') && 
                <div className="px-0">
                  <button type="button" className="btn btn-khaki shadow-sm" onClick={() => resetForm(formik)}>
                    { formik.status === 'success' ? 'Done' : 'Try again' }
                  </button>
                </div>
              }
            </Form>
          )
        }
      }
    </Formik>
  )
}

export default ReplaceImageForm