import * as Yup from 'yup'

export const validateImage = (input, name, size, isRequired) => {
  // For not required fields: Validate only when an image is uploaded, else return true
  // typeof input === 'undefined' when no image is uploaded
  if (typeof input === 'undefined' && !isRequired) return true
  // typeof (input) === 'object' when an image is uploaded
  if (typeof input === 'object') {
    if (input.type !== 'image/webp') {
      return new Yup.ValidationError('Only webp format is accepted', undefined, name)
    } else if (input.type === 'image/webp') {
      if (!input.name.toLowerCase().includes(name.toLowerCase()) && name !== 'award') {
        return new Yup.ValidationError("Image name doesn't match input name", undefined, name)
      } else if (input.size > size) {
        return new Yup.ValidationError(`Image size must not exceed ${Math.trunc(size / 1000)}KB`, undefined, name)
      } else return true
    }
  } else return false
}

export const proImagesValidation = Yup.object({
  Hero: Yup.mixed().test(input => validateImage(input, 'Hero', 600000, false)),
  HeroXL: Yup.mixed().test(input => validateImage(input, 'HeroXL', 600000, false)),
  Thumbnail: Yup.mixed().test(input => validateImage(input, 'Thumbnail', 200000, false)),
  FrontDesk: Yup.mixed().test(input => validateImage(input, 'FrontDesk', 200000, false)),
  ConsultationRoom: Yup.mixed().test(input => validateImage(input, 'ConsultationRoom', 200000, false)),
  Lobby: Yup.mixed().test(input => validateImage(input, 'Lobby', 200000, false)),
  TXRoom: Yup.mixed().test(input => validateImage(input, 'TXRoom', 200000, false)),
  Portrait: Yup.mixed().test(input => validateImage(input, 'Portrait', 250000, false)),
  FrontDoor: Yup.mixed().test(input => validateImage(input, 'FrontDoor', 200000, false)),
  ShoppingCenterSignage: Yup.mixed().test(input => validateImage(input, 'ShoppingCenterSignage', 250000, false)),
  reversed: Yup.boolean().when('Hero', {
    is: true,
    then: Yup.boolean().required('Required field')
  }),
  position: Yup.string()
}, [['reversed', 'Hero']])

// ------- Use this when uploading Pro images only ---------- //
// export const proImagesValidation = Yup.object({
//   Hero: Yup.mixed().required('Required field!').test(input => validateImage(input, 'Hero', 500000, true)),
//   HeroXL: Yup.mixed().test(input => validateImage(input, 'HeroXL', 500000, false)),
//   Thumbnail: Yup.mixed().required('Required field!').test(input => validateImage(input, 'Thumbnail', 200000, true)),
//   FrontDesk: Yup.mixed().required('Required field!').test(input => validateImage(input, 'FrontDesk', 200000, true)),
//   ConsultationRoom: Yup.mixed().required('Required field!').test(input => validateImage(input, 'ConsultationRoom', 200000, true)),
//   Lobby: Yup.mixed().required('Required field!').test(input => validateImage(input, 'Lobby', 200000, true)),
//   TXRoom: Yup.mixed().required('Required field!').test(input => validateImage(input, 'TXRoom', 200000, true)),
//   Portrait: Yup.mixed().required('Required field!').test(input => validateImage(input, 'Portrait', 250000, true)),
//   FrontDoor: Yup.mixed().when('ShoppingCenterSignage', {
//     is: ShoppingCenterSignage => !ShoppingCenterSignage,
//     then: Yup.mixed().required('Rquired when ShoppingCenterSignage is empty!')
//   }).test(input => validateImage(input, 'FrontDoor', 200000, false)),
//   ShoppingCenterSignage: Yup.mixed().test(input => validateImage(input, 'ShoppingCenterSignage', 250000, false)),
//   reversed: Yup.boolean().required('Required field!'),
//   position: Yup.string().required('Required field!')
// }, [['FrontDoor', 'ShoppingCenterSignage']])


// Checking for image dimensions //  Unfinished work
    // let optimizedHeight = 0
    // let optimizedWidth = 0
    // switch(name) {
    //   case 'Hero':
    //     optimizedHeight = 850
    //     optimizedWidth = 1920
    //     break
    //   case 'HeroXL':
    //     optimizedHeight = 605
    //     optimizedWidth = 1920
    //     break
    //   case 'Portrait':
    //     optimizedHeight = 600
    //     optimizedWidth = 430
    //     break
    //   case 'Thumbnail':
    //     optimizedHeight = 260
    //     optimizedWidth = 340
    //     break
    //   case 'Thumbnail':
    //     optimizedHeight = 260
    //     optimizedWidth = 340
    //     break
    //   default:
    //     optimizedHeight = 355
    //     optimizedWidth = 471
    // }
    // //Initiate the FileReader object.
    // var reader = new FileReader();
    // //Read the contents of Image File.
    // reader.readAsDataURL(input);
    // async function OnLoad() {

    // }
    // reader.onload = function (e) {
    //   //Initiate the JavaScript Image object.
    //   var image = new Image();

    //   //Set the Base64 string return from FileReader as source.
    //   image.src = e.target.result;

    //   //Validate the File Height and Width.
    //   image.onload = function () {
    //     var height = this.height;
    //     var width = this.width;
    //     if (height > 100 || width > 100) {
    //       return false
    //     }
    //     return true
    //   };
    // }

