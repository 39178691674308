import React, { useState, useRef, useEffect } from 'react'
import { FaSearch } from 'react-icons/fa'
import Form from "react-bootstrap/Form"
import ListGroup from "react-bootstrap/ListGroup"
import { trimAll } from '../../utils'
import { useClickOutside } from '../../hooks'

// Don't import hardTrim - it doesn't lowercase the string
const hardTrim = str => trimAll(str).replace(/[^a-z0-9]/gi, '');

export function MDSearchBar({ medicalDirectors, director, onDirectorChange, isAdminOrCMO, storesToBeAssigned, setStoresToBeAssigned }) {
  const [foundStores, setFoundStores] = useState([])
  const [inputValue, setInputValue] = useState("")
  const domNode = useClickOutside(() => setFoundStores([]))

  const onChangeHandler = e => {
    setFoundStores([])
    setInputValue(e.target.value)
    if (e.target.value === "") {
      setFoundStores([])
      return
    }
    const value = hardTrim(e.target.value)
    let foundStoresArr = []
    medicalDirectors.forEach(director => {
      if (director.locations.length !== 0) {
        director.locations.forEach(location => {
          if (hardTrim(location.name).includes(value) || hardTrim(location.metro).includes(value)) {
            const foundStore = {
              director: director.displayName,
              name: location.name,
              stateAbbrev: location.stateAbbrev,
              metro: location.metro
            }
            foundStoresArr.push(foundStore)
          }
        })
      }
    })
    setFoundStores(foundStoresArr)
  }

  const onStoreClicked = (clickedStore) => {
    if(isAdminOrCMO) {
      if (clickedStore.director === director.displayName) return
      if (storesToBeAssigned.some(store => (store.name === clickedStore.name) && (store.metro === clickedStore.metro))) return
      setStoresToBeAssigned(prev => [...prev, clickedStore])
    } else {
      onDirectorChange(clickedStore.director)
    }
    setFoundStores([])
    setInputValue("")
  }

  // Keyboard navigation
  const listRef = useRef(null)
  const searchBarRef = useRef(null)
  const [count, setCount] = useState(0)
  const handleKeyDown = e => {
    if (e.key === 'ArrowDown' && listRef.current && count < foundStores.length) {
      setCount(prevCount => prevCount + 1)
    } else if (e.key === 'ArrowUp' && listRef.current && count > 1) {
      setCount(prevCount => prevCount - 1)
    } else if (e.key === 'Enter' && listRef.current && count > 0) {
      onStoreClicked(foundStores[count - 1])
      setCount(0)
      setFoundStores([])
    } else {
      setCount(0)
    }
  }
  useEffect(() => {
    if (listRef.current && listRef.current.children && count === 0) {
      searchBarRef.current.focus()
    }
    if (listRef.current && listRef.current.children && count > 0) {
      listRef.current.children[count - 1].focus()
    }
  }, [count])

  return (
    <div>
      <div ref={domNode} className="search-bar-wrapper">
        <div className="search-input input-group">
          <span className="input-group-text" style={{ minWidth: '60px' }}><FaSearch /></span>
          <Form.Control className="py-2" ref={searchBarRef} type="search" placeholder="Search locations..." onChange={onChangeHandler} value={inputValue} onKeyDown={handleKeyDown} />
        </div>
        <div className="search-results position-relative">
          {
            foundStores.length !== 0 &&
            <ListGroup ref={listRef} className="align-items-center position-absolute w-100 bg-white">
              {
                foundStores.map((store, x) => (
                  <ListGroup.Item
                    key={x} tabIndex={-1}
                    className={`pointer list-group-item-action ${isAdminOrCMO && (store.director === director.displayName || storesToBeAssigned.some(toBeAssigned => (toBeAssigned.name === store.name) && (toBeAssigned.metro === store.metro) && (toBeAssigned.stateAbbrev === store.stateAbbrev))) ? 'disabled-bg' : ''}`}
                    onClick={() => onStoreClicked(store)} onKeyDown={handleKeyDown}>
                    {store.metro} ({store.name} | {store.stateAbbrev}) {store.director === 'Not Assigned' ? <small className="text-coral float-end">Not Assigned</small> : <span className="float-end">Assigned to <strong className="text-khaki">{store.director}</strong></span>}
                  </ListGroup.Item>
                ))
              }
            </ListGroup>
          }
        </div>
      </div>
    </div>
  );
}
export default MDSearchBar