import React, { useState, useEffect } from 'react'
import { useFetchMilanStores } from '../clinic-site-app/hooks'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { getMonth, getYear } from 'date-fns';
import { range } from "lodash";
import { FaCopy, FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const years = range(2012, getYear(new Date()) + 1, 1)
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

function ClinicCountCalculator() {
  const MILAN_STORES = useFetchMilanStores()
  const [openDates, setOpenDates] = useState([])
  // Set the startDate to Today's date initially
  const [startDate, setStartDate] = useState({ stateAbbrev: null, openDate: new Date() })
  // The Post to be coppied
  const [post, setPost] = useState({
    part_one: 'When I started at Milan Laser we had',
    liveClinics: 0,
    part_two: 'clinics! Today we have more than',
    liveToday: 0,
    part_three: 'clinics in',
    liveStates: 0,
    part_four: "states, and we're growing every day! #MilanMagic"
  })

  // When Milan Stores are fetched from the db
  useEffect(() => {
    if (MILAN_STORES.length > 0) {
      let openDatesArr = []
      // Only push the ones with an actuall openDate // not an empty string
      // Also exclude the ones that will open in the future
      MILAN_STORES.forEach(state => state.metros.forEach(metro => metro.stores.forEach(store => {
        if (store.openDate !== '' && store.openDate.toDate() < startDate.openDate) {
          openDatesArr.push({ stateAbbrev: store.stateAbbrev, openDate: new Date(store.openDate.toDate()) })
        }
      })))
      const states = [...new Set(openDatesArr.map(a => a.stateAbbrev))] // returns unique elements
      setOpenDates(openDatesArr)
      setPost({ ...post, liveClinics: openDatesArr.length, liveToday: openDatesArr.length, liveStates: states.length })
    }
  }, [MILAN_STORES])

  // When start date is changed
  useEffect(() => {
    // Exclude the previously selected start date
    const filteredOpenDates = openDates.filter(elem => elem.stateAbbrev)
    filteredOpenDates.push(startDate) // Push the new selected date
    filteredOpenDates.sort((a, b) => a.openDate - b.openDate)
    const startDateIndex = filteredOpenDates.findIndex(item => item.openDate === startDate.openDate) // Find the selected date index
    setPost({ ...post, liveClinics: startDateIndex })
  }, [startDate])

  // For the Copy Post Button
  const [copyPost, setCopyPost] = useState(false)
  const handleCopyPost = () => {
    setCopyPost(true)
    setTimeout(() => { setCopyPost(false) }, 5000)
  }

  return (
    <section id="live-clinics-calculator" className="pt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-5 text-white">
            <h2 className="h1 brandon-grotesque pop fw-light">SHARE YOUR <br /> MILESTONE!</h2>
            <h3 className="h4 fw-light brandon-grotesque py-4 text-uppercase">Clinic Count Calculator</h3>
            <p className="pe-lg-5 me-lg-4">Time flies when you're having fun! Use this calculator to look back at how many clinics have opened since you joined the Milan Team! Simply enter your start date to see how much Milan Laser has grown since you started!</p>
          </div>
          <div className="col-md-6 col-lg-5">
            <div className="mb-5">
              <label htmlFor="live-clinics" className="text-white mb-2">Select Your Employee Start Date</label>
              <DatePicker
                id="live-clinics" name="live-clinics"
                className="border-0 rounded main-blue form-control"
                selected={startDate.openDate}
                onChange={(date) => setStartDate({ stateAbbrev: null, openDate: date })}
                maxDate={new Date()}
                renderCustomHeader={({ date, changeYear, changeMonth, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                  <div className="d-flex justify-content-center m-2">
                    <button className="border-0 pe-2 main-blue" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}><FaChevronLeft /></button>
                    <select className="me-2 border-0" value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                      {years.map((option) => <option key={option} value={option}>{option}</option>)}
                    </select>
                    <select className="border-0" value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                      {months.map((option) => <option key={option} value={option}>{option}</option>)}
                    </select>
                    <button className="border-0 ps-2 main-blue" onClick={increaseMonth} disabled={nextMonthButtonDisabled}><FaChevronRight /></button>
                  </div>
                )}
              />
            </div>
            <div>
              <p className="text-white">We encourage you to share the #MilanMagic with your network! Feel free to copy and paste the below caption and share!</p>
              <p className="text-muted bg-white py-4 px-3 rounded">
                {post.part_one} <span className="main-blue">{post.liveClinics}</span> {post.part_two} <span className="main-blue">{post.liveToday}</span> {post.part_three} <span className="main-blue">{post.liveStates}</span> {post.part_four}
              </p>
              <CopyToClipboard text={`${post.part_one} ${post.liveClinics} ${post.part_two} ${post.liveToday} ${post.part_three} ${post.liveStates} ${post.part_four}`}>
                <button type="button" className="cta-btn red-bg-btn py-1 px-3 shadow-sm" onClick={() => handleCopyPost()}>
                  {copyPost ? 'Coppied' : 'Copy Post'} <FaCopy className="ms-2 mb-1" />
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid divider py-5"></div>
    </section>
  )
}

export default ClinicCountCalculator