import React from "react"
import { Field, ErrorMessage } from "formik"
import { TextError } from "../../utils"
import { FaAsterisk } from "react-icons/fa"

export const NumberInput = ({ name, label, formik }) => {
  return (
    <>
      <div className="input-group mb-2" >
        <div className="input-group-prepend">
          <label htmlFor={name} className="input-group-text">{label} <sup><FaAsterisk /></sup></label>
        </div>
        <div className="" style={{ position: 'relative', flex: '1 1 auto', width: '1%', minWidth: '0' }}>
          <div className="d-flex">
            <span style={{ flexGrow: '1' }}><Field name={name} id={name} className="form-control" disabled={formik.status !== 'edit'} /></span>
            <span className="bg-light text-dark border px-3 pt-1">,000</span>
          </div>
        </div>
      </div>
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}