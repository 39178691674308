import React, { useState, useContext } from 'react'
import { storage } from "../../../firebase/firebase"
import { trimAll } from '../../../utils'
import { AddImages, DisplayImages } from '..'
import { StoresManagerContext } from "../../../home"

export function ProImages({ setProImagesChanged, activeKey }) {
  const { currentMetro, currentStore, currentUser, updateActivities, updateDB } = useContext(StoresManagerContext)

  const storagePath = `stores/${trimAll(currentMetro.state)}/${currentMetro.pathname}/${currentStore.pathname}/store/`
  const isAdminOrDesigner = currentUser.role === 'admin' || currentUser.role === 'designer'
  const [uploading, setUploading] = useState(false)

  return (
    <div className="container-fluid py-5">
      { /* This will ensure DisplayImages component rerenders on tab click */
        activeKey === 'proImages' && 
        <DisplayImages {...{ currentStore, setUploading, uploading, storage, storagePath, isAdminOrDesigner, updateActivities, setProImagesChanged, updateDB }} />
      }
      {
        isAdminOrDesigner &&
        <AddImages {...{ storage, storagePath, currentStore, isAdminOrDesigner, uploading, setUploading, setProImagesChanged, updateActivities, updateDB }} />
      }
    </div>
  )
}

export default ProImages