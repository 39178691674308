import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from '.'
import { FaAsterisk } from 'react-icons/fa'

export function TextArea(props) {
  const { label, name, required, ...rest } = props
  return (
    <>
      {label && <label htmlFor={name}>{label} {required && <sup><FaAsterisk /></sup>} </label>}
      <Field name={name}>
        {
          props => {
            const { field, meta } = props
            return (
              <textarea
                className="form-control" id={name}
                {...field} {...rest}
                style={meta.touched && meta.error ? { borderColor: 'red' } : null} />
            )
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </>
  )
}

export default TextArea