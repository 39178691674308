import React, { useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  GEOCODE_GOOGLE_MAPS_URL,
  DISTANCE_MATRIX_GOOGLE_MAPS_URL,
  MILAN_SITE_START,
} from '../shared/constants';
import axios from 'axios';
import { NearbyStoreCard } from './NearbyStoreCard';
import { RecommendationCard } from './RecommendationCard';
import { usa_states } from './usa-states';
import {
  validationSchema,
  sortCallback,
  findMilanInDB,
  tileConfig,
  markerIcon,
  markerStartPoint,
  addToMarkersRefs,
  initialPoint,
  ZOOM_LEVEL,
  sortMilanGeoCodes,
  scrollToNearbyStoresContainer,
} from './utils/';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { ChangeView } from './ChangeView';
import { FaSearch } from 'react-icons/fa';
import Spinner from 'react-bootstrap/Spinner';
import { Element } from 'react-scroll';
import FindMilanHeader from './FindMilanHeader';

export function FindMilanWrapper({ milanStores, milanGeoCodes }) {
  const [initialResults, setInitialResults] = useState([]);
  const [nearbyMilan, setNearbyMilan] = useState([]);
  const [startPoint, setStartPoint] = useState({});
  const [sortType, setSortType] = useState('distance');

  //  Map
  const [center, setCenter] = useState(initialPoint); // Map center point view
  const mapRef = useRef(null);
  const markersRefs = useRef([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  // Reset all related useStates
  const resetAll = () => {
    setNearbyMilan([]);
    setInitialResults([]);
    setStartPoint({});
    setCenter(initialPoint);
    setSelectedCardIndex(0);
  };

  // Get distance and duration for each nearby location
  const getDistanceMatrix = (nearbyStores, startPoint, type) => {
    // Get distance of each nearby store
    let destinations = nearbyStores
      .map((nearbyStore) => `${nearbyStore.geo.lat},${nearbyStore.geo.long}`)
      .join('|');
    axios
      .get(
        `${DISTANCE_MATRIX_GOOGLE_MAPS_URL}?origins=${startPoint.lat},${startPoint.long}&destinations=${destinations}&units=imperial&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      )
      .then((res) => {
        nearbyStores.forEach((nearbyStore, x) => {
          nearbyStore.matrix = res.data.rows[0].elements[x];
        });
        if (type === 'submit') {
          // When Submit button is clicked
          nearbyStores.sort((a, b) => sortCallback(a, b, sortType));
          setNearbyMilan(nearbyStores);
        } else {
          // When Load More button is clicked
          let combindedAndSorted = [...nearbyMilan, ...nearbyStores].sort(
            (a, b) => sortCallback(a, b, sortType)
          );
          setNearbyMilan(combindedAndSorted);
        }
      })
      .catch((error) => {
        console.log('Distance Matrix Google API Error', error);
        resetAll();
      });
  };

  // Load more nearby locations
  const loadMore = (nearbyMilan) => {
    if (nearbyMilan.length > 0 && nearbyMilan.length < 7)
      getDistanceMatrix(initialResults.slice(6, 12), startPoint, 'load');
    if (nearbyMilan.length > 6 && nearbyMilan.length < 13)
      getDistanceMatrix(initialResults.slice(12, 18), startPoint, 'load');
  };

  // Sort Type: by Distance OR by Travel Time
  const onSortTypeChange = (val) => {
    setSortType(val);
    setNearbyMilan([...nearbyMilan].sort((a, b) => sortCallback(a, b, val)));
  };

  // If the search fails for any reason
  const onSearchFail = (helpers) => {
    helpers.setStatus('Failed');
    helpers.setSubmitting(false);
    resetAll();
  };

  // On Submit Search
  const onSubmit = (values, helpers) => {
    helpers.setStatus('');
    if (values.search === '') return;
    setSelectedCardIndex(0);
    let inputValue = values.search;
    if (usa_states.indexOf(values.search.trim().toLowerCase()) !== -1)
      inputValue += ' State';
    helpers.setFieldValue('formatted_address', '');
    helpers.setSubmitting(true);
    let includesUSA = (str) => {
      return (
        str.trim().slice(-3).toUpperCase() === 'USA' ||
        str.trim().slice(-13).toLowerCase() === 'united states'
      );
    };
    axios
      .get(
        `${GEOCODE_GOOGLE_MAPS_URL}?address=${inputValue}${
          includesUSA(inputValue) ? '' : ' , USA'
        }&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      )
      .then((response) => {
        if (
          response.data.results.length > 0 &&
          includesUSA(response.data.results[0].formatted_address)
        ) {
          const lat = response.data.results[0].geometry.location.lat;
          const long = response.data.results[0].geometry.location.lng;
          setStartPoint({ lat: lat, long: long });
          setCenter([lat, long]);
          helpers.setFieldValue(
            'formatted_address',
            response.data.results[0].formatted_address
          );
          sortMilanGeoCodes({ lat, long }, milanGeoCodes)
            .then((res) => {
              let nearbyStores = res
                .slice(0, 18)
                .map((item) => findMilanInDB(milanStores, item));
              setInitialResults(nearbyStores);
              getDistanceMatrix(
                nearbyStores.slice(0, 6),
                { lat, long },
                'submit'
              );
              helpers.setSubmitting(false);
              mapRef.current.closePopup(); // Close previous Popups if any
              setTimeout(() => {
                helpers.setFieldValue('search', '');
              }, 1000);
            })
            .catch((err) => {
              console.log('Error sorting milanGeoCodes:', err);
              onSearchFail(helpers);
            });
        } else {
          console.log('No Results');
          onSearchFail(helpers);
        }
      })
      .catch((err) => {
        console.error('API Error: Fetching lat, lng failed:', err);
        onSearchFail(helpers);
      });
  };

  // When Show on map button is clicked
  const showOnMap = (store, x) => {
    let targetedRef = markersRefs.current.find(
      (ref) => ref._latlng.lat === +store.geo.lat
    );
    if (targetedRef) {
      setSelectedCardIndex(x);
      setCenter([targetedRef._latlng.lat, targetedRef._latlng.lng]);
      targetedRef.openPopup();
    }
  };

  return (
    <div className='row gy-4 gx-3 mt-1'>
      <div className='col-md-5 col-xl-4 col-xxl-3'>
        <FindMilanHeader />
        <Formik
          initialValues={{ search: '', formatted_address: '' }}
          validationSchema={validationSchema}
          onSubmit={(values, helpers) => onSubmit(values, helpers)}
        >
          {(formik) => {
            return (
              <Form className='row gy-2 pt-1'>
                <div className='col-12'>
                  <div className='input-group'>
                    <Field
                      name='search'
                      id='search'
                      className='fm-search rounded-start border'
                      placeholder='Search by address, zip code, city, or state'
                    />
                    <button
                      className='input-group-text d-inline rounded-end w-auto'
                      style={{ minWidth: '50px' }}
                      type='submit'
                    >
                      {formik.isSubmitting ? (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                        />
                      ) : (
                        <FaSearch />
                      )}
                    </button>
                  </div>
                </div>
                {formik.status === 'Failed' && (
                  <div className='col-12 text-center mt-4'>
                    <div className='alert alert-warning mb-0' role='alert'>
                      No Accurate Results :(
                    </div>
                  </div>
                )}
                <div className='col-12 text-center'>
                  {formik.values.formatted_address && nearbyMilan.length > 0 ? (
                    <div className=''>
                      <p className='milan-fs-7 fw-light text-muted mb-2 border p-2 milan-bg-white rounded'>
                        Showing results around:{' '}
                        <span className='milan-text-primary'>
                          {formik.values.formatted_address}
                        </span>
                      </p>
                      <div className='text-start'>
                        <strong className='milan-fs-7 text-muted'>
                          Sort by
                        </strong>
                        <span className='float-end'>
                          <button
                            type='button'
                            onClick={() => onSortTypeChange('distance')}
                            className={`sort-type rounded-start border milan-fs-7 ${
                              sortType === 'distance' ? 'active' : ''
                            }`}
                          >
                            Distance
                          </button>
                          <button
                            type='button'
                            onClick={() => onSortTypeChange('duration')}
                            className={`sort-type rounded-end border milan-fs-7 ${
                              sortType === 'duration' ? 'active' : ''
                            }`}
                          >
                            Travel Time
                          </button>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <RecommendationCard />
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>

        {nearbyMilan.length > 0 && <hr className='mb-0' />}
        <div className='row justify-content-center'>
          <div className='col-12 pt-3'>
            <Element
              className='row justify-content-center gy-2 position-relative overflow-scroll'
              id='nearby-stores-container'
              style={{ maxHeight: '598px' }}
            >
              {nearbyMilan.length > 0 &&
                nearbyMilan.map((store, x) => (
                  <Element
                    key={x}
                    className={`col-12`}
                    name={`nearby-store-${x}`}
                  >
                    <NearbyStoreCard
                      store={store}
                      showOnMap={() => showOnMap(store, x)}
                      highlighted={selectedCardIndex === x}
                    />
                  </Element>
                ))}
              <div className='col-12 mt-3'>
                {nearbyMilan.length > 0 && nearbyMilan.length < 13 && (
                  <span
                    className='milan-fs-7 py-1 px-3 rounded fm-btn'
                    type='button'
                    onClick={() => loadMore(nearbyMilan)}
                  >
                    Load more
                  </span>
                )}
              </div>
            </Element>
          </div>
        </div>
      </div>

      <div className='col-md-7 col-xl-8 col-xxl-9'>
        <MapContainer
          center={center}
          zoom={ZOOM_LEVEL}
          scrollWheelZoom={false}
          ref={mapRef}
        >
          {startPoint.lat && <ChangeView center={center} zoom={ZOOM_LEVEL} />}
          <TileLayer
            url={tileConfig.maptiler.url}
            attribution={tileConfig.maptiler.attribution}
          />
          <Marker
            position={
              startPoint.lat ? [startPoint.lat, startPoint.long] : initialPoint
            }
            icon={markerStartPoint}
          />
          {nearbyMilan.length > 0 &&
            nearbyMilan.map((store, x) => (
              <Marker
                key={x}
                position={[store.geo.lat, store.geo.long]}
                icon={markerIcon}
                ref={(el) => addToMarkersRefs(el, markersRefs)}
                eventHandlers={{
                  click: () => {
                    setSelectedCardIndex(x);
                    setCenter([store.geo.lat, store.geo.long]);
                    scrollToNearbyStoresContainer(x);
                  },
                }}
              >
                <Popup className='rounded'>
                  <a
                    href={
                      store.metro.singleStore
                        ? `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.pathname}`
                        : `${MILAN_SITE_START}${store.metro.pathname}.com/locations/${store.metro.metroPath}/${store.pathname}/`
                    }
                    className='fw-bold milan-text-primary'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {store.marketingCity}
                  </a>
                </Popup>
              </Marker>
            ))}
        </MapContainer>
      </div>
    </div>
  );
}

export default FindMilanWrapper;
