import React, { useState } from 'react'
import { TextError } from "../../../utils"
import { storage } from "../../../firebase/firebase"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { FaExclamationTriangle } from 'react-icons/fa'
import * as Yup from 'yup'
import { ref, uploadBytes, updateMetadata, getDownloadURL, deleteObject } from 'firebase/storage'

export function EditStaff({ staffImages, selectedEmployee, setSelectedEmployee, setUploading, uploading, storagePath, updateActivities, currentStore, currentUser }) {
  const [formMode, setFormMode] = useState(null)
  const isAdminOrDesigner = currentUser.role === 'admin' || currentUser.role === 'designer'
  const validationSchema = Yup.object({
    image: Yup.mixed().test(input => {
      if (typeof (input) === 'object') {
        let employeeArr = input.name.split(".")[0].split("-")
        if (employeeArr.length < 2 || !input.name.includes('-') || input.name.includes('_')) {
          return new Yup.ValidationError('Invalid image name. Follow pattern FirstName-LastName.webp', undefined, 'image')
        } else if (input.name.includes(" ")) {
          return new Yup.ValidationError('Invalid image name. No space allowed', undefined, 'image')
        } else if (input.type !== 'image/webp') {
          return new Yup.ValidationError('Only webp format is accepted', undefined, 'image')
        } else if (input.size > 100000) {
          return new Yup.ValidationError('Image size must not exceed 100KB', undefined, 'image')
        } else return true
      } else return true
    }).nullable(),
    position: Yup.string().required('Required field!'),
    displayName: Yup.string().required('Required field!').test(input => {
      if (input && input !== '') {
        if (input[input.length - 1] !== '.') {
          return new Yup.ValidationError('Missing "." at the end', undefined, 'displayName')
        }
        if (input.length < 4) {
          return new Yup.ValidationError('Display Name is too short', undefined, 'displayName')
        }
        return true
      } else return true
    }),
    showOnWebsite: Yup.string()
  })

  const initialValues = {
    displayName: selectedEmployee.displayName,
    position: selectedEmployee.position,
    image: '',
    showOnWebsite: selectedEmployee.showOnWebsite !== undefined ? selectedEmployee.showOnWebsite : 'true' // a string because FB Storage doesn't recognize boolean values
  }

  const onSubmitEditEmployee = (data, helpers) => {
    if (currentUser.role !== 'admin' && currentUser.role !== 'designer' && currentUser.role !== 'HR') return
    const imageRef = ref(storage, `${storagePath}${selectedEmployee.imgName}`)
    // On Deleting Employee
    if (formMode === 'delete') {
      deleteObject(imageRef).then(() => {
        setSelectedEmployee({})
        setUploading(!uploading)
        if (data.position === 'Clinic Manager') {
          updateActivities({ type: 'CM Photo Deleted', details: `Deleted in ${currentStore.marketingCity} | ${currentStore.stateAbbrev}` })
        } else {
          updateActivities({ type: 'Staff Photo Deleted', details: `${data.position} deleted in ${currentStore.marketingCity} | ${currentStore.stateAbbrev}`, reviewed: true })
        }
      }).catch((error) => {
        console.log('Error deleting employee', error)
      })
    } else { // On Editing Employee
      // To update Employee metadata in Firebase Storage
      const metadata = {
        customMetadata: {
          position: data.position,
          displayName: data.displayName,
          index: (data.position === 'Clinic Manager') ? 1 : (data.position === 'Assistant Clinic Manager') ? 2 : (data.position === 'Physician Assistant') ? 3 : 100,
          showOnWebsite: data.showOnWebsite
        }
      }
      // To update the Employee Card and the Edit Form locallly
      let updatedEmployee = {
        ...selectedEmployee,
        ...metadata.customMetadata
      }
      // In case employee image gets replaced / (image field is not required in Edit Staff form)
      if (typeof data.image === 'object') {
        if (data.position === 'Clinic Manager') {
          updateActivities({ type: 'New CM Photo', details: `Replaced in ${currentStore.marketingCity} | ${currentStore.stateAbbrev}` })
        } else {
          updateActivities({ type: 'New Staff Photo', details: `${data.position} replaced in ${currentStore.marketingCity} | ${currentStore.stateAbbrev}`, reviewed: true })
        }
        uploadBytes(imageRef, data.image, metadata).then((res) => {
          // This will trigger the useStaffImages hook to rerender and fetch staff images again
          setUploading(!uploading)
          getDownloadURL(imageRef).then(url => {
            updatedEmployee.url = url
            // This will trigger EmployeeCard & Edit Form to rerender
            setSelectedEmployee(updatedEmployee)
          }).catch(err => {
            console.error('Employee getDownloadURL Failed', err)
          })
          helpers.setFieldValue("image", '')
        }).catch(err => {
          console.error('Failed replacing employee image', err)
          helpers.setStatus('failed')
        })
      } else { // if the other fields get updated and NOT the image
        updateMetadata(imageRef, metadata)
          .then((response) => {
            // This will trigger the useStaffImages hook to rerender and fetch staff images again
            setUploading(!uploading)
            // This will trigger EmployeeCard & Edit Form to rerender
            setSelectedEmployee(updatedEmployee)
          }).catch((error) => {
            console.log('Error updating employee info', error)
            helpers.setStatus('failed')
          })
      }
    }
    helpers.resetForm()
    setFormMode(null)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(data, helpers) => onSubmitEditEmployee(data, helpers)}
      enableReinitialize >
      {
        formik => {
          return (
            <Form className="row mx-0 p-4 justify-content-center card h-100">
              <h4 className="lh-base mb-2 px-0">Update staff member</h4>
              {
                formik.status === 'success' ?
                  <div className="alert alert-success text-center py-4" role="alert">
                    <p className="fs-5"><strong>Employee Updated Successfully!</strong></p>
                  </div>
                  :
                  formik.status === 'failed' ?
                    <div className="alert alert-danger text-center col-lg-6" role="alert">
                      Failed to update employee
                    </div>
                    :
                    <>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="image" className="form-label col-form-label text-capitalize main-blue fw-bold">Employee Image</label>
                        </div>
                        <div className="col-sm-8">
                          <input type="file" name="image" id="image" className="form-control" onChange={(e) => formik.setFieldValue("image", e.target.files[0])} disabled={formMode !== 'edit' || !isAdminOrDesigner} />
                          {formik.errors.image && <TextError>{formik.errors.image}</TextError>}
                        </div>
                      </div>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="displayName" className="form-label col-form-label text-capitalize main-blue fw-bold">Display Name</label>
                        </div>
                        <div className="col-sm-8">
                          <Field name="displayName" id="displayName" className="form-control" disabled={formMode !== 'edit'} />
                          <ErrorMessage name="displayName" component={TextError} />
                        </div>
                      </div>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="position" className="form-label col-form-label text-capitalize main-blue fw-bold">Position</label>
                        </div>
                        <div className="col-sm-8">
                          <Field as="select" name="position" id="position" className="form-select" disabled={formMode !== 'edit' || !isAdminOrDesigner} >
                            <option value="">Select position</option>
                            <option value="Assistant Clinic Manager">Assistant Clinic Manager</option>
                            <option value="Client Specialist">Client Specialist</option>
                            <option value="Clinic Manager" disabled={staffImages.find(employee => employee.position === 'Clinic Manager')}>Clinic Manager</option>
                            <option value="Licensed Practical Nurse">Licensed Practical Nurse</option>
                            <option value="Medical Aesthetician">Medical Aesthetician</option>
                            <option value="Nurse Practitioner">Nurse Practitioner</option>
                            <option value="Physician Assistant">Physician Assistant</option>
                            <option value="Registered Nurse">Registered Nurse</option>
                            <option value="Regional Clinical Specialist">Regional Clinical Specialist</option>
                          </Field>
                          <ErrorMessage name="position" component={TextError} />
                        </div>
                      </div>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="showOnWebsite" className={`form-label col-form-label text-capitalize fw-bold ${formik.values.showOnWebsite == 'false' ? 'text-danger' : 'main-blue'}`}>
                            Show on website {formik.values.showOnWebsite == 'false' && <FaExclamationTriangle className="ms-1 mb-1" />}
                          </label>
                        </div>
                        <div className="col-sm-8">
                          <Field as="select" name="showOnWebsite" id="showOnWebsite" className={`form-select ${formik.values.showOnWebsite == 'false' ? 'text-danger border-danger' : ''}`} disabled={formMode !== 'edit' || !isAdminOrDesigner} >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </Field>
                          <ErrorMessage name="showOnWebsite" component={TextError} />
                        </div>
                      </div>
                      {/* ************ Form Actions Control ************** */}
                      <div className="mt-4 px-0">
                        {
                          formMode === 'delete' &&
                          <div className="alert alert-warning" role="alert">
                            Are you sure?
                          </div>
                        }
                        {
                          formMode &&
                          <>
                            <button type="submit" className={`btn shadow-sm btn-${formMode === 'delete' ? 'delete' : 'submit'}`} disabled={!formik.isValid}>
                              {formMode === 'delete' ? 'Yes' : 'Update'}
                            </button>
                            <button type="button" className="btn btn-cancel shadow-sm px-4 float-end" onClick={() => setFormMode(null)}>
                              Cancel
                            </button>
                          </>
                        }
                        {/* if you do the ternary operator, Edit button is submitting the form */}
                        {
                          !formMode &&
                          <>
                            <button type="button" className="btn btn-edit shadow-sm text-white px-4" onClick={() => setFormMode('edit')}>
                              Edit
                            </button>
                            <button type="button" className="btn btn-delete shadow-sm px-4 float-end" onClick={() => setFormMode('delete')}>
                              Delete
                            </button>
                          </>
                        }
                      </div>
                    </>
              }
              {
                (formik.status === 'success' || formik.status === 'failed') &&
                <div className="px-0">
                  <button type="button" className="btn btn-khaki shadow-sm" onClick={() => formik.resetForm()}>
                    {formik.status === 'success' ? 'Done' : 'Try again'}
                  </button>
                </div>
              }
            </Form>
          )
        }
      }
    </Formik>
  )
}

export default EditStaff