import { useState, useEffect } from 'react';
import { collection, limit, onSnapshot, orderBy, query, deleteDoc, doc } from "firebase/firestore"
import { db } from '../firebase/firebase'

export function useRecentActivities() {
	const [recentActivities, setRecentActivities] = useState([])
  const FIREBASE_COLLECTION = 'recent-activities'
	const collectionRef = collection(db, FIREBASE_COLLECTION)
	const q = query(collectionRef, orderBy('createdAt', "desc"), limit(1001))

  useEffect(() => {
		const unsubscribe = onSnapshot(q, (snapshot) => {
			const fetchedData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data()}))
			setRecentActivities(fetchedData.slice(0, 600))
			if(fetchedData.length > 1000) {
				try {
					deleteDoc(doc(db, FIREBASE_COLLECTION, fetchedData[fetchedData.length - 1].id ))
				} catch (error) {
					console.log('error deleting old activity', error)
				}
			}
		}, error => {
			console.error('Error fetching Recent Activities', error)
		});
		// Stop listening to changes
		return unsubscribe
	}, [])
	
  return recentActivities
}
export default useRecentActivities
