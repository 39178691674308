import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { TextError } from '.'
import { FaAsterisk } from 'react-icons/fa'

export function StatesDropdown(props) {
  const { us_states, ...rest } = props
  return (
    <div>
      <div className="input-group mb-2" >
        <div className="input-group-prepend">
          <label htmlFor="state" className="input-group-text">State <sup><FaAsterisk /></sup></label>
        </div>
        <Field as="select" id="state" name="state" className="form-select" {...rest} >
          <option value="">Select a state</option>
          {
            us_states.map((state, x) => (
              <option key={x} value={state.name}>{state.name}</option>
            ))
          }
        </Field>
      </div>
      <ErrorMessage name="state" component={TextError} />
    </div>
  )
}

export default StatesDropdown