import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
import { usePagination } from '../../hooks'
import { GrCheckbox } from 'react-icons/gr'
import { FaCheckSquare } from 'react-icons/fa'
import { doc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase/firebase"

const FIREBASE_COLLECTION = 'recent-activities'

export function RecentActivities({ recentActivities, currentUser, users }) {
  const notReaders = users.filter(user => user.role !== 'reader' && user.firstName && user.lastName).sort((a, b) => a.firstName.localeCompare(b.firstName))
  const [foundActivities, setFoundActivities] = useState(recentActivities)
  const [currentQuery, setCurrentQuery] = useState('All Activities')
  const [selectedUser, setSelectedUser] = useState('Any User')
  // ----------------- Pagination Functionality ----------------------//
  /** NOTES
   * The collection being displayed relies on the foundActivities array
   * collection has nothing to do with the recentActivities that's coming from the db
   * On First Mount, the currentQuery will be set to 'All Activities' by default
   * On First Mount, in useEffect trigger onChangeHandler('All Activities')
   * On First Mount, currentPage will be set to 1
   */
  const { collection, items, handlePaginationClick } = usePagination(foundActivities)

  // This useEffect renders on Mount and also every time the recentActivities gets updated in the db
  // On First Mount, currentQuery will be set to 'All Activities' by default
  // After that, if Admin updates any checkbox, the currentQuery will be maintained
  useEffect(() => {
    onChangeHandler(currentQuery, selectedUser)
  }, [recentActivities])

  // Query Dropdown onChange Handler (Filter Functionality)
  const onChangeHandler = (query, user) => {
    if (query !== currentQuery) {
      setCurrentQuery(query)
      handlePaginationClick(1)
    }
    if (query === 'All Activities') {
      if (user === 'Any User') {
        setSelectedUser('Any User')
        setFoundActivities(recentActivities)
      } else {
        setSelectedUser(user)
        setFoundActivities(recentActivities.filter(activity => activity.author === user))
      }
    } else if (query === 'Admin Review') {
      setSelectedUser(user)
      if (user === 'Any User') {
        setFoundActivities(recentActivities.filter(activity => activity.reviewed === false))
      } else {
        setFoundActivities(recentActivities.filter(activity => activity.reviewed === false && activity.author === user))
      }
    } else {
      setSelectedUser(user)
      if (user === 'Any User') {
        setFoundActivities(recentActivities.filter(activity => activity.type === query))
      } else {
        setFoundActivities(recentActivities.filter(activity => activity.type === query && activity.author === user))
      }
    }
  }

  // Checkbox Admin Review Functionality
  const handleAdminReview = async activity => {
    if (currentUser.role !== 'admin') return
    const activityRef = doc(db, FIREBASE_COLLECTION, activity.id)
    try {
      await updateDoc(activityRef, { reviewed: !activity.reviewed })
    } catch (error) {
      console.error('Error updating activity', error)
    }
  }

  return (
    <div className="container-fluid py-5">
      <div className="row">
        <div className="col-lg-6">
          <h3 className="pb-4">Recent Activities </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Form className="mb-4">
            <Form.Select
              className="d-lg-inline w-auto" aria-label="activity query"
              defaultValue="" name="activity-query" onChange={(e) => onChangeHandler(e.target.value, selectedUser)}
              style={{ minWidth: '370px' }} >
              <option value="All Activities">All Activities</option>
              <option value="New Site">New Sites Created</option>
              <option value="Recently Opened">Recently Opened</option>
              <option value="New Store">New Stores Added</option>
              <option value="Metro Edit">Metros Edited</option>
              <option value="Store Edit">Stores Edited</option>
              <option value="Pro Images Upload">Pro Images Uploaded</option>
              <option value="Clinic Images">Clinic Images Uploaded</option>
              <option value="Global Data">Global Data Updated</option>
              <option value="Store Borrow">Store Borrowed From Another Metro</option>
              <option value="New CM Photo">New CM Photo</option>
              <option value="CM Photo Deleted">CM Photo Deleted</option>
              <option value="New Staff Photo">New Staff Photo</option>
              <option value="Staff Photo Deleted">Staff Photo Deleted</option>
              <option value="Admin Review" disabled={currentUser.role !== 'admin'}>To Be Reviewed</option>
            </Form.Select>
          </Form>
        </div>
        <div className="col-lg-6">
          <Form className="mb-4 float-lg-end">
            <Form.Select
              className="d-lg-inline w-auto" aria-label="user activities"
              value={selectedUser} name="user-activities" onChange={(e) => onChangeHandler(currentQuery, e.target.value)}
              style={{ minWidth: '370px' }} >
              <option value="Any User">Any User</option>
              {
                notReaders.map((user, x) => (
                  <option key={x} className="text-capitalize" value={`${user.firstName} ${user.lastName}`}>{`${user.firstName} ${user.lastName}`}</option>
                ))
              }
            </Form.Select>
          </Form>
        </div>
      </div>
      {
        recentActivities.length > 0 &&
        <div className="row">
          <div className="col">
            {
              collection.length > 0 &&
              <Table striped>
                <thead>
                  <tr>
                    <th>Activity</th>
                    <th>Details</th>
                    <th>Author</th>
                    <th>Date</th>
                    {
                      currentUser.role === 'admin' && <th className="text-center">Admin Review</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    collection.map((activity, x) => (
                      <tr key={x}>
                        <td><strong className="main-blue">{activity.type}</strong></td>
                        <td>{activity.details}</td>
                        <td>{activity.author}</td>
                        <td>{activity.date}</td>
                        {
                          currentUser.role === 'admin' &&
                          <td
                            className={`text-center pb-0 pt-1 fs-5 ${activity.reviewed ? 'text-success' : ''}`}
                            onClick={() => handleAdminReview(activity)}>
                            {activity.reviewed ? <FaCheckSquare /> : <GrCheckbox />}
                          </td>
                        }
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            }
            {
              collection.length > 0 ?
                <div className="mt-3">
                  <Pagination className="mb-0" size='sm'>{items}</Pagination>
                </div>
                :
                <div className="">
                  <div className="alert alert-secondary text-center" role="alert">No activities found for the selected user!</div>
                </div>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default RecentActivities