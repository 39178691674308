import React, {useEffect, useState, useContext} from 'react';
import {FaEdit, FaTrashAlt, FaFacebookSquare, FaYelp, FaMapMarkerAlt, FaGoogle} from 'react-icons/fa';
import {HiChevronDoubleRight, HiChevronDoubleLeft} from 'react-icons/hi';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {TextError, FormikControl, StatesDropdown} from '../../utils';
import {storeValidationSchema as validationSchema, storeInitialValues as initialValues} from './utils';
import states from 'states-us';
import {StoresManagerContext} from '../../home';
import {Timestamp} from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export function StoreDetails() {
  const {updateDB, currentStore, currentUser} = useContext(StoresManagerContext);
  const us_states = states.filter((state) => !state.territory);
  const isAdminOrEditor = currentUser.role === 'admin' || currentUser.role === 'editor';
  const isAdminOrDesigner = currentUser.role === 'admin' || currentUser.role === 'designer';
  const isAdminOrCopywriter = currentUser.role === 'admin' || currentUser.role === 'copywriter';
  const isAdminOrCoordinator = currentUser.role === 'admin' || currentUser.role === 'coordinator';
  const [formMode, setFormMode] = useState(null);

  const onSubmit = (data) => {
    updateDB({data: data, target: 'store', mode: formMode});
    setFormMode(null);
  };

  useEffect(() => {
    return () => {
      setFormMode(null);
    };
  }, [currentStore.salesforceValue]);

  return (
    <div className={`p-4 w-100`}>
      <Formik initialValues={currentStore || initialValues} validationSchema={validationSchema} onSubmit={(data) => onSubmit(data)} enableReinitialize>
        {(formik) => {
          return (
            <Form>
              {/* ***************** First Row ****************** */}
              <div className='row gy-3 gx-5'>
                {currentStore.description.line_one !== '' && (
                  <div className='col-12'>
                    <p>
                      Time zone: <strong className='main-blue'>{currentStore.timezone} Time</strong>
                    </p>
                    <div className='input-group mb-2'>
                      <div className='form-control disabled-bg overflow-scroll py-3 text-center'>
                        Located {currentStore.description.line_one}
                        {currentStore.description.line_two}
                      </div>
                    </div>
                  </div>
                )}
                <div className='col-md-6 col-xl-4'>
                  <FormikControl control='groupInput' name='marketingCity' label='Marketing City' disabled={formMode !== 'edit' || currentUser.role !== 'admin'} />
                </div>
                <div className='col-md-6 col-xl-4'>
                  <FormikControl control='groupInput' name='clinicName' label='Clinic Name' optional disabled={formMode !== 'edit' || !isAdminOrEditor} placeholder='N/A' />
                </div>
                <div className='col-md-6 col-xl-4'>
                  <FormikControl control='groupInput' name='address' disabled={formMode !== 'edit' || !isAdminOrEditor} />
                </div>
                <div className='col-md-6 col-xl-4'>
                  <FormikControl
                    control='groupInput'
                    name='addressCity'
                    label='Address City'
                    optional
                    disabled={formMode !== 'edit' || !isAdminOrEditor}
                    placeholder='Same as Marketing City'
                  />
                </div>
                <div className='col-md-6 col-xl-4'>
                  <StatesDropdown us_states={us_states} disabled={formMode !== 'edit' || currentUser.role !== 'admin'} />
                </div>
                <div className='col-md-6 col-xl-4'>
                  <FormikControl control='groupInput' name='zipCode' label='Zip Code' disabled={formMode !== 'edit' || !isAdminOrEditor} />
                </div>
                <div className='col-md-6 col-xl-4'>
                  <FormikControl control='phoneInput' name='phone' disabled={formMode !== 'edit' || !isAdminOrEditor} />
                </div>
                <div className='col-md-6 col-xl-4'>
                  <FormikControl control='groupInput' name='salesforceValue' label='Salesforce Value' disabled={formMode !== 'edit' || currentUser.role !== 'admin'} />
                </div>
                <div className='col-md-6 col-xl-4'>
                  <FormikControl control='groupInput' name='store_email' label='Clinic Email' disabled={formMode !== 'edit' || !isAdminOrEditor} />
                </div>
                <div className='col-md-6 col-xl-4 z-index-100'>
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <label className='input-group-text' htmlFor='openDate'>
                        Actual Open Date
                      </label>
                    </div>
                    <div className={`form-control ${formMode !== 'edit' && 'disabled-bg'}`}>
                      <DatePicker
                        selected={formik.values.openDate && formik.values.openDate !== '' && formik.values.openDate.seconds ? formik.values.openDate.toDate() : ''}
                        dateFormat='MMM dd, yyyy'
                        className='border-0 w-100 main-blue'
                        name='openDate'
                        id='openDate'
                        placeholderText='Select The Actual Open Date'
                        onChange={(date) => formik.setFieldValue('openDate', date !== null ? Timestamp.fromDate(date) : '')}
                        disabled={formMode !== 'edit' || !isAdminOrCoordinator}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-xl-4'>
                  <div className='input-group mb-2'>
                    <div className='input-group-prepend'>
                      <label htmlFor='open' className='input-group-text'>
                        Open <small>(Website)</small>
                      </label>
                    </div>
                    <Field as='select' name='open' id='open' className='form-select' disabled={formMode !== 'edit' || currentUser.role !== 'admin'}>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Field>
                  </div>
                  <ErrorMessage name='open' component={TextError} />
                </div>
                {currentUser.emailVerified && isAdminOrDesigner && (
                  <div className='col-md-6 col-xl-4'>
                    <FormikControl control='groupInput' name='place_id' label='Google Place ID' optional disabled={formMode !== 'edit' || currentUser.role !== 'admin'} />
                  </div>
                )}
                {currentStore.maps.google !== '' && (
                  <div className='col-md-6 col-xl-4'>
                    <div className='list-group-item py-2 px-3 border mb-2 disabled-bg'>
                      <FaMapMarkerAlt className='mb-1 me-2 main-blue' />
                      <a href={currentStore.maps.google} target='_blank' rel='noreferrer' className='main-blue stretched-link'>
                        Google Maps
                      </a>
                    </div>
                  </div>
                )}
                {currentStore.maps.apple !== '' && (
                  <div className='col-md-6 col-xl-4'>
                    <div className='list-group-item py-2 px-3 border mb-2 disabled-bg'>
                      <FaMapMarkerAlt className='mb-1 me-2 main-blue' />
                      <a href={currentStore.maps.apple} target='_blank' rel='noreferrer' className='main-blue stretched-link'>
                        Apple Maps
                      </a>
                    </div>
                  </div>
                )}
                {currentStore.platforms.facebook !== '' && (
                  <div className='col-md-6 col-xl-4'>
                    <div className='list-group-item py-2 px-3 border mb-2 disabled-bg'>
                      <FaFacebookSquare className='mb-1 me-2' style={{color: '#3E5A97'}} />
                      <a href={`https://fb.com/MilanLaser${currentStore.platforms.facebook}`} target='_blank' rel='noreferrer' className='main-blue stretched-link'>
                        Facebook link
                      </a>
                    </div>
                  </div>
                )}
                {currentStore.platforms.yelp !== '' && (
                  <div className='col-md-6 col-xl-4'>
                    <div className='list-group-item py-2 px-3 border mb-2 disabled-bg'>
                      <FaYelp className='mb-1 me-2' style={{color: 'rgb(216, 43, 44)'}} />
                      <a
                        href={`https://yelp.com/biz/milan-laser-hair-removal-${currentStore.platforms.yelp}`}
                        target='_blank'
                        rel='noreferrer'
                        className='main-blue stretched-link'
                      >
                        Yelp link
                      </a>
                    </div>
                  </div>
                )}
                {currentStore.platforms.google !== '' && (
                  <div className='col-md-6 col-xl-4'>
                    <div className='list-group-item py-2 px-3 border mb-2 disabled-bg'>
                      <FaGoogle className='mb-1 me-2' style={{color: '#4285F4'}} />
                      <a href={currentStore.platforms.google} target='_blank' rel='noreferrer' className='main-blue stretched-link'>
                        Google Reviews Link
                      </a>
                    </div>
                  </div>
                )}
              </div>

              {/* ***************** Second Row ****************** */}
              <div className='row mt-1 gy-3 gx-5'>
                {currentStore.rating && currentStore.reviewCount && (
                  <div className='col-12'>
                    <div className='input-group mb-2'>
                      <div className='form-control disabled-bg text-center py-3 fs-5'>
                        <HiChevronDoubleLeft className='main-blue me-4 mb-1 fs-5 fw-bold' />
                        <strong className='main-blue'>{currentStore.marketingCity}</strong> rated <strong className='main-blue'>{currentStore.rating} / 5</strong> based on{' '}
                        <strong className='main-blue'>{currentStore.reviewCount}</strong> reviews on Google
                        <HiChevronDoubleRight className='main-blue ms-4 mb-1 fs-5 fw-bold' />
                      </div>
                    </div>
                  </div>
                )}
                {currentUser.emailVerified && isAdminOrCopywriter && (
                  <div className='col-md-12'>
                    <h4 className='h5'>Description</h4>
                    <FormikControl control='groupInput' name='description.field_one' label='Field One' optional disabled={formMode !== 'edit' || !isAdminOrCopywriter} />
                    <FormikControl control='groupInput' name='description.field_two' label='Field Two' optional disabled={formMode !== 'edit' || !isAdminOrCopywriter} />
                    <FormikControl control='groupInput' name='description.line_one' label='Short' optional disabled={formMode !== 'edit' || !isAdminOrCopywriter} />
                    <FormikControl control='groupInput' name='description.line_two' label='Long' optional disabled={formMode !== 'edit' || !isAdminOrCopywriter} />
                    <FormikControl control='groupInput' name='description.field_three' label='Field Three' optional disabled={formMode !== 'edit' || !isAdminOrCopywriter} />
                  </div>
                )}
                {currentUser.emailVerified && isAdminOrEditor && (
                  <div className='col-md-6'>
                    <h4 className='h5'>Maps Links</h4>
                    <FormikControl control='groupInput' name='maps.google' label='Google' optional disabled={formMode !== 'edit'} />
                    <FormikControl control='groupInput' name='maps.apple' label='Apple' optional disabled={formMode !== 'edit'} />
                  </div>
                )}
                <div className='col-md-6'>
                  <h4 className='h5'>Geocode</h4>
                  <FormikControl control='groupInput' name='geo.lat' label='Lat' optional disabled={formMode !== 'edit' || currentUser.role !== 'admin'} />
                  <FormikControl control='groupInput' name='geo.long' label='Long' optional disabled={formMode !== 'edit' || currentUser.role !== 'admin'} />
                </div>
                {currentUser.emailVerified && isAdminOrEditor && (
                  <div className='col-xl-8'>
                    <h4 className='h5'>Platforms</h4>
                    {currentUser.role === 'admin' && (
                      <>
                        <FormikControl
                          control='groupInput'
                          name='platforms.reviews.list_token'
                          label='List Token'
                          optional
                          disabled={formMode !== 'edit'}
                          placeholder='reviewsonmywebsite token'
                        />
                        <FormikControl
                          control='groupInput'
                          name='platforms.reviews.slide_token'
                          label='Slider Token'
                          optional
                          disabled={formMode !== 'edit'}
                          placeholder='reviewsonmywebsite token'
                        />
                      </>
                    )}
                    {isAdminOrEditor && (
                      <>
                        <FormikControl
                          control='groupInput'
                          name='platforms.google'
                          label='Google Reviews'
                          optional
                          disabled={formMode !== 'edit'}
                          placeholder='Google Reviews Link For The Reviews Page'
                        />
                        <FormikControl
                          control='groupInput'
                          name='platforms.facebook'
                          label='fb.com/MilanLaser'
                          optional
                          disabled={formMode !== 'edit' || !isAdminOrEditor}
                          placeholder='Complete the link'
                        />
                        <FormikControl
                          control='groupInput'
                          name='platforms.yelp'
                          label='yelp.com/biz/milan-laser-hair-removal-'
                          optional
                          disabled={formMode !== 'edit' || !isAdminOrEditor}
                          placeholder='Complete the link'
                        />
                      </>
                    )}
                  </div>
                )}
                {(isAdminOrCopywriter || isAdminOrDesigner) && (
                  <div className='col-md-6 col-xl-4'>
                    {isAdminOrDesigner && (
                      <div style={{paddingBottom: '14px'}}>
                        <h4 className='h5'>Pro Images</h4>
                        <div>
                          <div className='input-group mb-2'>
                            <div className='input-group-prepend'>
                              <label htmlFor='with_pro_images' className='input-group-text'>
                                Pro Images
                              </label>
                            </div>
                            <Field as='select' name='with_pro_images' id='with_pro_images' className='form-select' disabled={formMode !== 'edit' || !isAdminOrDesigner}>
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </Field>
                          </div>
                          <ErrorMessage name='with_pro_images' component={TextError} />
                        </div>
                        {(formik.values.with_pro_images === 'true' || formik.values.with_pro_images === true) && (
                          <>
                            <div className='input-group mb-2'>
                              <div className='input-group-prepend'>
                                <label htmlFor='hero.reversed' className='input-group-text'>
                                  Clinic Location
                                </label>
                              </div>
                              <Field as='select' name='hero.reversed' id='hero.reversed' className='form-select' disabled={formMode !== 'edit' || !isAdminOrDesigner}>
                                <option value={false}>On the right</option>
                                <option value={true}>On the left</option>
                              </Field>
                              <ErrorMessage name='hero.reversed' component={TextError} />
                            </div>
                            <div className='input-group mb-2'>
                              <div className='input-group-prepend'>
                                <label htmlFor='hero.position' className='input-group-text'>
                                  Hero BG Position
                                </label>
                              </div>
                              <Field as='select' name='hero.position' id='hero.position' className='form-select' disabled={formMode !== 'edit' || !isAdminOrDesigner}>
                                <option value='center'>Center</option>
                                <option value='left'>Left</option>
                                <option value='right'>Right</option>
                              </Field>
                              <ErrorMessage name='hero.position' component={TextError} />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    {isAdminOrCopywriter && (
                      <div className='mt-4 pt-2'>
                        <FormikControl
                          control='groupInput'
                          name='cm_pardot_file'
                          label='CM Pardot File'
                          optional
                          disabled={formMode !== 'edit' || !isAdminOrCopywriter}
                          placeholder='E.g. 51-75'
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* ************ Form Actions Control ************** */}
              {currentUser.emailVerified && (isAdminOrEditor || currentUser.role === 'designer' || currentUser.role === 'copywriter' || currentUser.role === 'coordinator') && (
                <>
                  {formMode === 'delete' && (
                    <div className='alert alert-warning px-4 mt-3 mb-0 d-inline-block' role='alert'>
                      <p className='mb-2'>
                        <strong className='main-blue'>{currentStore.marketingCity}</strong> store will be deleted!
                      </p>
                      Are you sure?
                    </div>
                  )}
                  {formMode ? (
                    <div className='mt-4'>
                      <input
                        type='submit'
                        value={formMode === 'delete' ? 'Delete Location' : 'Submit'}
                        className={`btn shadow-sm px-5 me-3 ${formMode === 'delete' ? 'btn-delete' : 'btn-submit'}`}
                        disabled={!formik.isValid}
                      />
                      <button type='button' className='btn btn-cancel shadow-sm float-end px-3' onClick={() => setFormMode(null)}>
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className='mt-4'>
                      <button type='button' className='btn btn-edit shadow-sm' onClick={() => setFormMode('edit')}>
                        {' '}
                        <FaEdit className='mb-1' /> Edit <span className='d-none d-md-inline'>{currentStore.marketingCity} Location</span>
                      </button>
                      {currentUser.role === 'admin' && (
                        <button type='button' className='btn btn-delete shadow-sm float-end' onClick={() => setFormMode('delete')}>
                          <FaTrashAlt className='mb-1' /> Delete <span className='d-none d-sm-inline'>{currentStore.marketingCity}</span>{' '}
                          <span className='d-none d-xl-inline'>Location</span>
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
export default StoreDetails;
