import React from 'react'

export function AwardCard({ selectedAward }) {
  return (
    <div className="img-thumbnail mx-auto mb-4 mb-md-0" style={{ width: '200px', minHeight: '200px' }}>
      <a href={selectedAward.link !== '' ? selectedAward.link : null} target="_blank" className="d-block">
        <img src={selectedAward.url} alt={selectedAward.name} className="w-100" />
        <div className="text-center py-2">
          <div>
            <strong className="main-blue">
              {selectedAward.name}
            </strong>
          </div>
        </div>
      </a>
    </div>
  )
}

export default AwardCard