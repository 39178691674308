import React from 'react'

function BrandFonts() {
  return (
  <section className="py-3 py-sm-5 bg-blue text-white">
    <div className="container py-sm-5">
      <div className="row">
        <div className=" col-sm-6 my-sm-auto mt-4 mt-sm-0">
          <h1 className="text-uppercase brandon-grotesque pop">Do You Need To Install The Milan Brand Fonts?</h1>
        </div>
        <div className=" col-sm-5 my-auto pt-4 pt-sm-0">
          <p>Create a project that’s equal parts strong and polished with the Milan Brand. You want that professional look, one that builds consistency and confidence, and to get it, be sure to use the brand fonts  in your presentations. Click below to create a ticket for the IT team install the Milan brand fonts on your system.</p>
          <div className="text-white pt-2 mb-4 mb-sm-0"><a href="https://milanlaser.happyfox.com" target="_blank" rel="noreferrer" ><button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">Create Help Desk Ticket</button></a></div>

        </div>
      </div>
    </div>
  </section>
  )
}

export default BrandFonts
