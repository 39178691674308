import {doc, updateDoc} from 'firebase/firestore';
import {db} from '../../firebase/firebase';
import {FIREBASE_COLLECTION, trimAll, hardTrim} from '../../utils';
import {updateRecentActivities} from '..';
import Geocode from 'react-geocode';
import states from 'states-us';
import axios from 'axios';
import {GOOGLE_TIMEZONE_API_URL} from '../../../shared/constants';

const us_states = states.filter((state) => !state.territory);

export const updateMilanStoresDB = (milanStores, currentUser, currentState, currentMetro, currentStore, updateCurrentView, medicalDirectors, action) => {
  if (
    !currentUser.emailVerified ||
    !(currentUser.role === 'admin' || currentUser.role === 'designer' || currentUser.role === 'editor' || currentUser.role === 'copywriter' || currentUser.role === 'coordinator')
  )
    return;
  // Get Current Indexes
  const currentStateIndex = milanStores.findIndex((state) => state.name === currentState.name);
  const currentMetroIndex = currentMetro ? milanStores[currentStateIndex].metros.findIndex((metro) => metro.pathname === currentMetro.pathname) : null;
  const currentStoreIndex =
    currentMetro && currentStore
      ? milanStores[currentStateIndex].metros[currentMetroIndex].stores.findIndex((store) => store.salesforceValue === currentStore.salesforceValue)
      : null;
  const stateRef = doc(db, FIREBASE_COLLECTION, currentState.name);

  // ***********************************  Metro  ************************************ //
  // - Add New Metro
  const addNewMetro = async (data, helpers) => {
    const newMetro = {
      name: data.name.trim(),
      metroPath: hardTrim(data.name).toLowerCase(),
      pathname: trimAll(data.pathname),
      origin: `https://milanlaser${trimAll(data.pathname)}.com`,
      state: currentState.name,
      stateAbbrev: currentState.abbreviation,
      grandOpening: true,
      gtag_id: data.gtag_id,
      google_analytics_id: data.google_analytics_id,
      bing_id: data.bing_id,
      google_verification: '',
      openStores: 0,
      stores: [],
    };
    // Update the milanStores locally so the view gets updated right away after submitting
    milanStores[currentStateIndex].metros.push(newMetro);
    milanStores[currentStateIndex].metros.sort((a, b) => a.name.localeCompare(b.name));
    try {
      await updateDoc(stateRef, {metros: milanStores[currentStateIndex].metros});
      updateCurrentView({
        stateName: currentState.name,
        metroPathname: newMetro.pathname,
        salesforceValue: '',
      });
      helpers.setStatus('success');
      setTimeout(() => {
        helpers.resetForm();
      }, 1200);
      updateRecentActivities({type: 'New Site', details: `milanlaser${newMetro.pathname}.com in ${newMetro.state}`}, currentUser);
    } catch (error) {
      console.error('Failed adding new metro: Double Check DB', error);
      helpers.setStatus('failed');
    }
  };

  // - Edit Current Metro
  const editMetro = (data) => {
    const toBeEdited = {...currentMetro};
    toBeEdited.name = data.name.trim();
    toBeEdited.pathname = trimAll(data.pathname);
    toBeEdited.origin = `https://milanlaser${trimAll(data.pathname)}.com`;
    toBeEdited.metroPath = trimAll(data.metroPath);
    toBeEdited.gtag_id = data.gtag_id;
    toBeEdited.google_analytics_id = data.google_analytics_id;
    toBeEdited.bing_id = data.bing_id;
    toBeEdited.google_verification = data.google_verification.trim();
    toBeEdited.grandOpening = data.grandOpening === 'true' || data.grandOpening === true ? true : false;

    milanStores[currentStateIndex].metros[currentMetroIndex] = toBeEdited;
    // To Avoid UI Error when changing the pathname
    //  Remember that Metro currentView relies on metro pathname to render
    currentMetro.pathname = toBeEdited.pathname;
    updateRecentActivities({type: 'Metro Edit', details: `${toBeEdited.name}, ${toBeEdited.stateAbbrev}`}, currentUser);
    updateFireBaseDoc();
  };

  // - Delete Current Metro
  const deleteMetro = () => {
    if (currentUser.role !== 'admin') return;
    milanStores[currentStateIndex].metros.splice(currentMetroIndex, 1);
    // Check if there is any metros left in the current state after deleting
    const stateHasMetro = milanStores[currentStateIndex].metros.length !== 0;
    // if there's a metro left, then check if that metro has at least one store
    const firstMetroHasStore = stateHasMetro && milanStores[currentStateIndex].metros[0].stores.length !== 0;
    updateCurrentView({
      stateName: currentState.name,
      metroPathname: stateHasMetro ? milanStores[currentStateIndex].metros[0].pathname : '',
      salesforceValue: stateHasMetro && firstMetroHasStore ? milanStores[currentStateIndex].metros[0].stores[0].salesforceValue : '',
    });
    updateFireBaseDoc();
  };

  // ************************************  Store  ************************************ //
  // - Add New Store
  const addNewStore = async (data, helpers) => {
    const newStore = {
      address: data.address.trim(),
      addressCity: data.addressCity.trim(),
      marketingCity: data.marketingCity.trim(),
      pathname: hardTrim(data.marketingCity).toLowerCase(),
      open: data.open === 'true' || data.open === true ? true : false,
      phone: data.phone,
      place_id: data.place_id.trim(),
      salesforceValue: data.salesforceValue.trim(),
      store_email: data.store_email.toLowerCase().trim(),
      openDate: data.openDate && data.openDate !== '' && data.openDate !== null && Object.keys(data.openDate).length !== 0 ? data.openDate : '',
      clinicName: data.clinicName.trim(),
      state: data.state,
      stateAbbrev: us_states.find((state) => state.name === data.state).abbreviation,
      zipCode: data.zipCode.trim(),
      with_pro_images: false,
      cm_pardot_file: '',
      rating: null,
      reviewCount: null,
      timezone: '',
      hero: {
        reversed: true,
        position: 'center',
      },
      maps: {
        google: data.maps.google.trim(),
        apple: data.maps.apple.trim(),
      },
      geo: {
        lat: '',
        long: '',
      },
      description: {
        field_one: data.description.field_one.trim(),
        field_two: data.description.field_two.trim(),
        line_one: data.description.line_one.trim(),
        line_two: ' ' + data.description.line_two.trim(),
        field_three: data.description.field_three.trim(),
      },
      platforms: {
        reviews: {
          list_token: data.platforms.reviews.list_token,
          slide_token: data.platforms.reviews.slide_token,
        },
        facebook: data.platforms.facebook.trim(),
        yelp: data.platforms.yelp.trim().toLowerCase(),
        google: data.platforms.google.trim(),
      },
    };
    if (newStore.open === true) milanStores[currentStateIndex].metros[currentMetroIndex].openStores += 1;
    // Don't update currentState or currentMetro directly // The view doesn't get updated right away when you do that
    // Also it's not a good paractice to alter a useState const directly
    milanStores[currentStateIndex].metros[currentMetroIndex].stores.push(newStore);
    milanStores[currentStateIndex].metros[currentMetroIndex].stores.sort((a, b) => a.marketingCity.localeCompare(b.marketingCity));
    try {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
      Geocode.setLocationType('ROOFTOP');
      const geoResponse = await Geocode.fromAddress(
        `${newStore.address} ${newStore.addressCity !== '' ? newStore.addressCity : newStore.marketingCity}, ${newStore.stateAbbrev} ${newStore.zipCode}, USA`
      );
      const {lat, lng} = geoResponse.results[0].geometry.location;
      if (lat && lng) {
        newStore.geo.lat = lat.toFixed(6).toString();
        newStore.geo.long = lng.toFixed(6).toString();
        // Get Timezone
        const timezone = await axios.get(
          `${GOOGLE_TIMEZONE_API_URL}?location=${newStore.geo.lat}%2C${newStore.geo.long}&timestamp=${Date.now().toString().slice(0, -3)}&key=${
            process.env.REACT_APP_GOOGLE_MAPS_API_KEY
          }`
        );
        newStore.timezone = timezone.data && timezone.data.status === 'OK' ? timezone.data.timeZoneName.split(' ')[0] : '';
      }
    } catch (error) {
      console.error('Error getting lat, lng', error);
    } finally {
      try {
        await updateDoc(stateRef, {metros: milanStores[currentStateIndex].metros});
        // Medical Director Assignment
        const md_doc = medicalDirectors.find((md) => md.id === data.md);
        md_doc.locations.push({
          metro: currentMetro.name,
          name: newStore.marketingCity,
          stateAbbrev: newStore.stateAbbrev,
        });
        md_doc.locations.sort((a, b) => a.name.localeCompare(b.name));
        const mdDocRef = doc(db, 'medical-directors', data.md);
        await updateDoc(mdDocRef, {locations: md_doc.locations});
        updateCurrentView({
          stateName: currentState.name,
          metroPathname: currentMetro.pathname,
          salesforceValue: newStore.salesforceValue,
        });
        helpers.setStatus('success');
        setTimeout(() => {
          helpers.resetForm();
        }, 1200);
        // Update Activities
        updateRecentActivities({type: 'New Store', details: `${newStore.marketingCity} store added to ${currentMetro.name}, ${currentMetro.stateAbbrev} site`}, currentUser);
      } catch (error) {
        console.error('Error adding new store: Double Check DB', error);
        helpers.setStatus('failed');
      }
    }
  };

  // - Edit Current Store
  const editStore = async (data) => {
    // Store some props before editing
    const isOpen = currentStore.open;
    const addressBeforeEditing = currentStore.address;
    const toBeEdited = {...currentStore};

    toBeEdited.address = data.address.trim();
    toBeEdited.addressCity = data.addressCity.trim();
    toBeEdited.marketingCity = data.marketingCity.trim();
    toBeEdited.pathname = hardTrim(data.marketingCity).toLowerCase();
    toBeEdited.open = data.open === 'true' || data.open === true ? true : false;
    toBeEdited.phone = data.phone;
    toBeEdited.place_id = data.place_id.trim();
    toBeEdited.salesforceValue = data.salesforceValue.trim();
    toBeEdited.store_email = data.store_email.toLowerCase().trim();
    toBeEdited.openDate = data.openDate && data.openDate !== '' && data.openDate !== null ? data.openDate : '';
    toBeEdited.clinicName = data.clinicName.trim();
    toBeEdited.state = data.state;
    toBeEdited.stateAbbrev = us_states.find((state) => state.name === data.state).abbreviation;
    toBeEdited.zipCode = data.zipCode.trim();
    toBeEdited.with_pro_images = data.with_pro_images === 'true' || data.with_pro_images === true ? true : false;
    toBeEdited.hero.reversed = data.hero.reversed === 'true' || data.hero.reversed === true ? true : false;
    toBeEdited.hero.position = data.hero.position;
    toBeEdited.maps.google = data.maps.google.trim();
    toBeEdited.maps.apple = data.maps.apple.trim();
    toBeEdited.geo.lat = data.geo.lat.trim();
    toBeEdited.geo.long = data.geo.long.trim();
    toBeEdited.description.field_one = data.description.field_one.trim();
    toBeEdited.description.field_two = data.description.field_two.trim();
    toBeEdited.description.line_one = data.description.line_one.trim();
    toBeEdited.description.line_two = ' ' + data.description.line_two.trim();
    toBeEdited.description.field_three = data.description.field_three.trim();
    toBeEdited.platforms.reviews.list_token = data.platforms.reviews.list_token.trim();
    toBeEdited.platforms.reviews.slide_token = data.platforms.reviews.slide_token.trim();
    toBeEdited.platforms.facebook = data.platforms.facebook;
    toBeEdited.platforms.yelp = data.platforms.yelp.trim().toLowerCase();
    toBeEdited.platforms.google = data.platforms.google.trim();
    toBeEdited.cm_pardot_file = data.cm_pardot_file.trim();

    // if open prop changed
    if (toBeEdited.open !== isOpen) {
      toBeEdited.open === true
        ? (milanStores[currentStateIndex].metros[currentMetroIndex].openStores += 1)
        : (milanStores[currentStateIndex].metros[currentMetroIndex].openStores -= 1);
    }
    // if address changed OR Geocodes are empty
    if (toBeEdited.address !== addressBeforeEditing || currentStore.geo.lat === '' || currentStore.geo.long === '') {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
      Geocode.setLocationType('ROOFTOP');
      try {
        const geoResponse = await Geocode.fromAddress(
          `${toBeEdited.address} ${toBeEdited.addressCity !== '' ? toBeEdited.addressCity : toBeEdited.marketingCity}, ${toBeEdited.stateAbbrev} ${toBeEdited.zipCode}, USA`
        );
        const {lat, lng} = geoResponse.results[0].geometry.location;
        if (lat && lng) {
          toBeEdited.geo.lat = lat.toFixed(6).toString();
          toBeEdited.geo.long = lng.toFixed(6).toString();
        }
      } catch (error) {
        console.error('Error getting lat, lng', error);
      }
    }
    if (toBeEdited.timezone === '') {
      try {
        // Get Timezone
        const timezone = await axios.get(
          `${GOOGLE_TIMEZONE_API_URL}?location=${toBeEdited.geo.lat}%2C${toBeEdited.geo.long}&timestamp=${Date.now().toString().slice(0, -3)}&key=${
            process.env.REACT_APP_GOOGLE_MAPS_API_KEY
          }`
        );
        toBeEdited.timezone = timezone.data && timezone.data.status === 'OK' ? timezone.data.timeZoneName.split(' ')[0] : '';
      } catch (error) {
        console.log('Error getting timezone', error);
      }
    }
    milanStores[currentStateIndex].metros[currentMetroIndex].stores[currentStoreIndex] = toBeEdited;
    // To Avoid UI Error when changing the salesforceValue
    //  Remember that Store currentView relies on store salesforceValue to update
    currentStore.salesforceValue = toBeEdited.salesforceValue;
    updateRecentActivities({type: 'Store Edit', details: `${toBeEdited.marketingCity} store in ${currentMetro.name}, ${currentMetro.stateAbbrev} metro`}, currentUser);
    if (isOpen === false && toBeEdited.open === true) {
      updateRecentActivities({type: 'Recently Opened', details: `${toBeEdited.marketingCity} store in ${currentMetro.name}, ${currentMetro.stateAbbrev} metro`}, currentUser);
    }
    updateFireBaseDoc();
  };
  // - Delete Current Store
  const deleteStore = () => {
    if (currentUser.role !== 'admin') return;
    if (currentStore.open === true) milanStores[currentStateIndex].metros[currentMetroIndex].openStores -= 1;
    // Check if there's any stores left in this current metro
    const toReplaceDeleted = currentMetro.stores.find((store) => store.salesforceValue !== currentStore.salesforceValue);
    currentMetro.stores.splice(currentStoreIndex, 1);
    updateCurrentView({
      stateName: currentState.name,
      metroPathname: currentMetro.pathname,
      salesforceValue: toReplaceDeleted ? toReplaceDeleted.salesforceValue : '',
    });
    updateFireBaseDoc();
  };

  // ************************************ Borrow Store ************************************ //
  const borrowStore = (toBeBorrowed) => {
    if (currentUser.role !== 'admin') return;
    if (!milanStores[currentStateIndex].metros[currentMetroIndex].borrowed_stores) {
      milanStores[currentStateIndex].metros[currentMetroIndex].borrowed_stores = [];
    }
    milanStores[currentStateIndex].metros[currentMetroIndex].borrowed_stores.push(toBeBorrowed);
    updateRecentActivities({type: 'Store Borrow', details: `Added in ${currentMetro.name}, ${currentMetro.stateAbbrev} metro`}, currentUser);
    updateFireBaseDoc();
  };

  const deleteBorrowedStore = (toBeDeleted) => {
    if (currentUser.role !== 'admin') return;
    const borrowedStoreIndex = milanStores[currentStateIndex].metros[currentMetroIndex].borrowed_stores.findIndex(
      (store) => store.details.salesforceValue === toBeDeleted.details.salesforceValue
    );
    milanStores[currentStateIndex].metros[currentMetroIndex].borrowed_stores.splice(borrowedStoreIndex, 1);
    updateRecentActivities({type: 'Store Borrow', details: `Deleted in ${currentMetro.name}, ${currentMetro.stateAbbrev} metro`}, currentUser);
    updateFireBaseDoc();
  };

  // ************************************ Pro Images ************************************ //
  const proImages = (data) => {
    if (!(currentUser.role === 'admin' || currentUser.role === 'designer')) return;
    milanStores[currentStateIndex].metros[currentMetroIndex].stores[currentStoreIndex].with_pro_images = data.hero;
    currentStore.with_pro_images = data.hero;
    milanStores[currentStateIndex].metros[currentMetroIndex].stores[currentStoreIndex].hero.reversed = data.reversed === 'true' || data.reversed === true ? true : false;
    milanStores[currentStateIndex].metros[currentMetroIndex].stores[currentStoreIndex].hero.position = data.position;
    updateFireBaseDoc();
  };

  // --------------------- Update FireBase Document------------------------ //
  const updateFireBaseDoc = async () => {
    try {
      await updateDoc(stateRef, {metros: milanStores[currentStateIndex].metros});
    } catch (error) {
      console.error('Error updating metro', error);
    }
  };

  // --------------------- ******** ACTIONS ******* ------------------------ //
  if (action.target === 'metro') {
    if (action.mode === 'add new') addNewMetro(action.data, action.helpers);
    if (action.mode === 'edit') editMetro(action.data);
    if (action.mode === 'delete') deleteMetro();
    if (action.mode === 'borrow store') borrowStore(action.data);
    if (action.mode === 'delete borrowed store') deleteBorrowedStore(action.data);
    return;
  }

  if (action.target === 'store') {
    if (action.mode === 'add new') addNewStore(action.data, action.helpers);
    if (action.mode === 'edit') editStore(action.data);
    if (action.mode === 'delete') deleteStore();
    if (action.mode === 'pro images') proImages(action.data);
    return;
  }
};
