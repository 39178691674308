import React from 'react'
import { Field, ErrorMessage } from "formik"
import { TextError } from "."
import { FaAsterisk } from 'react-icons/fa'

export function GroupInput(props) {
  const { name, label, optional, small, ...rest } = props
  return (
    <div>
      <div className={`input-group ${small ? '' : 'mb-2'}`} >
        <div className="input-group-prepend">
          <label htmlFor={name} className={`input-group-text ${!label ? 'text-capitalize' : ''}`}>{label || name} {!optional && <sup><FaAsterisk /></sup>}</label>
        </div>
        <Field name={name} id={name} className="form-control" {...rest} />
      </div>
      { small && <small className="text-muted">{small}</small>}
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default GroupInput