import React, { useState } from 'react'
import { useEffect } from 'react'
import Toast from 'react-bootstrap/Toast'

export function AppUpdateToastAlert({ globalData }) {
  const [showToast, setShowToast] = useState(true)
  const [lastUpdated, setLastUpdated] = useState(globalData.fm_last_update)

  useEffect(() => {
    if(lastUpdated !== globalData.fm_last_update) {
      setShowToast(true)
      setLastUpdated(globalData.fm_last_update)
    }
  }, [globalData.fm_last_update])

  return (
    <Toast show={showToast} onClose={() => setShowToast(false)}>
      <Toast.Header>
        <strong className="me-auto">Last updated on:</strong>
        <strong className="main-blue">{globalData.fm_last_update}</strong>
      </Toast.Header>
      <Toast.Body>
        <p className="mb-0">If you haven't already, please refresh the page now to get the latest version. Thanks!</p>
      </Toast.Body>
    </Toast>
  );
}

export default AppUpdateToastAlert