import React, { useState, useContext, useEffect } from "react";
import { FaEdit, FaTrashAlt, FaCopy } from 'react-icons/fa'
import { Formik, Form, Field, ErrorMessage } from "formik"
import { TextError, FormikControl } from "../../utils"
import { metroValidationSchema as validationSchema, metroInitialValues as initialValues, MetroCarousel, BorrowStore, BorrowedStores } from '.'
import { StoreTabsWrapper } from ".."
import { StoresManagerContext } from "../../home"
import { CopyToClipboard } from 'react-copy-to-clipboard'

export function MetroDetails() {
  const { milanStores, updateDB, currentMetro, currentStore, currentUser } = useContext(StoresManagerContext)
  const currentMetroJson = JSON.stringify(currentMetro)
  const [copyJson, setCopyJson] = useState(false)
  const [formMode, setFormMode] = useState(null)
  const [borrwoStore, setBorrowStore] = useState(false)

  const onSubmit = (data) => {
    updateDB({ data: data, target: 'metro', mode: formMode })
    setFormMode(null)
  }

  const handleCopyJson = () => {
    setCopyJson(true)
    setTimeout(() => {
      setCopyJson(false)
    }, 5000)
  }

  useEffect(() => {
    // When navigating between metros -> Disable Edit form & Hide Borrow Store form initially
    return () => {
      setFormMode(null)
      setBorrowStore(false)
    }
  }, [currentMetro.pathname])

  return (
    <div id="metro-details" className="container-fluid py-4 px-3 px-md-5">
      <MetroCarousel currentMetro={currentMetro} />
      <div className={`row justify-content-center`}>
        <Formik
          initialValues={currentMetro || initialValues}
          validationSchema={validationSchema}
          onSubmit={(data) => onSubmit(data)}
          enableReinitialize >
          {
            formik => {
              return (
                <Form className={`px-2 py-5 w-100`}>
                  <h2 className="mb-5 h1 d-inline-block">
                    Metro: <strong className="main-blue">{currentMetro.name}</strong>
                  </h2>
                  {
                    currentUser.role === 'admin' &&
                    <CopyToClipboard text={currentMetroJson}>
                      <button type="button" className="btn btn-submit shadow-sm float-end" onClick={() => handleCopyJson()}>
                        {copyJson ? 'COPPIED' : 'Copy Metro JSON'} <FaCopy className={`ms-2 mb-1 ${copyJson ? 'light-blue' : ''}`} />
                      </button>
                    </CopyToClipboard>
                  }
                  <div className="row gy-3 gx-5">
                    <div className="col-md-6 col-xl-4">
                      <div className="input-group mb-2" >
                        <div className="input-group-prepend">
                          <label className="input-group-text">URL</label>
                        </div>
                        <div className="form-control disabled-bg overflow-scroll"><a href={currentMetro.origin} target="_blank" rel="noreferrer" className="main-blue stretched-link">{currentMetro.origin.split('//')[1]}</a></div>
                      </div>
                    </div>
                    {
                      currentUser.role === 'admin' &&
                      <>
                        <div className="col-md-6 col-xl-4">
                          <div className="input-group mb-2" >
                            <div className="input-group-prepend">
                              <label className="input-group-text text-lowercase">milanlaser</label>
                            </div>
                            <Field name="pathname" id="pathname" className="form-control" disabled={formMode !== 'edit'} />
                          </div>
                          <ErrorMessage name="pathname" component={TextError} />
                        </div>
                        <div className="col-md-6 col-xl-4">
                          <div className="input-group mb-2" >
                            <div className="input-group-prepend">
                              <label className="input-group-text text-lowercase">.com/locations/</label>
                            </div>
                            <Field name="metroPath" id="metroPath" className="form-control" disabled={formMode !== 'edit'} />
                          </div>
                          <ErrorMessage name="metroPath" component={TextError} />
                        </div>
                      </>
                    }
                    <div className="col-md-6 col-xl-4">
                      <div className="input-group mb-2" >
                        <div className="input-group-prepend">
                          <label className="input-group-text">State</label>
                        </div>
                        <div className="form-control disabled-bg">{currentMetro.state} | {currentMetro.stateAbbrev}</div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                      <div className="input-group mb-2" >
                        <div className="input-group-prepend">
                          <label htmlFor="grandOpening" className="input-group-text">Grand Opening</label>
                        </div>
                        <Field as="select" name="grandOpening" id="grandOpening" className="form-select" disabled={formMode !== 'edit' || !(currentUser.role === 'admin' || currentUser.role === 'copywriter')}>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </Field>
                      </div>
                      <ErrorMessage name="grandOpening" component={TextError} />
                    </div>
                    {
                      currentUser.emailVerified && (currentUser.role === 'editor' || currentUser.role === 'admin') &&
                      <>
                        {
                          currentUser.role === 'admin' &&
                          <div className="col-md-6 col-xl-4">
                            {/* This field is required. I just don't want to show the asterisk here */}
                            <FormikControl control="groupInput" name="name" label="Metro" optional disabled={formMode !== 'edit'} />
                          </div>
                        }
                        <div className="col-md-6 col-xl-4">
                          <FormikControl control="groupInput" name="gtag_id" label="GTAG ID" optional disabled={formMode !== 'edit'} placeholder="For gtag manager" />
                        </div>
                        <div className="col-md-6 col-xl-4">
                          <FormikControl control="groupInput" name="google_analytics_id" label="Google Analytics" optional disabled={formMode !== 'edit'} placeholder="For Google Analytics" />
                        </div>
                        <div className="col-md-6 col-xl-4">
                          <FormikControl control="groupInput" name="bing_id" label="Bing ID" optional disabled={formMode !== 'edit'} />
                        </div>
                        <div className="col-md-6 col-xl-4">
                          <FormikControl control="groupInput" name="google_verification" label="Verification ID" placeholder="Google Verification ID" optional disabled={formMode !== 'edit'} />
                        </div>
                      </>
                    }
                  </div>
                  {/* ************ Form Actions Control ************** */}
                  {
                    currentUser.emailVerified && (currentUser.role === 'admin' || currentUser.role === 'editor' || currentUser.role === 'copywriter') &&
                    <>
                      {
                        formMode === 'delete' &&
                        <div className="alert alert-warning mt-4 mb-0 d-inline-block px-4" role="alert">
                          <strong className="main-blue">{currentMetro.name}</strong> and all its stores will be deleted! <br />
                          Are you sure?
                        </div>
                      }
                      {
                        formMode ?
                          <div className="mt-4">
                            <input type="submit" value={formMode === 'delete' ? 'Delete Metro' : 'Submit'} className={`btn px-5 me-3 shadow-sm ${formMode === 'delete' ? 'btn-delete' : 'btn-submit'}`} disabled={!formik.isValid} />
                            <button type="button" className="btn btn-cancel shadow-sm float-end px-3" onClick={() => (setFormMode(null))}>Cancel</button>
                          </div>
                          :
                          <div className="mt-4 d-flex justify-content-between">
                            <button type="button" className="btn btn-edit shadow-sm" onClick={() => setFormMode('edit')}> <FaEdit className="mb-1" /> Edit Metro</button>
                            {
                              currentUser.role === 'admin' &&
                              <div className="d-inline-block">
                                {
                                  !borrwoStore ?
                                    <button type="button" className="btn-edit btn shadow-sm px-4" onClick={() => setBorrowStore(true)}>Borrow Location From Another Metro</button>
                                    :
                                    <button type="button" className="btn-edit btn shadow-sm px-5" onClick={() => setBorrowStore(false)}>Hide Borrow Location Form</button>
                                }
                              </div>
                            }
                            {
                              currentUser.role === 'admin' &&
                              <button type="button" className="btn btn-delete shadow-sm float-end" onClick={() => setFormMode('delete')}>
                                <FaTrashAlt className="mb-1" /> Delete Metro</button>
                            }
                          </div>
                      }
                    </>
                  }
                </Form>
              )
            }
          }
        </Formik>
      </div>
      {
        (borrwoStore || ((currentMetro.borrowed_stores instanceof Array) && currentMetro.borrowed_stores.length !== 0)) &&
        <div className="border p-4 mb-5">
          {
            currentUser.role === 'admin' && borrwoStore &&
            <BorrowStore {...{ milanStores, currentMetro, updateDB }} />
          }
          {
            currentMetro.borrowed_stores && currentMetro.borrowed_stores.length !== 0 &&
            <BorrowedStores {...{ currentMetro, currentUser, updateDB }} />
          }
        </div>
      }
      {
        currentStore &&
        <StoreTabsWrapper {...{ currentStore, currentMetro }} />
      }
    </div>
  );
}
export default MetroDetails