import React from "react";
import { CustomGraphicsForm } from "./Form";
import FormVerbiage from "./FormVerbiage";

export const FormContainer = ({formVerbiage}) => {

  return (
    <section id="custom-graphic-form" className="py-5 ">
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-6 pb-4 pb-lg-0">
            <FormVerbiage formVerbiage={formVerbiage} />
          </div>
          <div className="col-md-6">
            <CustomGraphicsForm />
          </div>
        </div>
      </div>
    </section>
  )
}