import { doc, updateDoc } from "firebase/firestore"
import { db } from '../../../firebase/firebase'
import { GOOGLE_REVIEWS_API_URL, FIREBASE_COLLECTION } from "../../../utils/constants"
import axios from 'axios'

export const updateGoogleRatings = milanStores => {
  let count = 0
  milanStores.forEach(state => {
    state.metros.forEach(metro => {
      const stateRef = doc(db, FIREBASE_COLLECTION, state.name)
      metro.stores.forEach(store => {
        if (store.place_id !== '') {
          count += 1
          setTimeout(() => {
            axios.get(`${GOOGLE_REVIEWS_API_URL}`, {
              params: {
                place_id: store.place_id,
                fields: 'rating,user_ratings_total',
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
              }
            }).then(response => {
              if (response.data.status === 'OK' && response.data.result.rating && response.data.result.user_ratings_total) {
                // 
                if (store.rating !== response.data.result.rating || store.reviewCount !== response.data.result.user_ratings_total) {
                  store.rating = response.data.result.rating
                  store.reviewCount = response.data.result.user_ratings_total
                  try {
                    console.log('Rating updated in:', store.salesforceValue)
                    updateDoc(stateRef, { metros: state.metros })
                  } catch (error) {
                    console.log('Error updating firebase db', error)
                  }
                }
              }
            }).catch(err => console.log('Error getting store rating', err))
          }, count * 1100) // Wait another second to make the next get request // Only 60 requests allowed per minute
        }
      })
    })
    
  })
}