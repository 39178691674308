import { Events, scroller } from "react-scroll";

export const scrollToNearbyStoresContainer = (x) => {
  let goToContainer = new Promise((resolve, reject) => {
    Events.scrollEvent.register('end', () => {
      resolve();
      Events.scrollEvent.remove('end');
    });

    scroller.scrollTo('nearby-stores-container', {
      duration: 200,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -300 // offset of the container
    });
  });

  goToContainer
    .then(() =>
      scroller.scrollTo(`nearby-store-${x}`, {
        duration: 200,
        delay: 0,
        smooth: 'easeInOutQuart',
        containerId: 'nearby-stores-container',
        // offset: 100 // offset of the element
      }))
    .catch(err => console.log('error with react-scroll', err))
}