import React, { useState } from 'react'
import { useEffect } from 'react'
import Toast from 'react-bootstrap/Toast'

export function ToastAlert({ currentUser, globalData }) {
  const [showToast, setShowToast] = useState(true)
  const [lastUpdated, setLastUpdated] = useState(globalData.last_updated)

  useEffect(() => {
    if(lastUpdated !== globalData.last_updated) {
      setShowToast(true)
      setLastUpdated(globalData.last_updated)
    }
  }, [globalData.last_updated])

  return (
    <Toast show={showToast} onClose={() => setShowToast(false)}>
      <Toast.Header>
        <strong className="me-auto">Last updated on:</strong>
        <strong className="main-blue">{globalData.last_updated}</strong>
      </Toast.Header>
      <Toast.Body>
        <p className="mb-1">Hi <strong className="main-blue">{currentUser.firstName}</strong>!</p>
        <p className="mb-0">If this is your first signin after <strong className="main-blue">{globalData.last_updated}</strong> please refresh the page now to get the latest version. Thanks!</p>
      </Toast.Body>
    </Toast>
  );
}

export default ToastAlert