import { useState, useEffect } from 'react'
import Pagination from "react-bootstrap/Pagination"

export function usePagination(list, LIST_COUNT_PER_PAGE) {
  if(!LIST_COUNT_PER_PAGE) LIST_COUNT_PER_PAGE = 15
  // if the list length is 18, and LIST_COUNT_PER_PAGE is 10 -> Then we will have 2 pages
  const pagesCount = Math.ceil(list.length / LIST_COUNT_PER_PAGE)
  const [currentPage, setCurrentPage] = useState(1)
  const [collection, setCollection] = useState([])
    let items = []
  for (let number = 1; number <= pagesCount; number++) {
    items.push(
      <Pagination.Item
        key={number} active={number === currentPage}
        onClick={() => handlePaginationClick(number)}>
        {number}
      </Pagination.Item>,
    );
  }
  // The pagination buttons
  const handlePaginationClick = pageNumber => {
    // slice(start, end)
    setCollection(list.slice(LIST_COUNT_PER_PAGE * (pageNumber - 1), LIST_COUNT_PER_PAGE * pageNumber))
    setCurrentPage(pageNumber)
  }

  // pageCount will be set to 1 by default on first mount
  // After that, this line comes in handy when any list element is updated, so it doesn't default to first page (the page number will be maintained)
  useEffect(() => {
    handlePaginationClick(currentPage)
  }, [list])
  return {
    collection: collection,
    items: items,
    handlePaginationClick: handlePaginationClick
  }
}
export default usePagination
