import React from 'react'
import { FormContainer } from '../shared/form/FormContainer';
import Hero from './Hero';
import BrandFonts from './BrandFonts';
import BrandImages from './BrandImages';
import { Helmet } from 'react-helmet'

const formVerbiage = 'Make your presentations really stand out with custom graphics or images. Fill out this form, and a member of the marketing team will be in touch with you soon! We can help take your presentation from zero to hero.'

function MilanDeck() {
  return (
    <main id='milan-deck'>
      <Helmet>
        <title>MILAN PRESENTATION TEMPLATES | Milan Laser</title>
        <meta name="description" content="Presentation templates that help you present confidently and consistently – the Milan way." />
      </Helmet>
      <Hero />
      <FormContainer formVerbiage={formVerbiage} />
      <BrandFonts />
      <BrandImages />
    </main>
  )
}

export default MilanDeck