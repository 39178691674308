import React from 'react'
import { TextError } from "../../../utils"
import { storage } from "../../../firebase/firebase"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { ref, uploadBytes } from 'firebase/storage'
import { FaAward } from 'react-icons/fa'
import { validateImage } from '..'

export function AddAward({ storagePath, setUploading, uploading, isAdminOrDesigner }) {
  const validationSchema = Yup.object({
    award: Yup.mixed().required('Required field!').test(input => validateImage(input, 'award', 30000, true)),
    name: Yup.string().required('Required field!'),
    link: Yup.string()
  })

  const onSubmitAward = (data, helpers) => {
    if (!isAdminOrDesigner) return
    if (typeof (data.award) === 'object') {
      const imageRef = ref(storage, `${storagePath}${data.award.name}`)
      const metadata = {
        customMetadata: {
          link: data.link,
          name: data.name,
          imgName: data.award.name
        }
      }
      uploadBytes(imageRef, data.award, metadata).then((res) => {
        helpers.setStatus('success')
        // This will trigger the useAwardsImages hook to rerender and fetch awards again
        setUploading(!uploading)
        setTimeout(() => {
          helpers.resetForm()
        }, 1200)
      }).catch(err => {
        console.log(err)
        helpers.setStatus('failed')
      })
    } else return
  }
  
  return (
    <Formik
      initialValues={{ award: '', name: '', link: '' }}
      validationSchema={validationSchema}
      onSubmit={(data, helpers) => onSubmitAward(data, helpers)} >
      {
        formik => {
          return (
            <Form className="row mx-0 p-4 rounded bg-white border justify-content-center">
              <h4 className="lh-base mb-2 px-0">Add Award <FaAward className="ms-2 mb-1 main-blue" /></h4>
              {
                formik.status === 'success' ?
                  <div className="alert alert-success text-center py-4" role="alert">
                    <p className="fs-5"><strong>Award Uploaded Successfully!</strong></p>
                  </div>
                  :
                  formik.status === 'failed' ?
                    <div className="alert alert-danger text-center col-lg-6" role="alert">
                      Failed to upload award.
                    </div>
                    :
                    <>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="award" className="form-label col-form-label text-capitalize main-blue fw-bold">Award Image</label>
                        </div>
                        <div className="col-sm-8">
                          <input type="file" name="award" id="award" className="form-control" onChange={(e) => formik.setFieldValue("award", e.target.files[0])} />
                          {formik.errors.award && <TextError>{formik.errors.award}</TextError>}
                        </div>
                      </div>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="name" className="form-label col-form-label text-capitalize main-blue fw-bold">Award Name</label>
                        </div>
                        <div className="col-sm-8">
                          <Field name="name" id="name" className="form-control" />
                          <ErrorMessage name="name" component={TextError} />
                        </div>
                      </div>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="link" className="form-label col-form-label text-capitalize main-blue fw-bold">Award Link</label>
                        </div>
                        <div className="col-sm-8">
                          <Field name="link" id="link" className="form-control" />
                          <ErrorMessage name="link" component={TextError} />
                        </div>
                      </div>
                      {/* ************ Form Actions Control ************** */}
                      <div className="mt-4 px-0">
                        <button type="submit" className="btn btn-submit shadow-sm" disabled={!formik.isValid}>
                          Add Award <FaAward className="ms-2 mb-1" />
                        </button>
                      </div>
                    </>
              }
              {
                (formik.status === 'success' || formik.status === 'failed') &&
                <div className="px-0">
                  <button type="button" className="btn btn-khaki shadow-sm" onClick={() => formik.resetForm()}>
                    {formik.status === 'success' ? 'Done' : 'Try again'}
                  </button>
                </div>
              }
            </Form>
          )
        }
      }
    </Formik>
  )
}

export default AddAward