import React, { useState } from 'react'
import { TextError } from "../../../utils"
import { storage } from "../../../firebase/firebase"
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { ref, uploadBytes, updateMetadata, getDownloadURL, deleteObject } from 'firebase/storage'
import { FaAward } from 'react-icons/fa'
import { validateImage } from '..'

export function EditAward({ selectedAward, setSelectedAward, setUploading, uploading, storagePath, isAdminOrDesigner }) {
  const [formMode, setFormMode] = useState(null)
  const validationSchema = Yup.object({
    award: Yup.mixed().test(input => validateImage(input, 'award', 30000, false)),
    link: Yup.string(),
    name: Yup.string()
  })

  const initialValues = {
    link: selectedAward.link,
    name: selectedAward.name,
    award: ''
  }

  const onSubmitEditAward = (data, helpers) => {
    if (!isAdminOrDesigner) return
    const imageRef = ref(storage, `${storagePath}${selectedAward.imgName}`)
    // On Deleting Award
    if (formMode === 'delete') {
      deleteObject(imageRef).then(() => {
        setSelectedAward({})
        setUploading(!uploading)
      }).catch((error) => {
        console.log('Error deleting employee', error)
      })
    } else { // On Editing Award
      // To update Award metadata in Firebase Storage
      const metadata = {
        customMetadata: {
          link: data.link,
          name: data.name,
          imgName: selectedAward.imgName
        }
      }
      // To update the Award Card and the Edit Form locallly
      let updatedAward = {
        ...selectedAward,
        ...metadata.customMetadata
      }
      // In case the award image getting replaced / (image field is not required in Edit Award form)
      if (typeof (data.award) === 'object') {
        uploadBytes(imageRef, data.award, metadata).then((res) => {
          // This will trigger the useAwardsImages hook to rerender and fetch awards images again
          setUploading(!uploading)
          getDownloadURL(imageRef).then(url => {
            updatedAward.url = url
            // This will trigger AwardCard & Edit Form to rerender
            setSelectedAward(updatedAward)
          }).catch(err => {
            console.error('Award getDownloadURL Failed', err)
          })
        }).catch(err => {
          console.error('Failed replacing award', err)
          helpers.setStatus('failed')
        })
      } else {
        updateMetadata(imageRef, metadata)
          .then((response) => {
            // This will trigger the useAwardsImages hook to rerender and fetch awards images again
            setUploading(!uploading)
            // This will trigger AwardCard & Edit Form to rerender
            setSelectedAward(updatedAward)
          }).catch((error) => {
            console.log(error)
            helpers.setStatus('failed')
          })
      }

    }
    helpers.resetForm()
    setFormMode(null)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(data, helpers) => onSubmitEditAward(data, helpers)}
      enableReinitialize >
      {
        formik => {
          // console.log(formik.status)
          return (
            <Form className="row mx-0 p-4 justify-content-center card h-100">
              <h4 className="lh-base mb-2 px-0">Update Award <FaAward className="ms-2 mb-1 main-blue" /></h4>
              {
                formik.status === 'success' ?
                  <div className="alert alert-success text-center py-4" role="alert">
                    <p className="fs-5"><strong>Award Updated Successfully!</strong></p>
                  </div>
                  :
                  formik.status === 'failed' ?
                    <div className="alert alert-danger text-center col-lg-6" role="alert">
                      Failed to update award
                    </div>
                    :
                    <>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="award" className="form-label col-form-label text-capitalize main-blue fw-bold">Award Image</label>
                        </div>
                        <div className="col-sm-8">
                          <input type="file" name="award" id="award" className="form-control" onChange={(e) => formik.setFieldValue("award", e.target.files[0])} disabled={formMode !== 'edit'} />
                          {formik.errors.award && <TextError>{formik.errors.award}</TextError>}
                        </div>
                      </div>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="link" className="form-label col-form-label text-capitalize main-blue fw-bold">Award Link</label>
                        </div>
                        <div className="col-sm-8">
                          <Field name="link" id="link" className="form-control" disabled={formMode !== 'edit'} />
                          <ErrorMessage name="link" component={TextError} />
                        </div>
                      </div>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="name" className="form-label col-form-label text-capitalize main-blue fw-bold">Award Name</label>
                        </div>
                        <div className="col-sm-8">
                          <Field name="name" id="name" className="form-control" disabled={formMode !== 'edit'} />
                          <ErrorMessage name="name" component={TextError} />
                        </div>
                      </div>
                      {/* ************ Form Actions Control ************** */}
                      <div className="mt-4 px-0">
                        {
                          formMode === 'delete' &&
                          <div className="alert alert-warning" role="alert">
                            Are you sure?
                          </div>
                        }
                        {
                          formMode &&
                          <>
                            <button type="submit" className={`btn shadow-sm btn-${formMode === 'delete' ? 'delete' : 'submit'}`} disabled={!formik.isValid}>
                              {formMode === 'delete' ? 'Yes' : 'Update'}
                            </button>
                            <button type="button" className="btn btn-cancel shadow-sm px-4 float-end" onClick={() => setFormMode(null)}>
                              Cancel
                            </button>
                          </>
                        }
                        {/* if you do the ternary operator, Edit button is submitting the form */}
                        {
                          !formMode &&
                          <>
                            <button type="button" className="btn btn-edit shadow-sm text-white px-4" onClick={() => setFormMode('edit')}>
                              Edit
                            </button>
                            <button type="button" className="btn btn-delete shadow-sm px-4 float-end" onClick={() => setFormMode('delete')}>
                              Delete
                            </button>
                          </>
                        }
                      </div>
                    </>
              }
              {
                (formik.status === 'success' || formik.status === 'failed') &&
                <div className="px-0">
                  <button type="button" className="btn btn-khaki shadow-sm" onClick={() => formik.resetForm()}>
                    {formik.status === 'success' ? 'Done' : 'Try again'}
                  </button>
                </div>
              }
            </Form>
          )
        }
      }
    </Formik>
  )
}

export default EditAward