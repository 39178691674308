import React from 'react'

export function EmployeeCard({selectedEmployee}) {
  return (
    <div className="img-thumbnail mx-auto mb-4 mb-md-0" style={{ width: '250px', minHeight: '320px' }}>
      <img src={selectedEmployee.url} alt={selectedEmployee.displayName} className="w-100" />
      <div className="text-center py-2">
        <div>
          <strong>
            {selectedEmployee.displayName}
          </strong>
        </div>
        <div>{selectedEmployee.position}</div>
      </div>
    </div>
  )
}

export default EmployeeCard