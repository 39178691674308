import React, { useEffect, useState } from 'react'
import FindMilanWrapper from './FindMilanWrapper'
import { useFetchMilanStores } from '../clinic-site-app/hooks'
import { StatesDropdownSection } from './StatesDropdownSection'
import { Footer } from '../clinic-site-app/home'
import { Helmet } from 'react-helmet'
import { useGlobalData } from '../clinic-site-app/hooks'
import AppUpdateToastAlert from './AppUpdateToastAlert'
import './styles.css'

function FindMilan() {
  const milanStores = useFetchMilanStores()
  const [milanGeoCodes, setMilanGeoCodes] = useState([])
  const { globalData } = useGlobalData()

  useEffect(() => {
    if (milanStores.length > 0) {
      setMilanGeoCodes(milanStores.map(state => state.metros.map(metro => metro.stores.map(store => ({
        name: store.salesforceValue,
        lat: store.geo.lat,
        long: store.geo.long,
        metro: {
          name: metro.name,
          pathname: metro.pathname,
          metroPath: metro.metroPath,
          singleStore: metro.stores.length === 1 ? true : false
        },
        state: {
          name: state.name,
          abbrev: state.abbreviation
        }
      })))).flat(2))
    }
  }, [milanStores.length])

  return (
    <section id="find-milan">
      <Helmet>
        <title>Find Milan | Milan Laser</title>
        <meta name="description" content="Find nearby Milan locations. | Search by address, zip code, city, or state" />
      </Helmet>
      <div className="container-fluid rounded p-4 px-4 px-md-5" style={{ minHeight: '600px' }}>
        {milanStores.length > 0 && <FindMilanWrapper {...{ milanStores, milanGeoCodes }} />}
      </div>
      {globalData && globalData.fm_last_update !== '' && <AppUpdateToastAlert {...{ globalData }} />}
      <hr />
      <div className="container-fluid px-4 py-5">
        {milanStores.length > 0 && <StatesDropdownSection {...{ milanStores }} />}
      </div>
      <Footer />
    </section>
  )
}

export default FindMilan