import * as Yup from 'yup'

export const globalValidationSchema = Yup.object({
  last_updated: Yup.string(),
  avg_rating: Yup.string().required('Required field!'),
  reviews_count: Yup.string().required('Required field!'),
  tx_per_month: Yup.number().required('Required field!'),
  pulses_per_day: Yup.number().required('Required field!'),
  waxing_cost_per_year: Yup.number().required('Required field!'),
  plans_start_at: Yup.string().required('Required field!'),
  specials_banner: Yup.string().required('Required field!').test(input => {
    if (!input.includes('|')) return new Yup.ValidationError(`Must include a separator |`, undefined, 'specials_banner')
    else return true
  }),
  grand_specials_banner: Yup.string().required('Required field!').test(input => {
    if (!input.includes('|')) return new Yup.ValidationError(`Must include a separator |`, undefined, 'grand_specials_banner')
    else return true
  })
})