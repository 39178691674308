import React from 'react';
import MILAN_BLUE_LOGO from '../shared/assets/Milan-Logo-Blue.webp';

export function FindMilanHeader() {
  return (
    <div className='row align-items-center mb-4 gy-3'>
      <div className='logo col-auto'>
        <img src={MILAN_BLUE_LOGO} className='' alt='Milan Logo' />
      </div>
      <div className='col-auto'>
        <h1 className='mb-0 milan-text-primary fw-light display-5'>
          Find Milan
        </h1>
      </div>
    </div>
  );
}

export default FindMilanHeader;
