import React from 'react'
import { StoresManager, Footer } from "./home"
import { Helmet } from 'react-helmet'
import './styles.css'

function ClinicSiteApp() {
  return (
    <div id="clinic-site-app">
      <Helmet>
        <title>Clinic Site App | Milan Laser</title>
        <meta name="description" content="Milan Laser Locations Manager" />
      </Helmet>
      <div className="bg-white shadow">
        <StoresManager />
        <Footer />
      </div>
    </div>
  )
}

export default ClinicSiteApp