import React from 'react'
import { saveAs } from "file-saver"
import MilanIversaryThumbnail from './assets/Happy-Milan-iversary.webp'
import JoinedTheTeamThumbnail from './assets/I-joined-the-team.webp'
import PromotedThumbnail from './assets/I_ve-been-promoted.webp'
import WelcomeToTheTeamThumbnail from './assets/Welcome-to-the-team.webp'
import { INTERNAL_ASSETS_PATH, INTERNAL_ASSETS_PATH_WITH_CORS } from '../shared/constants'
import DownloadIcon from '../shared/assets/Deck-download.png'

const MilanIversary = INTERNAL_ASSETS_PATH_WITH_CORS + 'Happy-Milan-iversary.png'
const JoinedTheTeam = INTERNAL_ASSETS_PATH_WITH_CORS + 'I-joined-the-team.png'
const Promoted = INTERNAL_ASSETS_PATH_WITH_CORS + "I've-been-promoted.png"
const WelcomeToTheTeam = INTERNAL_ASSETS_PATH_WITH_CORS + 'Welcome-to-the-team.png'

const MilanIversaryGif = INTERNAL_ASSETS_PATH_WITH_CORS + 'Happy_Milan_iversary_Individual.gif'
const JoinedTheTeamGif = INTERNAL_ASSETS_PATH_WITH_CORS + 'I_joined_the_Milan_Team.gif'
const PromotedGif = INTERNAL_ASSETS_PATH_WITH_CORS + "Ive_Been_Promoted.gif"
const WelcomeToTheTeamGif = INTERNAL_ASSETS_PATH_WITH_CORS + 'WelcomeToTheTeam.gif'
const MilanMagicGif = INTERNAL_ASSETS_PATH_WITH_CORS + 'MilanMagic.gif'
const JoinOurTeamGif = INTERNAL_ASSETS_PATH_WITH_CORS + 'JoinOurTeam.gif'

const WereHiringGif = INTERNAL_ASSETS_PATH_WITH_CORS + 'WereHiring.gif'
const MilanIversaryGifThumbnail = INTERNAL_ASSETS_PATH + 'Happy_Milan_iversary_Individual.gif'
const JoinedTheTeamGifThumbnail = INTERNAL_ASSETS_PATH + 'I_joined_the_Milan_Team.gif'
const PromotedGifThumbnail = INTERNAL_ASSETS_PATH + "Ive_Been_Promoted.gif"
const WelcomeToTheTeamGifThumbnail = INTERNAL_ASSETS_PATH + 'WelcomeToTheTeam.gif'
const MilanMagicGifThumbnail = INTERNAL_ASSETS_PATH + 'MilanMagic.gif'
const JoinOurTeamGifThumbnail = INTERNAL_ASSETS_PATH + 'JoinOurTeam.gif'
const WereHiringGifThumbnail = INTERNAL_ASSETS_PATH + 'WereHiring.gif'


function Milestones() {
  return (
    <section id="linkedin-milestones" className="pb-5">
      <div className="container">
        <h2 className="text-center pt-5 pb-4 h1 fw-light d-none d-sm-block milan-text-primary">CELEBRATE YOUR MILESTONES</h2>
        <h2 className="text-center pt-5 pb-4 d-sm-none display-3 milan-text-primary">CELEBRATE YOUR MILESTONES</h2>
        {/* <p className="pb-3">Whether you’re just hired, you got promoted, or it’s your anniversary, there’s a graphic for that.</p> */}
        <h6 className="pb-3 text-center">Whether you’re just hired, you got promoted, or it’s your anniversary, there’s a graphic for that.</h6>
        <div className="row justify-content-center gy-5 gx-xl-5">
          <div className="col-md-6 col-lg-3">
            <div className="bg-card mb-5" onClick={() => saveAs(MilanIversary, 'Happy-Milan-iversary.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={MilanIversaryThumbnail} alt="Happy Milan-iversary" />
                <figcaption className="mt-4 fs-5">Milaniversary</figcaption>
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
            <div className="bg-card" onClick={() => saveAs(MilanIversaryGif, 'Happy_Milan_iversary_Individual.gif')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={MilanIversaryGifThumbnail} alt="Happy Milan-iversary Gif" />
                <figcaption className="mt-4 fs-5">Milaniversary <small>(Gif)</small></figcaption>
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="bg-card mb-5" onClick={() => saveAs(JoinedTheTeam, 'I-joined-the-team.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={JoinedTheTeamThumbnail} alt="I'm joining Milan" />
                <figcaption className="mt-4 fs-5">I'm joining Milan!</figcaption>
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
            <div className="bg-card" onClick={() => saveAs(JoinedTheTeamGif, 'I_joined_the_Milan_Team.gif')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={JoinedTheTeamGifThumbnail} alt="I'm joining Milan Gif" />
                <figcaption className="mt-4 fs-5">I'm joining Milan! <small>(Gif)</small></figcaption>
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="bg-card mb-5" onClick={() => saveAs(Promoted, "I've-been-promoted.png")}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={PromotedThumbnail} alt="I've been promoted" />
                <figcaption className="mt-4 fs-5">Promotion</figcaption>
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
            <div className="bg-card" onClick={() => saveAs(PromotedGif, 'Ive_Been_Promoted.gif')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={PromotedGifThumbnail} alt="Promotion Gif" />
                <figcaption className="mt-4 fs-5">Promotion <small>(Gif)</small></figcaption>
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card mb-5" onClick={() => saveAs(WelcomeToTheTeam, 'Welcome-to-the-team.png')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={WelcomeToTheTeamThumbnail} alt="Welcome to the team" />
                <figcaption className="mt-4 fs-5">Welcome to the team</figcaption>
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
            <div className="bg-card" onClick={() => saveAs(WelcomeToTheTeamGif, 'WelcomeToTheTeam.gif')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={WelcomeToTheTeamGifThumbnail} alt="Welcome to the team Gif" />
                <figcaption className="mt-4 fs-5">Welcome to the team <small>(Gif)</small></figcaption>
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(MilanMagicGif, 'MilanMagic.gif')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={MilanMagicGifThumbnail} alt="Milan Magic Gif" />
                <figcaption className="mt-4 fs-5">Milan Magic <small>(Gif)</small></figcaption>
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(JoinOurTeamGif, 'JoinOurTeam.gif')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={JoinOurTeamGifThumbnail} alt="Join Our Team Gif" />
                <figcaption className="mt-4 fs-5">Join Our Team <small>(Gif)</small></figcaption>
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4">
            <div className="bg-card" onClick={() => saveAs(WereHiringGif, 'WereHiring.gif')}>
              <figure className="bg-card-thumbnail mb-3 text-center">
                <img className="w-100" src={WereHiringGifThumbnail} alt="We're Hiring Gif" />
                <figcaption className="mt-4 fs-5">We're Hiring <small>(Gif)</small></figcaption>
              </figure>
              <div className="bg-card-download-btn text-center">
                <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
                  Download <img src={DownloadIcon} alt='' className="ps-3" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Milestones