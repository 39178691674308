import { useState, useEffect } from 'react';
import { onSnapshot, doc } from "firebase/firestore"
import { db } from '../firebase/firebase'

export function useGlobalData() {
  const [globalData, setGlobalData] = useState(null)
  const FIREBASE_COLLECTION = 'global-data'
  const DOC_ID = "q5lPpC1u4tlBij8siO1Q"
  const docRef = doc(db, FIREBASE_COLLECTION, DOC_ID);

  useEffect(() => {
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setGlobalData(doc.data())
    }, error => {
      console.log('Error fetching global data')
    })
    
    // Stop listening to changes
    return unsubscribe
  }, [])

  return {
    globalData: globalData,
    docRef: docRef
  }
}
export default useGlobalData