import React from 'react'
import { useMap } from 'react-leaflet'

// This component will help with centring and flying animation effect by using useMap hook
export function ChangeView({ center, zoom }) {
  const map = useMap();
  // map.setView(center, zoom);
  map.flyTo(center, zoom, {
    animate: true,
    duration: 1 // in seconds
  });
  return null;
}

export default ChangeView