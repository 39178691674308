import React, { useState, useEffect, useContext } from 'react'
import { trimAll } from '../../../utils'
import { storage } from "../../../firebase/firebase"
import { useStaffImages } from '../../../hooks'
import { AddStaff, StaffList, EditStaff, EmployeeCard } from '..'
import { StoresManagerContext } from "../../../home"

export function Staff() {
  const { currentMetro, currentStore, currentUser, updateActivities } = useContext(StoresManagerContext)
  const storagePath = `stores/${trimAll(currentMetro.state)}/${currentMetro.pathname}/${currentStore.pathname}/staff/`
  const isAdminOrDesigner = currentUser.role === 'admin' || currentUser.role === 'designer'
  // uploading state is crucial to trigger useStaffImages hook to refetch staff images
  const [uploading, setUploading] = useState(false)
  const staffImages = useStaffImages(storage, storagePath, uploading)
  const [selectedEmployee, setSelectedEmployee] = useState({})

  useEffect(() => {
    // By default, no employee is selected initially. 
    return () => {
      setSelectedEmployee({})
    }
  }, [currentStore.salesforceValue])

  return (
    <div className="container-fluid py-5">
      <h4 className="mb-4 h3"><strong className="main-blue">{currentStore.marketingCity}</strong> Staff </h4>
      {
        (currentUser.role === 'admin' || currentUser.role === 'copywriter') && currentStore.cm_pardot_file !== '' &&
        <h5 className="mb-3 text-white badge bg-khaki">CM Pardot File: {currentStore.cm_pardot_file}</h5>
      }
      <div className="row gx-4 mb-4">
        <div className="col-lg-7 col-xl-5">
          {
            staffImages.length > 0 ?
              <StaffList {...{ staffImages, selectedEmployee, setSelectedEmployee, currentStore }} />
              :
              <div className="alert alert-warning py-4 mb-0 text-center fs-6" role="alert">
                <strong className="main-blue">{currentStore.marketingCity}</strong> location has no staff images
              </div>
          }
        </div>
        <div className="col-xl-7">
          {
            selectedEmployee.displayName &&
            <div className="row" style={{minHeight: '384px'}}>
              <div className="col-12 col-md-auto">
                <EmployeeCard selectedEmployee={selectedEmployee} />
              </div>
              {
                (isAdminOrDesigner || currentUser.role === 'HR') &&
                <div className="col">
                  <EditStaff {...{ staffImages, selectedEmployee, setSelectedEmployee, setUploading, uploading, storagePath, updateActivities, currentStore, currentUser }} />
                </div>
              }
            </div>
          }
        </div>
      </div>
      {
        isAdminOrDesigner &&
        <div className="row g-4">
          <div className="col-lg-7 col-xl-5">
            <AddStaff {...{ staffImages, storagePath, setUploading, uploading, isAdminOrDesigner, updateActivities, currentStore }} />
          </div>
        </div>
      }
    </div>
  )
}

export default Staff