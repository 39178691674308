import React from "react";
import {saveAs} from "file-saver";
// import {INTERNAL_ASSETS_PATH, A2_DOMAIN_ASSETS_PATH} from "../shared/constants";
import {A2_DOMAIN_ASSETS_PATH} from "../shared/constants";
import DownloadIcon from "../shared/assets/Deck-download.png";
import Thumbnail1 from "./assets/Thumbnail1.jpg";
import Thumbnail2 from "./assets/Thumbnail2.jpg";
import Thumbnail3 from "./assets/Thumbnail3.jpg";
import Thumbnail4 from "./assets/Thumbnail4.jpg";
import Thumbnail5 from "./assets/Thumbnail5.jpg";
import Thumbnail6 from "./assets/Thumbnail6.jpg";
import Thumbnail7 from "./assets/Thumbnail7.jpg";
import Thumbnail8 from "./assets/Thumbnail8.jpg";
import Thumbnail9 from "./assets/Thumbnail9.jpg";
import Thumbnail10 from "./assets/Thumbnail10.jpg";
import Thumbnail11 from "./assets/Thumbnail11.jpg";
import Thumbnail12 from "./assets/Thumbnail12.jpg";
import Thumbnail13 from "./assets/Thumbnail13.jpg";
import Thumbnail14 from "./assets/Thumbnail14.jpg";
import Thumbnail15 from "./assets/Thumbnail15.jpg";

const Cover1 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover1.png";
const Cover2 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover2.png";
const Cover3 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover3.png";
const Cover4 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover4.png";
const Cover5 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover5.png";
const Cover6 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover6.png";
const Cover7 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover7.png";
const Cover8 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover8.png";
const Cover9 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover9.png";
const Cover10 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover10.png";
const Cover11 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover11.png";
const Cover12 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover12.png";
const Cover13 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover13.png";
const Cover14 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover14.png";
const Cover15 = A2_DOMAIN_ASSETS_PATH + "internal/assets/linkedin/HeaderCover/linkedinCover15.png";

const generateBackground = (thumbnail, cover, number) => {
  return (
    <div className="col-sm-10 col-md-4 mb-4">
      <div className="bg-card" onClick={() => saveAs(cover, `linkedinCover${number}.png`)}>
        <div className="bg-card-thumbnail mb-3">
          <img className="w-100" src={thumbnail} alt={`Milan LinkedIn Background cover ${number}`} />
        </div>
        <div className="bg-card-download-btn text-center">
          <button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">
            Download <img src={DownloadIcon} alt="" className="ps-3" />
          </button>
        </div>
      </div>
    </div>
  );
};

function Backgrounds() {
  return (
    <section id="linkedin-backgrounds" className="pb-5">
      <div className="container">
        <h2 className="text-center pt-5 pb-4 h1 fw-light d-none d-sm-block milan-text-primary">CHOOSE YOUR BACKGROUND</h2>
        <h2 className="text-center pt-5 pb-4 display-3 d-sm-none">
          CHOOSE YOUR <br /> BACKGROUND
        </h2>
        {/* <p className="pb-3">Make your profile pop with a custom Milan banner. Click your favorite and download for your LinkedIn page!</p> */}
        <h6 className="text-center">Make your profile pop with a custom Milan banner. Click your favorite and download for your LinkedIn page!</h6>
        <div className="row gx-xl-5 justify-content-center">
          {generateBackground(Thumbnail1, Cover1, 1)}
          {generateBackground(Thumbnail2, Cover2, 2)}
          {generateBackground(Thumbnail3, Cover3, 3)}
          {generateBackground(Thumbnail4, Cover4, 4)}
          {generateBackground(Thumbnail5, Cover5, 5)}
          {generateBackground(Thumbnail6, Cover6, 6)}
          {generateBackground(Thumbnail7, Cover7, 7)}
          {generateBackground(Thumbnail8, Cover8, 8)}
          {generateBackground(Thumbnail9, Cover9, 9)}
          {generateBackground(Thumbnail10, Cover10, 10)}
          {generateBackground(Thumbnail11, Cover11, 11)}
          {generateBackground(Thumbnail12, Cover12, 12)}
          {generateBackground(Thumbnail13, Cover13, 13)}
          {generateBackground(Thumbnail14, Cover14, 14)}
          {generateBackground(Thumbnail15, Cover15, 15)}
        </div>
      </div>
    </section>
  );
}

export default Backgrounds;