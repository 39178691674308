import React from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { doc, setDoc } from "firebase/firestore"
import { db } from "../../firebase/firebase"
import states from 'states-us';
import { FIREBASE_COLLECTION, TextError } from '../../utils'
import * as Yup from 'yup'

export function AddNewState({ milanStores, updateCurrentView, updateActivities }) {
  const statesInDB = milanStores.map(state => state.name)
  const us_states = states.filter(state => (!state.territory && !statesInDB.includes(state.name)))

  const validationSchema = Yup.object({
    us_state: Yup.string().required('Required field!').test(stateName => {
      const isMilanState = milanStores.find(state => state.name === stateName)
      if (isMilanState) return new Yup.ValidationError('State already exists in database!', undefined, 'us_state')
      return true
    }),
  })

  const addState = async (stateName, helpers) => {
    // Double check if the added state exists
    const isMilanState = milanStores.find(state => state.name === stateName)
    if (isMilanState) return
    const selectedState = us_states.find(state => state.name === stateName)
    const newState = {
      name: selectedState.name,
      abbreviation: selectedState.abbreviation,
      metros: []
    }
    try {
      await setDoc(doc(db, FIREBASE_COLLECTION, newState.name), newState)
      // Update milanStores locally to avoid any UI errors // newState will take some time to be fetched form db
      milanStores.push(newState)
      milanStores.sort((a, b) => a.name.localeCompare(b.name))
      updateActivities({ type: 'New State', details: `${newState.name} state added` })
      updateCurrentView({
        stateName: newState.name,
        metroPathname: "",
        salesforceValue: ""
      })
      helpers.setStatus('success')
      setTimeout(() => {
        helpers.resetForm()
      }, 1200)
    } catch (error) {
      console.error(error)
      helpers.setStatus('failed')
    }
  }

  const onSubmit = (values, helpers) => {
    if (values.us_state === "") return
    addState(values.us_state, helpers)
  }

  return (
    <div id="add-new-state" className="d-flex justify-content-center justify-content-lg-start">
      <Formik
        initialValues={{ us_state: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, helpers) => onSubmit(values, helpers)} >
        {
          formik => {
            return (
              <Form className="p-4 shadow-sm navy-border bg-white w-100">
                {
                  formik.status === 'success' ?
                    <div className="alert alert-success text-center" role="alert">
                      New State Added Successfully!
                    </div>
                    :
                    formik.status === 'failed' ?
                      <div className="alert alert-danger text-center" role="alert">
                        Failed to add a new state.
                      </div>
                      :
                      <>
                        <Field as="select" id="us_state" name="us_state" className="form-select" disabled={formik.status === 'confirm'} >
                          <option value="">Select a state</option>
                          {
                            us_states.map((state, x) => (
                              <option key={x} value={state.name}>{state.name}</option>
                            ))
                          }
                        </Field>
                        <ErrorMessage name="us_state" component={TextError} />
                      </>
                }
                {/* ************ Form Actions Control ************** */}
                {
                  formik.status === 'confirm' ?
                    <div className="border bg-light p-3 mt-3">
                      <p>A new state will be added</p>
                      <p>Would you like to proceed?</p>
                      <div>
                        <input type="submit" value="Yes" className="btn btn-submit shadow-sm" disabled={formik.status === 'success' || !formik.isValid || formik.values.us_state === ''} />
                        <button type="button" className="btn btn-cancel shadow-sm float-end" onClick={() => formik.resetForm()}>Cancel</button>
                      </div>
                    </div>
                    :
                    <div className="mt-3">
                      {
                        (formik.status === 'success' || formik.status === 'failed') ?
                          <button type="button" className="btn navy-bg-btn light-blue" onClick={() => formik.resetForm()} style={{ maxWidth: '200px' }}>Start Over</button>
                          :
                          <button type="button" className="btn btn-submit shadow-sm" onClick={() => formik.setStatus("confirm")} disabled={formik.status === 'success' || !formik.isValid || formik.values.us_state === ''} >Add New State</button>
                      }
                    </div>
                }
              </Form>
            )
          }
        }
      </Formik>
    </div>
  );
}

export default AddNewState