import React from 'react'
import { TextError } from "../../../utils"
import { storage } from "../../../firebase/firebase"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { ref, uploadBytes } from 'firebase/storage'
import * as Yup from 'yup'
import { FaUser, FaEyeSlash } from 'react-icons/fa'

export function AddStaff({ staffImages, storagePath, setUploading, uploading, isAdminOrDesigner, updateActivities, currentStore }) {
  const validationSchema = Yup.object({
    employee: Yup.mixed().required('Required field!').test(input => {
      if (typeof (input) === 'object') {
        let employeeArr = input.name.split(".")[0].split("-")
        const doesExist = staffImages.find(member => member.imgName.split(".")[0].split("-").join("").includes(`${employeeArr[0]}${employeeArr[1]}`))
        if (doesExist) {
          return new Yup.ValidationError('Staff member with same name found', undefined, 'employee')
        } else if (employeeArr.length < 2 || !input.name.includes('-') || input.name.includes('_')) {
          return new Yup.ValidationError('Invalid image name. Follow pattern FirstName-LastName.webp', undefined, 'employee')
        } else if (input.name.includes(" ")) {
          return new Yup.ValidationError('Invalid image name. No space allowed', undefined, 'employee')
        } else if (input.type !== 'image/webp') {
          return new Yup.ValidationError('Only webp format is accepted', undefined, 'employee')
        } else if (input.size > 100000) {
          return new Yup.ValidationError('Image size must not exceed 100KB', undefined, 'employee')
        } else return true
      } else return false
    }),
    position: Yup.string().required('Required field!'),
    displayName: Yup.string().test(input => {
      if (input && input !== '') {
        if (input[input.length - 1] !== '.') {
          return new Yup.ValidationError('Missing "." at the end', undefined, 'displayName')
        }
        if (input.length < 4) {
          return new Yup.ValidationError('Display Name is too short', undefined, 'displayName')
        }
        return true
      } else return true
    })
  })

  const onSubmitEmployeeImage = (data, helpers) => {
    if (!isAdminOrDesigner) return
    if (typeof (data.employee) === 'object') {
      let employeeArr = data.employee.name.split(".")[0].split("-")
      const imageRef = ref(storage, `${storagePath}${data.employee.name}`)
      const metadata = {
        customMetadata: {
          position: data.position,
          displayName: data.displayName !== '' ? data.displayName : `${employeeArr[0]} ${employeeArr[1][0] + '.'}`,
          // index is necessary when displaying staff members in certain order
          index: (data.position === 'Clinic Manager') ? 1 : (data.position === 'Assistant Clinic Manager') ? 2 : (data.position === 'Physician Assistant') ? 3 : 100,
          showOnWebsite: 'true', // a string because FB Storage doesn't recognize boolean values
        }
      };
      if (data.position === 'Clinic Manager') {
        updateActivities({ type: 'New CM Photo', details: `Added to ${currentStore.marketingCity} | ${currentStore.stateAbbrev}` })
      } else {
        updateActivities({ type: 'New Staff Photo', details: `${data.position} added to ${currentStore.marketingCity} | ${currentStore.stateAbbrev}`, reviewed: true })
      }
      uploadBytes(imageRef, data.employee, metadata).then((res) => {
        helpers.setStatus('success')
        // This will trigger the useStaffImages hook to rerender and fetch staff images again
        setUploading(!uploading)
        setTimeout(() => {
          helpers.resetForm()
        }, 1200)
      }).catch(err => {
        console.log(err)
        helpers.setStatus('failed')
      })
    } else return
  }

  return (
    <Formik
      initialValues={{ employee: '', position: '', displayName: '' }}
      validationSchema={validationSchema}
      onSubmit={(data, helpers) => onSubmitEmployeeImage(data, helpers)}>
      {
        formik => {
          return (
            <Form className="row mx-0 p-4 rounded bg-white border justify-content-center">
              <h4 className="lh-base mb-2 px-0">Add staff member <FaUser className="ms-2 mb-1 main-blue" /></h4>
              {
                formik.status === 'success' ?
                  <div className="alert alert-success text-center py-4" role="alert">
                    <p className="fs-5"><strong>Image Uploaded Successfully!</strong></p>
                  </div>
                  :
                  formik.status === 'failed' ?
                    <div className="alert alert-danger text-center col-lg-6" role="alert">
                      Failed to upload image.
                    </div>
                    :
                    <>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="employee" className="form-label col-form-label text-capitalize main-blue fw-bold">Employee Image</label>
                        </div>
                        <div className="col-sm-8">
                          <input type="file" name="employee" id="employee" className="form-control" onChange={(e) => formik.setFieldValue("employee", e.target.files[0])} />
                          {formik.errors.employee && <TextError>{formik.errors.employee}</TextError>}
                        </div>
                      </div>
                      <div className="row py-1 g-1">
                        <div className="col-sm-4">
                          <label htmlFor="position" className="form-label col-form-label text-capitalize main-blue fw-bold">Position</label>
                        </div>
                        <div className="col-sm-8">
                          <Field as="select" name="position" id="position" className="form-select" >
                            <option value="">Select position</option>
                            <option value="Assistant Clinic Manager">Assistant Clinic Manager</option>
                            <option value="Client Specialist">Client Specialist</option>
                            <option value="Clinic Manager" disabled={staffImages.find(employee => employee.position === 'Clinic Manager')}>Clinic Manager</option>
                            <option value="Licensed Practical Nurse">Licensed Practical Nurse</option>
                            <option value="Medical Aesthetician">Medical Aesthetician</option>
                            <option value="Nurse Practitioner">Nurse Practitioner</option>
                            <option value="Physician Assistant">Physician Assistant</option>
                            <option value="Registered Nurse">Registered Nurse</option>
                            <option value="Regional Clinical Specialist">Regional Clinical Specialist</option>
                          </Field>
                          <ErrorMessage name="position" component={TextError} />
                        </div>
                      </div>
                      {
                        (formik.values.employee && formik.values.employee !== '' && !formik.errors.employee && formik.values.employee.name.split(".")[0].split("-").length > 1) &&
                        <>
                          <div className="row py-1 g-1">
                            <div className="mb-0" role="alert">
                              <strong className="text-khaki fst-italic">{formik.values.employee.name.split(".")[0].split("-")[0]} {formik.values.employee.name.split(".")[0].split("-")[1][0]}. </strong>
                              <small className="text-decoration-underline pointer" onClick={() => formik.setStatus('edit-displayName')}><i>isn't the correct Display Name?</i></small>
                              {
                                formik.status === 'edit-displayName' && <FaEyeSlash className="float-end mt-1 pointer text-khaki" onClick={() => formik.setStatus('')} />
                              }
                            </div>
                          </div>
                          {
                            formik.status === 'edit-displayName' &&
                            <div className="row py-1 g-1">
                              <div className="col-sm-4">
                                <label htmlFor="displayName" className="form-label col-form-label text-capitalize main-blue fw-bold">Display Name</label>
                              </div>
                              <div className="col-sm-8">
                                <Field name="displayName" id="displayName" className="form-control" placeholder={`${formik.values.employee.name.split(".")[0].split("-")[0]} ${formik.values.employee.name.split(".")[0].split("-")[1][0]}.`} />
                                <ErrorMessage name="displayName" component={TextError} />
                              </div>
                            </div>
                          }
                        </>
                      }
                      {/* ************ Form Actions Control ************** */}
                      <div className="mt-4 px-0">
                        <button type="submit" className="btn btn-submit shadow-sm" disabled={!formik.isValid}>
                          Add Staff Member
                        </button>
                      </div>
                    </>
              }
              {
                formik.status === 'failed' &&
                <div className="px-0">
                  <button type="button" className="btn btn-khaki shadow-sm" onClick={() => formik.resetForm()}>
                    Try Again
                  </button>
                </div>
              }
            </Form>
          )
        }
      }
    </Formik>
  )
}

export default AddStaff