import React from 'react'
import { TextArea, DefaultInput, PhoneInput, EmailInput, Select, GroupInput, FileInput } from '.'

export const FormikControl = props => {
  const { control, ...rest } = props
  switch (control) {
    case 'defaultInput':
      return <DefaultInput {...rest} />
    case 'groupInput':
      return <GroupInput {...rest} />
    case 'phoneInput':
      return <PhoneInput {...rest} />
    case 'emailInput':
      return <EmailInput {...rest} />
    case 'textarea':
      return <TextArea {...rest} />
    case 'select':
      return <Select {...rest} />
    case 'file':
      return <FileInput {...rest} />
    default: return <p>FormikControl</p>
  }
  return <p>FormikControl</p>
}

export default FormikControl