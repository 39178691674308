import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { StoreDetails, ProImages, Staff, Awards } from '.'
import { MILAN_SITE_START } from '../../../shared/constants'

export function StoreTabsWrapper({ currentStore, currentMetro }) {
  const [proImagesChanged, setProImagesChanged] = useState(false)
  const [activeKey, setActiveKey] = useState('storeDetails');
  const STORE_PATH = `${MILAN_SITE_START}${currentMetro.pathname}.com/locations/${currentMetro.stores.length === 1 ? currentStore.pathname : `${currentMetro.metroPath}/${currentStore.pathname}`}/`

  return (
    <div className="container-fluid control-panel">
      <div className="row">
        <h3 className="h2 px-0 mb-4">
          Location: <strong className="main-blue">{currentStore.marketingCity}</strong> {' '}
          {currentStore.openDate && currentStore.openDate !== '' && currentStore.openDate.seconds && <small className="d-block d-sm-inline fs-5 ms-sm-4 mt-2 mt-sm-0">(Open Date: <span className="main-blue">{ new Date(currentStore.openDate.toDate()).toString().slice(0, 15)}</span>)</small>}
          {currentStore.open ? null : <small className="text-coral mt-2 fs-5 float-md-end">Coming&nbsp;Soon</small>}
        </h3>
        <h4 className="mb-4 h6 px-0">
          <a href={STORE_PATH} className="main-blue text-decoration-underline" target="_blank">Visit {currentStore.marketingCity} Page</a>
        </h4>
        <Tabs
          id="store-tabs-wrapper"
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k)}
        >
          <Tab eventKey="storeDetails" title="Location Details">
            <StoreDetails {...{ proImagesChanged }} />
          </Tab>
          <Tab eventKey="proImages" title="Pro Images">
            <ProImages {...{ setProImagesChanged, activeKey }} />
          </Tab>
          <Tab eventKey="staff" title="Staff">
            {/* This will ensure Staff component rerenders on tab click */}
            {activeKey === 'staff' && <Staff />}
          </Tab>
          <Tab eventKey="awards" title="Awards">
            {activeKey === 'awards' && <Awards />}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default StoreTabsWrapper