import React from 'react'
// Error Message Alert Component
export function TextError({ children }) {
  return (
    <div className="alert alert-danger mb-1 px-3 py-1 mt-1 rounded">
      {children}
    </div>
  )
}

export default TextError
