import React from 'react'
import DownloadIcon from '../shared/assets/Deck-download.png'
import MilanHorizontalLogo from '../shared/assets/Milan_Logo_Horizontal.webp'
import KeyNoteIcon from './assets/Deck-Keynote.webp'
import GoogleSlidesIcon from './assets/Deck-GoogleSlides.webp'
import PowerpointIcon from './assets/Deck-Powerpoint.webp'
import { INTERNAL_ASSETS_PATH } from '../shared/constants'

const PresentationsPath = INTERNAL_ASSETS_PATH+'presentations/'
function Hero() {
  return (
    <section className="background hero py-4 py-sm-5 bg-l-blue">
      <div className="container pt-3 pt-lg-5 pb-sm-2">
        <div className="row pb-4">
          <div className="col-10 col-sm-12">
            <a href="https://milanlaser.com/" target="_blank" rel="noreferrer" ><img src={MilanHorizontalLogo} alt="Milan Laser Logo Horizontal" className='mw-100' /></a>
          </div>
        </div>
        <div className="row pb-sm-4">
          <div className="col-sm-8 col-lg-5">
            <h1 className="text-uppercase brandon-grotesque pop">Creating the <br /> 'Wow' Factor</h1>
            <span className="d-block d-sm-none"><br /></span>
            <h4 className="text-uppercase brandon-grotesque py-sm-4 my-5 my-sm-0 pt-5">How To Shine Using Milan Presentation Templates</h4>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-sm-8 col-lg-6">
            <p className="">The “wow factor” and Milan Laser Hair Removal go hand in hand. Wowing customers is why Milan is in a class by itself, and that same Milan Brand elevates the company perception and creates a best-in-class user experience. When creating branded presentations, please see the following directions in the documents below.</p>
          </div>
        </div>
        <div className="row gx-xl-5">
          <div className="col-lg-4 mb-4 mb-lg-0">
            <div className="bg-white p-4">
              <img src={KeyNoteIcon} alt="Apple Keynote Logo" className="py-3 w-100" />
              <h5><strong>Apple Keynote</strong></h5>
              <p>Share presentations with members of your team so they can continue to shine. Download this file and start designing your presentation that’s easy to share with other members of your team who use a Mac.</p>
              <div className="text-white pt-2"><a href={`${PresentationsPath}Keynote_May2022.key`}><button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">Download <img src={DownloadIcon} alt='' className="ps-3" /></button></a></div>
            </div>
          </div>
          <div className="col-lg-4 mb-4 mb-lg-0">
            <div className="bg-white p-4">
              <img src={GoogleSlidesIcon} alt="Google Slides Logo" className="py-3 w-100" />
              <h5><strong>Google Slides</strong></h5>
              <p>Trying to add a little extra Milan pizzazz to your latest piece of work? Download and drag this file to your Google drive. This template is super easy to share with other members of your team on a Mac or PC.</p>
              <div className="text-white pt-2"><a href="https://docs.google.com/presentation/d/1vW1Rh7eKnzVbehzekNuvDqD6JW0VoRzO0JOiDj44FKI/edit?usp=sharing" target="_blank" rel="noreferrer"><button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">Download <img src={DownloadIcon} alt='' className="ps-3" /></button></a></div>
            </div>
          </div>
          <div className="col-lg-4 mb-4 mb-lg-0">
            <div className="bg-white p-4">
              <img src={PowerpointIcon} alt="Microsoft Powerpoint Logo" className="py-3 w-100" />
              <h5><strong>Microsoft Powerpoint</strong></h5>
              <p>Show the team and audience your best presentation in style by downloading this file to your PC. This template is super easy to share with other members of your team who use Microsoft Office programs.</p>
              <div className="text-white pt-2"><a href={`${PresentationsPath}Powerpoint_May2022.pptx`}><button className="cta-btn red-bg-btn py-2 px-3 shadow-sm">Download <img src={DownloadIcon} alt='' className="ps-3" /></button></a></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
//hello
