import { useState, useEffect } from 'react';
import { collection, onSnapshot  } from "firebase/firestore"
import { db } from '../firebase/firebase'

export function useMedicalDirectors() {
  const FIREBASE_COLLECTION = 'medical-directors'
	const [medicalDirectors, setMedicalDirectors] = useState([])

  useEffect(() => {
		const unsubscribe = onSnapshot(collection(db, FIREBASE_COLLECTION), (snapshot) => {
			const fetchedData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data()}))
			setMedicalDirectors(fetchedData)
		}, error => {
			console.error(error)
		});
		// Stop listening to changes
		return unsubscribe
	}, [])
  return medicalDirectors.sort((a, b) => a.displayName.localeCompare(b.displayName))
}
export default useMedicalDirectors


// import { updateDoc, doc } from "firebase/firestore"
// fetchedData.forEach(md => {
// 	const newLocations = []
// 	md.locations.forEach(location => {
// 		const newLoc = {
// 			name: location.name,
// 			metro: location.metro,
// 			stateAbbrev: location.stateAbbrev
// 		}
// 		newLocations.push(newLoc)
// 	})
// 	const docRef = doc(db, FIREBASE_COLLECTION, md.id)
// 	try {
// 		updateDoc(docRef, { locations: newLocations })
// 	} catch (error) {
// 		console.log(error)
// 	}
// 	console.log('newLocations', newLocations)
// })