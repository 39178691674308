import React, { useState, useEffect } from 'react'
import { proInitialValues } from '.'
import { ref, deleteObject } from 'firebase/storage'
import { storage } from "../../../firebase/firebase"
import { FaTrashAlt } from 'react-icons/fa'

export function ImagesList({ storeImages, handleImageSelected, selectedImage, currentStore, setUploading, setSelectedImage, storagePath, isAdminOrDesigner, updateActivities, setProImagesChanged, updateDB }) {
  const [deleteImage, setDeleteImage] = useState(false)
  const localKeys = Object.keys(proInitialValues).sort() // Array
  const remoteKeys = Object.keys(storeImages).sort() // Array
  const missingImages = []

  const compareArrays = (local, remote) => {
    if (local.length === remote.length) return []
    for (let x = 0; x < local.length; x++) {
      if (remote[x] !== local[x]) {
        missingImages.push(local[x])
        local.splice(x, 1)
        compareArrays(local, remote)
        break
      }
    }
  }
  compareArrays(localKeys, remoteKeys)

  const onDeleteImage = (img) => {
    const imageRef = ref(storage, `${storagePath}${currentStore.marketingCity.trim().replace(/[^a-z0-9]/gi, '')}_${img}.webp`)
    setProImagesChanged(false)
    setUploading(true)
    if(img === 'Hero') {
      updateDB({ data: {hero: false, reversed: false, position: 'center' }, target: 'store',  mode: 'pro images' })
    }
    deleteObject(imageRef).then(() => {
      setSelectedImage([])
      setUploading(false)
      setDeleteImage(false)
      setProImagesChanged(true)
      updateActivities({ type: 'Clinic Images', details: `${img} image deleted in ${currentStore.marketingCity} | ${currentStore.stateAbbrev}`, reviewed: true })
    }).catch((error) => {
      console.log(`Error deleting ${img} image`, error)
      setUploading(false)
    })
  }

  useEffect(() => {
    // set to false when switching between locations
    setDeleteImage(false)
  }, [currentStore.salesforceValue])

  return (
    <div>
      <ul className="list-group bg-white">
        {
          Object.entries(storeImages).map((entry, x) => (
            <li key={x}
              className={`list-group-item list-group-item-action p-0 w-100 position-relative ${entry[0] === selectedImage[0] ? 'bg-blue text-white' : ''}`}
            >
              <div className="py-2 px-3" onClick={() => handleImageSelected(entry)}>
                <span className="me-2">{currentStore.marketingCity}</span>
                <span className={`img-name ${entry[0] === selectedImage[0] ? 'light-blue' : 'main-blue'}`}>{entry[0]}</span>
              </div>
              {
                (isAdminOrDesigner && deleteImage && entry[0] === selectedImage[0]) &&
                <span className="position-absolute top-50 end-0 translate-middle-y text-coral py-2 px-3" onClick={() => onDeleteImage(entry[0])}><FaTrashAlt className="mb-1" /></span>
              }
            </li>
          ))
        }
        {
          missingImages.length > 0 && missingImages.map((missingImg, k) => (
            <li key={k}
              className="list-group-item w-100 bg-light" style={{ cursor: 'not-allowed' }}>
              <small className="me-2">{currentStore.marketingCity}</small> <small className="text-danger">{missingImg}</small>
              <span className="alert alert-danger py-1 mb-0 float-end badge">Missing</span>
            </li>
          ))
        }
      </ul>
      {
        (isAdminOrDesigner && selectedImage.length !== 0) &&
        <>
          {
            deleteImage ?
              <button type="button" className="btn btn-cancel shadow-sm px-4 mt-3" onClick={() => setDeleteImage(false)}>
                Cancel
              </button>
              :
              <button type="button" className="btn btn-delete shadow-sm mt-3" onClick={() => setDeleteImage(true)}>
                Delete Image
              </button>
          }
        </>
      }
    </div>
  )
}

export default ImagesList