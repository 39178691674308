import React, { useState, useEffect } from 'react'
import { useMedicalDirectors } from '../../../hooks'

export function StaffList({ staffImages, selectedEmployee, setSelectedEmployee, currentStore }) {
  const medicalDirectors = useMedicalDirectors()
  const [clinicMD, setClinicMD] = useState({ displayName: 'Not Assigned', url: '', position: '' })
  useEffect(() => {
    if (medicalDirectors.length > 0) {
      const MD = medicalDirectors.filter(md => md.locations.find(location => (location.name === currentStore.marketingCity) && (location.stateAbbrev === currentStore.stateAbbrev)))[0]
      if (MD) {
        setClinicMD({
          displayName: MD.displayName,
          url: MD.url,
          position: 'Medical Director'
        })
      }
    }
  }, [medicalDirectors])

  return (
    <ul className="list-group bg-white mb-4 mb-xl-0">
      {
        (clinicMD.displayName !== 'Not Assigned' && clinicMD.url !== '') &&
        <li className="list-group-item w-100 position-relative disabled-bg d-flex justify-content-between align-items-center" style={{ cursor: 'default' }}>
          <div className="d-flex justify-content-between align-items-center">
            <figure className="overflow-hidden d-inline-block me-3 rounded-circle border shadow-sm mb-0" style={{ maxWidth: '40px', maxHeight: '40px' }} >
              <img src={clinicMD.url} alt="" className="w-100" />
            </figure>
            <span className="main-blue">{clinicMD.displayName}</span>
          </div>
          <span className="float-end">Medical Director</span>
        </li>
      }
      {
        staffImages.map((employee, x) => (
          <li key={x}
            className={`list-group-item list-group-item-action w-100 position-relative ${employee.displayName === selectedEmployee.displayName ? 'bg-blue text-white' : ''} ${employee.position === 'Clinic Manager' ? 'fw-bold' : ''}`}
            onClick={() => setSelectedEmployee(employee)}>
            <span className={`${employee.displayName === selectedEmployee.displayName ? 'light-blue' : 'main-blue'}`}>{employee.displayName}</span>
            <span className={`float-end ${employee.displayName === selectedEmployee.displayName ? 'text-white' : ''}`}>{employee.position}</span>
          </li>
        ))
      }
    </ul>
  )
}

export default StaffList