import React from "react"

export const AlertWarning = ({ item }) => {
  return (
    <div className="d-flex align-items-end h-100">
      <div className="alert alert-warning p3-2 mb-0 w-100 text-center" role="alert">
        No {item} found.
      </div>
    </div>
  )
}