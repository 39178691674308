import React from "react";
import { FaAsterisk } from 'react-icons/fa'
import { Formik, Form, Field, ErrorMessage } from "formik"
import { TextError, FormikControl } from "../../utils"
import { newMetroSchema as validationSchema } from '.'

export function AddNewMetro({ milanStores, updateDB, currentState }) {
  return (
    <div className="d-flex justify-content-center justify-content-lg-start">
      <Formik
        initialValues={{ name: '', pathname: '', gtag_id: '', google_analytics_id: '', bing_id: '' }}
        validationSchema={validationSchema(milanStores)}
        onSubmit={(data, helpers) => updateDB({ data: data, target: 'metro', mode: 'add new', helpers: helpers })} >
        {
          formik => {
            return (
              <Form className="p-4 shadow-sm navy-border bg-white w-100" >
                <h2 className="h4 mb-4">This metro will be added to <strong className="main-blue">{currentState.name}</strong> state</h2>
                {
                  formik.status === 'success' ?
                    <div className="alert alert-success text-center" role="alert">
                      New metro Added Successfully!
                    </div>
                    :
                    formik.status === 'failed' ?
                      <div className="alert alert-danger text-center" role="alert">
                        Failed to add a new metro.
                      </div>
                      : <div className="row gy-3">
                        <FormikControl control="groupInput" name="name" disabled={formik.status === 'confirm'} />

                        <div>
                          <div className="input-group mb-2" >
                            <div className="input-group-prepend">
                              <label htmlFor="pathname" className="input-group-text">milanlaser <sup><FaAsterisk /></sup></label>
                            </div>
                            <div className="" style={{ position: 'relative', flex: '1 1 auto', width: '1%', minWidth: '0' }}>
                              <div className="d-flex">
                                <span style={{ flexGrow: '1' }}><Field name="pathname" id="pathname" className="form-control" disabled={formik.status === 'confirm'} /></span>
                                <span className="bg-light text-dark border px-3 pt-1">.com</span>
                              </div>
                            </div>
                          </div>
                          <ErrorMessage name="pathname" component={TextError} />
                        </div>

                        <FormikControl control="groupInput" name="gtag_id" label="GTAG ID" disabled={formik.status === 'confirm'} optional />
                        <FormikControl control="groupInput" name="google_analytics_id" label="Google Analytics" disabled={formik.status === 'confirm'} optional />
                        <FormikControl control="groupInput" name="bing_id" label="Bing ID" disabled={formik.status === 'confirm'} optional />
                      </div>
                }
                {/* ************ Form Actions Control ************** */}
                {
                  formik.status === 'confirm' ?
                    <div className="border bg-light p-3 mt-3">
                      <p>A new metro will be added to <span className="fs-5 fw-bold main-blue">{currentState.name}</span></p>
                      <div>
                        <input type="submit" value="Confirm" className="btn btn-submit shadow-sm" disabled={formik.status === 'success' || !formik.isValid} />
                        <button type="button" className="btn btn-cancel shadow-sm float-end" onClick={() => formik.resetForm()}>Cancel</button>
                      </div>
                    </div>
                    :
                    <div className="mt-3">
                      {
                        (formik.status === 'success' || formik.status === 'failed') ?
                          <button type="button" className="btn navy-bg-btn light-blue" onClick={() => formik.resetForm()} style={{ maxWidth: '200px' }}>Start Over</button>
                          :
                          <button type="button" className="btn btn-submit shadow-sm" onClick={() => formik.setStatus("confirm")} disabled={formik.status === 'success' || !formik.isValid} >Add New Metro</button>
                      }
                    </div>
                }
              </Form>
            )
          }
        }
      </Formik>
    </div>
  );
}
export default AddNewMetro;